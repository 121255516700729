<template>
  <div class="hive-model">
    <el-botton @click="openTDoor" v-if="false">open</el-botton>
    <ul id="ul">
      <!-- 舱盖 -->
      <li>
        <div class="door-mk5 door-top-mk5" :class="{ active: roofState === '打开中' || roofState === '关闭中' }">
          <img src="./img/door-right.png" />
        </div>
        <div class="door-mk5 door-bottom-mk5" :class="{ active: roofState === '打开中' || roofState === '关闭中' }">
          <img src="./img/door-left.png" />
        </div>
      </li>
      <!-- 舱底 -->
      <li class="bbottom" :class="{ active: stageState === '上升中' || stageState === '下降中' }">
        <div class="x fance-top" :class="{ active: braceState === '松开中' || braceState === '夹紧中' }"></div>
        <div class="y fance-right" :class="{ active: clawState === '松开中' || clawState === '夹紧中' }"></div>
        <div class="x fance-bottom" :class="{ active: braceState === '松开中' || braceState === '夹紧中' }"></div>
        <div class="y fance-left" :class="{ active: clawState === '松开中' || clawState === '夹紧中' }"></div>
      </li>
      <li>
        <img src="./img/face.png" style="height: 100%;width: 100%" />
      </li>
      <li>
        <img src="./img/hou.png" style="height: 100%;width: 100%" />
      </li>
      <li>
        <img src="./img/zuo.png" style="height: 60px;width: 60px" />
      </li>
      <li>
        <img src="./img/zuo.png" style="height: 100%;width: 100%" />
      </li>
      <li>
        <img src="./img/bottom.png" style="height: 100%;width: 100%" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HiveModel',
  props: {
    roofState: {
      type: String,
      default() {
        return '';
      },
    },
    stageState: {
      type: String,
      default() {
        return '';
      },
    },
    braceState: {
      type: String,
      default() {
        return '';
      },
    },
    clawState: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      roofFlag: null,
    };
  },
  computed: {
    roof() {
      var result = false;
      switch (this.roofState) {
        case '打开中':
        case '已打开':
          result = true;
          break;
        case '关闭中':
        case '已关闭':
          result = false;
          break;
        default:
          break;
      }
      return result;
    },
    stage() {
      var result = false;
      switch (this.stageState) {
        case '上升中':
        case '顶部':
          result = true;
          break;
        case '下降中':
        case '底部':
          result = false;
          break;
        default:
          break;
      }
      return result;
    },
    brace() {
      var result = false;
      switch (this.braceState) {
        case '松开中':
        case '松开':
          result = true;
          break;
        case '夹紧中':
        case '夹紧':
          result = false;
          break;
        default:
          break;
      }
      return result;
    },
    /* 卡爪 */
    claw() {
      var result = {};
      switch (this.clawState) {
        case '松开中':
        case '松开':
          result = true;
          break;
        case '夹紧中':
        case '夹紧':
          result = false;
          break;
        default:
          break;
      }
      return JSON.parse(JSON.stringify(result));
    },
  },
  watch: {
    roof(newVal) {
      if (newVal) {
        this.openTDoor();
      } else {
        this.closeDoor();
      }
    },
    stage(newVal) {
      if (newVal) {
        this.upBottom();
      } else {
        this.downBottom();
      }
    },
    /* 横杆 */
    brace(newVal) {
      if (newVal) {
        this.fanceYAcitive();
      } else {
        this.clampYAcitive();
      }
    },
    /* 卡爪 */
    claw(newVal) {
      if (newVal) {
        if (newVal.claw === '松开') {
          this.letGo();
        } else {
          this.letGo();
        }
      } else {
        this.collectBuckle();
      }
    },
  },
  methods: {
    run() {
      var ul = document.getElementById('ul');
      ul.style.webkitTransition = '-webkit-transform 1.5s linear'; //设置立方体变换的属性、持续时间、动画类型
      this.x += 30;
      this.y += 30;
      ul.style.webkitTransition = '-webkit-transform 0.1s linear';
      ul.style.webkitTransform = 'rotateX(' + this.x + 'deg) rotateY(' + this.y + 'deg)';
    },
    back() {
      var ul = document.getElementById('ul');
      ul.style.webkitTransition = '-webkit-transform 1.5s linear'; //设置立方体变换的属性、持续时间、动画类型
      this.x -= 15;
      this.y -= 15;
      ul.style.webkitTransition = '-webkit-transform 0.1s linear';
      ul.style.webkitTransform = 'rotateX(' + this.x + 'deg) rotateY(' + this.y + 'deg)';
    },
    openTDoor() {
      var doorLeft = document.getElementsByClassName('door-top-mk5')[0];
      doorLeft.animate(
        [
          {
            transform: 'rotateX(0deg)',
            offset: 0,
          },
          {
            transform: 'rotateX(160deg)',
            offset: 1,
          },
        ],
        {
          duration: this.roofState === '打开中' ? 30000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var doorRight = document.getElementsByClassName('door-bottom-mk5')[0];
      doorRight.animate(
        [
          {
            transform: 'rotateX(0deg)',
            offset: 0,
          },
          {
            transform: 'rotateX(-160deg)',
            offset: 1,
          },
        ],
        {
          duration: this.roofState === '打开中' ? 30000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      const a = 1 != 2;
    },
    closeDoor() {
      var doorLeft = document.getElementsByClassName('door-top-mk5')[0];
      doorLeft.animate(
        [
          {
            transform: 'rotateX(160deg)',
            offset: 0,
          },
          {
            transform: 'rotateX(0deg)',
            offset: 1,
          },
        ],
        {
          duration: this.roofState === '关闭中' ? 30000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var doorRight = document.getElementsByClassName('door-bottom-mk5')[0];
      doorRight.animate(
        [
          {
            transform: 'rotateX(-160deg)',
            offset: 0,
          },
          {
            transform: 'rotateX(-0deg)',
            offset: 1,
          },
        ],
        {
          duration: this.roofState === '关闭中' ? 30000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    upBottom() {
      var bottom = document.getElementsByClassName('bbottom')[0];
      bottom.animate(
        [
          {
            transform: 'rotateX(270deg) translateZ(0px)',
            offset: 0,
          },
          {
            transform: 'rotateX(270deg) translateZ(-30px)',
            offset: 1,
          },
        ],
        {
          duration: this.stageState === '上升中' ? 30000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    downBottom() {
      var bottom = document.getElementsByClassName('bbottom')[0];
      bottom.animate(
        [
          {
            transform: 'rotateX(270deg) translateZ(-30px)',
            offset: 0,
          },
          {
            transform: 'rotateX(270deg) translateZ(0px)',
            offset: 1,
          },
        ],
        {
          duration: this.stageState === '下降中' ? 30000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 夹紧横杆 */
    clampYAcitive() {
      var fanceLeft = document.getElementsByClassName('y')[1];
      fanceLeft.animate(
        [
          {
            left: '-10px',
            top: '0px',
            offset: 0,
          },
          {
            left: '0px',
            top: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceRight = document.getElementsByClassName('y')[0];
      fanceRight.animate(
        [
          {
            right: '-10px',
            bottom: '0px',
            offset: 0,
          },
          {
            right: '0px',
            bottom: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 松开横杆 */
    fanceYAcitive() {
      var fanceLeft = document.getElementsByClassName('y')[1];
      fanceLeft.animate(
        [
          {
            left: '0px',
            top: '0px',
            offset: 0,
          },
          {
            left: '-10px',
            top: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceRight = document.getElementsByClassName('y')[0];
      fanceRight.animate(
        [
          {
            right: '0px',
            bottom: '0px',
            offset: 0,
          },
          {
            right: '-10px',
            bottom: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },

    letGo() {
      var fanceTop = document.getElementsByClassName('x')[0];
      fanceTop.animate(
        [
          {
            left: '0px',
            top: '0px',
            offset: 0,
          },
          {
            left: '0px',
            top: '-20px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceBottom = document.getElementsByClassName('x')[1];
      fanceBottom.animate(
        [
          {
            right: '0px',
            bottom: '0px',
            offset: 0,
          },
          {
            right: '0px',
            bottom: '-20px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    collectBuckle() {
      var fanceTop = document.getElementsByClassName('x')[0];
      fanceTop.animate(
        [
          {
            left: '0px',
            top: '-20px',
            offset: 0,
          },
          {
            left: '0px',
            top: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceBottom = document.getElementsByClassName('x')[1];
      fanceBottom.animate(
        [
          {
            right: '0px',
            bottom: '-20px',
            offset: 0,
          },
          {
            right: '0px',
            bottom: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.hive-model {
  width: 400px;
  height: 100px;
  position: relative;
  ul {
    width: 80px;
    height: 80px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 23%;
    left: 38%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotatex(320deg) rotateY(300deg);
    .active {
      box-shadow: 0 2px 12px 0 rgba(173, 255, 47, 0.5);
    }
    li {
      width: 60px;
      height: 60px;
      color: #fff;
      font-size: 24px;
      position: absolute;
    }
    li:nth-child(1) {
      height: 80px;
      transform: rotateX(90deg) translateZ(40px);
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      -webkit-transform: rotateX(90deg) translateZ(40px);
      .door-mk5 {
        width: 60px;
        height: 40px;
        position: absolute;
      }
      .door-top-mk5 {
        top: 0px;
        transform-origin: top center;
      }
      .door-bottom-mk5 {
        bottom: 0px;
        transform-origin: bottom center;
      }
    }
    li:nth-child(2) {
      height: 80px;
      background-image: url('./img/bock.png');
      transform: rotateX(270deg) translateZ(-30px);
      -webkit-transform: rotateX(270deg) translateZ(-30px);
    }
    li.bbottom {
      height: 80px;
      position: absolute;
      .x,
      .y {
        position: absolute;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        background-color: rgba(0, 0, 0, 0);
      }
      .x {
        width: 60px;
        height: 30px;
      }
      .y {
        width: 20px;
        height: 80px;
      }
      .fance-top {
        top: 0;
        border-bottom: 4px solid #313131;
      }
      .fance-left {
        left: 0;
        border-right: 4px solid #313131;
      }
      .fance-right {
        right: 0;
        border-left: 4px solid #313131;
      }
      .fance-bottom {
        bottom: 0;
        border-top: 4px solid #313131;
      }
    }
    li:nth-child(3) {
      width: 80px;
      background: rgba(255, 0, 255, 0.5);
      transform: rotateY(90deg) translateZ(20px);
      -webkit-transform: rotateY(90deg) translateZ(20px);
    }
    li:nth-child(4) {
      width: 80px;
      background: rgba(0, 255, 0, 0.5);
      transform: rotateY(270deg) translateZ(40px);
      -webkit-transform: rotateY(270deg) translateZ(40px);
    }
    li:nth-child(5) {
      background: rgba(200, 200, 0, 0.5);
      transform: translateZ(-40px);
      -webkit-transform: translateZ(-40px);
    }
    li:nth-child(6) {
      background: rgba(0, 0, 255, 0.5);
      transform: translateZ(40px);
      -webkit-transform: translateZ(40px);
    }
    li:nth-child(7) {
      height: 80px;
      background: rgba(0, 255, 255, 0.5);
      transform: rotateX(270deg) translateZ(18px);
      -webkit-transform: rotateX(270deg) translateZ(18px);
    }
  }
}
</style>

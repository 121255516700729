
/* 储存测试 */
const setRole = data => {
  return window.sessionStorage.setItem('role', data);
};

const getRole = () => {
  return window.sessionStorage.getItem('role');
};

const removeRole = () => {
  return window.sessionStorage.removeItem('role');
};

const setUserInfo = data => {
  return window.sessionStorage.setItem('userInfo', data);
};

const getUserInfo = () => {
  return window.sessionStorage.getItem('userInfo');
};

const removeUserInfo = () => {
  return window.sessionStorage.removeItem('userInfo');
};

const setSocketIOAccount = data => {
  return window.sessionStorage.setItem('socketIOAccount', data);
};

const getSocketIOAccount = () => {
  return window.sessionStorage.getItem('socketIOAccount');
};

const removeSocketIOAccount = () => {
  return window.sessionStorage.removeItem('socketIOAccount');
};

function siteConfigs(config) {
  return window.sessionStorage.setItem('siteConfig', config)
}

function getSiteConfigs() {
  return window.sessionStorage.getItem('siteConfig')
}

function removeSiteConfig() {
  return window.sessionStorage.removeItem('siteConfig');
}

function setAsyncRoutes(routes) {
  return window.sessionStorage.setItem('ROUTES', JSON.stringify(routes))
}

function getAsyncRoutes() {
  return window.sessionStorage.getItem('ROUTES') && JSON.parse(window.sessionStorage.getItem('ROUTES'))
}

function removeAsyncRoutes() {
  return window.sessionStorage.removeItem('ROUTES')
}

function setRoutes(flag) {
  return window.sessionStorage.setItem('hasRoutes', flag)
}

function getRoutes() {
  return window.sessionStorage.getItem('hasRoutes')
}

function removeRoutes() {
  return window.sessionStorage.removeItem('hasRoutes')
}

function setStreamInfo(stream) {
  return window.sessionStorage.setItem('stream', stream)
}

function getStreamInfo() {
  return window.sessionStorage.getItem('stream')
}

function removeStreamInfo() {
  return window.sessionStorage.removeItem('stream')
}

export {
  setRole,
  getRole,
  removeRole,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
  siteConfigs,
  getSiteConfigs,
  removeSiteConfig,
  setSocketIOAccount,
  getSocketIOAccount,
  removeSocketIOAccount,
  setAsyncRoutes,
  getAsyncRoutes,
  removeAsyncRoutes,
  setRoutes,
  getRoutes,
  removeRoutes,
  setStreamInfo,
  getStreamInfo,
  removeStreamInfo
};

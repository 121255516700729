<template>
  <div class="clock-container">
    <Flip :total="5" :current="timeArr[2]" />
    <Flip :total="9" :current="timeArr[3]" />
    <div class="colon"></div>
    <Flip :total="5" :current="timeArr[4]" />
    <Flip :total="9" :current="timeArr[5]" />
  </div>
</template>

<script>
import Flip from './flip.vue';
export default {
  components: {
    Flip,
  },
  props: {
    interval: {
      type: String,
      default() {
        return '00:00:00';
      },
    },
  },
  data() {
    return {
      timeArr: [0, 0, 0, 0, 0, 0],
    };
  },
  mounted() {},
  watch: {
    interval(newVal, oldVal) {
      this.timeArr = (newVal.replace(/[^0-9]/gi, '') + '').split('').map(Number);
    },
  },
};
</script>

<style lang="scss" scoped>
.clock-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.colon {
  height: 40px;
  padding: 0 4px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  &::after,
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
  }
}
</style>

<template>
  <ul class="shift-bar-wrapper">
    <li :class="['shift-item', waypointIndex >= 1 ? 'current' : '']">1</li>
    <template v-if="waypointCount > 1">
      <template v-if="waypointCount == 2">
        <li :class="['cable', waypointIndex >= 1 ? 'active' : '']">···</li>
        <li :class="['shift-item', waypointIndex == 2 ? 'current' : '']">2</li>
      </template>
      <template v-if="waypointCount > 2">
        <li :class="['cable', waypointIndex >= 1 ? 'active' : '']">···</li>
        <li :class="['shift-item', waypointIndex > 1 ? 'current' : '']">{{ waypointIndex > 1 ? waypointIndex : parseInt(waypointCount / 2) }}</li>
        <li :class="['cable', waypointIndex == waypointCount ? 'active' : '']">···</li>
        <li :class="['shift-item', waypointIndex === waypointCount ? 'current' : '']">{{ waypointCount }}</li>
      </template>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'ShiftBar',
  props: ['waypointCount', 'waypointIndex'],
  data() {
    return {
      count: 19,
    };
  },
};
</script>

<style lang="scss" scoped>
.shift-bar-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  li.shift-item {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background: #fff;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    color: chocolate;
    font-weight: 600;
  }
  li.cable {
    font-size: 40px;
  }
  li {
    &.active {
      color: chartreuse;
    }
    &.current {
      background: chartreuse;
    }
  }
}
</style>

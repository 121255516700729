<template>
  <ul class="controls-bar-wrapper">
    <li v-for="(ctrlItem,index) in ctrlFloor" :key="ctrlItem.icon+'_'+index">
      <el-tooltip :content="ctrlItem.label" placement="right" v-if="!ctrlItem.hide">
        <span class="controls-item">
          <i :class="['iconfont','item' ,ctrlItem.icon, action === ctrlItem.value ? 'action' : '']" @click="toggleRealTimeInformation(ctrlItem.value)"></i>
        </span>

      </el-tooltip>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ControlsBar',
  props: {
    siteID: {
      type: String,
      default() {
        return '';
      },
    },
    endSiteID: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      action: 2,
      ctrlFloor: [
        {
          label: '无人机报文',
          icon: 'icon-wurenji',
          value: 2,
          hide: false
        },
        {
          label: '起点站报文',
          icon: 'icon-012-zhandian',
          value: 1,
          hide: false
        },
        {
          label: '终点站报文',
          icon: 'icon-012-zhandian',
          value: 4,
          hide: true
        },
        {
          label: '排班信息',
          icon: 'icon-renwujincheng',
          value: 3,
          hide: false
        },
      ],
    };
  },
  methods: {
     hideHiveLabel(){
       this.ctrlFloor[1].hide = true
    },

    hideScheduleLabel(){
      this.ctrlFloor[3].hide = true
    },
    toggleRealTimeInformation(value) {
      if (this.action === value) return;
      this.action = value;
      this.$emit('toggleRealTimeInformation', value);
    },

  },
  watch: {
    endSiteID(newVal, oldVal) {
      if (!newVal || (newVal && newVal == this.siteID)) {
        this.ctrlFloor[2].hide = true
      } else {
        this.ctrlFloor[2].hide = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.controls-bar-wrapper {
  padding: 4px;
  border-radius: 4px;
  margin: 0;
  li {
    .controls-item {
      padding: 6px;
      margin: 0 0 10px 0;
      width: 40px px;
      height: 40px;
      display: inline-block;
      cursor: pointer;
      i {
        font-size: 24px;
        background: #{$subMenuBg};
        color: #fff;
        padding: 6px;
        border-radius: 4px;
        &.action {
          color: #{$primary};
        }
      }
      i:hover {
        background: #{$subMenuHover};
      }
      &:hover i {
        background: #{$cardHeaderBg};
        color: #{$primary};
      }
    }
  }
}
</style>

import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server

//获分享ID
export function getMissionShareID(missionBatch) {
  let start = new Date().getTime()
  return request({
    url: `${server}/auth//pageDeal/shareConnGeneration?start=${start}&missionBatch=${missionBatch}`,
    method: 'get'
  })
}

<template>
  <ul class="inspection-bar">
    <li class="inspection-item">
      <span><i :class="subStateMission(subState).state | statusIcon"></i>{{subStateMission(subState).state | parseStatus}}</span>
      <span class="item-title">{{subStateMission(subState).label}}</span>
    </li>

    <template v-if="site&&site.siteMode!=1">
      <li class="inspection-item">
        <span><i :class="headCoverState(hiveInfo.roofState).state | statusIcon"></i>{{headCoverState(hiveInfo.roofState).state | parseStatus}}</span>
        <span class="item-title">舱门{{headCoverState(hiveInfo.roofState).label}}</span>
      </li>
      <li class="inspection-item">
        <span><i :class="terraceState(hiveInfo.stageState).state | statusIcon"></i>{{terraceState(hiveInfo.stageState).state | parseStatus}}</span>
        <span class="item-title">平台{{terraceState(hiveInfo.stageState).label}}</span>
      </li>
      <li class="inspection-item">
        <span><i :class="railState(hiveInfo.braceState).state | statusIcon"></i>{{railState(hiveInfo.braceState).state | parseStatus}}</span>
        <span class="item-title">横杆{{railState(hiveInfo.braceState).label}}</span>
      </li>
      <li class="inspection-item">
        <span><i :class="railState(hiveInfo.clawState).state | statusIcon"></i>{{railState(hiveInfo.clawState).state | parseStatus}}</span>
        <span class="item-title">卡爪{{railState(hiveInfo.clawState).label}}</span>
      </li>
  </template>

    <li class="inspection-item">
      <span><i :class="subStatus(subState).state | statusIcon"></i>{{subStatus(subState).state | parseStatus}}</span>
      <span class="item-title">{{subStatus(subState).label}}</span>
    </li>
    <li class="inspection-item">
      <span><i :class="droneState | statusIcon"></i>{{droneState | parseStatus}}</span>
      <span>{{droneState === 2 ? '起飞中' : '起飞'}}</span>
    </li>
  </ul>
</template>

<script>
import InspectionItem from "./InspectionItem.vue"
export default {
  name: 'Inspection',
  components: {
    InspectionItem,
  },
  props: {
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    subState: {
      type: Number,
      default: 0,
    },
    hiveInfo: {
      type: Object,
      default: {}
    }
  },
  filters: {
    statusIcon(status) {
      const statusMap = {
        0: 'el-icon-remove-outline red',
        1: 'el-icon-loading blue',
        2: 'el-icon-circle-check green'
      }
      return statusMap[status]
    },
    parseStatus(status) {
      const statusMap = {
        0: '等待',
        1: '过程中',
        2: '完成'
      }
      return statusMap[status]
    },
  },
  data() {
    return {
      droneState: 0,
      waitInspection: [
        { state: '0', title: '任务自检' },
        { state: '1', title: '平台上升' },
        { state: '2', title: '横杆松开' },
        { state: '0', title: '卡爪松开' },
        { state: '1', title: '状态检测' },
        { state: '2', title: '起飞' }
      ]
    }
  },
  computed: {
    subStateMission(i) {
      return i => this.paramOperationState(i)
    },
    headCoverState(i) {
      return i => this.paramHeadCoverState(i)
    },
    terraceState(i) {
      return i => this.paramTerraceState(i)
    },
    railState(i) {
      return i => this.paramRailState(i)
    },
    subStatus(i) {
      return i => this.paramSubStatusByMission(i)
    }
  },
  methods: {
    paramOperationState(i) {
      let states = { state: 1, label: '待命' };
      switch (i) {
        case 102:
          states.state = 0;
          states.label = '任务自检'
          break;
        case '任务自检':
        case '未知':
          states.state = 1;
          states.label = i;
          break;
        default:
          states.state = 2;
          states.label = '任务自检已完成'
          break;
      }
      return states
    },
    paramHeadCoverState(i) {
      let states = { state: 0, label: '待开启' };
      switch (i) {
        case '已关闭':
          states.state = 0;
          states.label = "待开启";
          break;
        case '打开中':
        case '未知':
          states.state = 1;
          states.label = i;
          break;
        default:
          states.state = 2;
          states.label = '已打开'
          break;
      }
      return states
    },
    paramTerraceState(i) {
      let states = { state: 0, label: '待上升', };
      switch (i) {
        case '底部':
          states.state = 0;
          states.label = "待上升";
          break;
        case '上升中':
        case '未知':
          states.state = 1;
          states.label = i;
          break;
        default:
          states.state = 2;
          states.label = '已至顶部'
          break;
      }
      return states
    },
    paramRailState(i) {
      let states = { state: 0, label: '待松开' };
      switch (i) {
        case '夹紧':
          states.state = 0;
          states.label = "待松开";
          break;
        case '松开中':
        case '未知':
          states.state = 1;
          states.label = i;
          break;
        default:
          states.state = 2;
          states.label = '已松开'
          break;
      }
      return states
    },
    paramSubStatusByMission(i) {
      let states = { state: 1, label: '流程自检' };
      switch (i) {
        case '待命':
          states.state = 0;
          states.label = '流程自检'
          break;
        case '起飞':
          states.state = 2;
          states.label = '起飞自检完成';
          this.droneState = 2;
          break;
        default:
          states.state = 1;
          states.label = '起飞自检'
          break;
      }
      return states
    }


  },
  // watch: {
  //     subState(n, o) {
  //         console.log(n)
  //     },
  //     hiveInfo(n, o) {
  //         console.log(n, o)
  //     }
  // }
}
</script>

<style lang="scss" scoped>
.inspection-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 200;
  background: #00000088;
  font-size: 14px;
  border-radius: 4px;
  overflow: hidden;
  .inspection-item {
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    color: #eee;
    background: #{$navBarBg};
    border-radius: 4px;
    > span {
      padding: 5px;
      > i {
        margin: 0 4px 0 0;
      }
      .red {
        color: #f40;
      }
      .green {
        color: green;
      }
      .blue {
        color: #{$primary};
      }
    }
  }
}
</style>
<template>
  <div class="handle-page-tool-bar-wrapper">
    <template v-for="(tool, index) in tools">
      <tool-item v-if="!tool.hide" :key="index" :className="tool.className" :toolId="tool.toolId" :hasMenuOptions="tool.menuOptions ? true : false" :action="tool.action" :title="tool.title" :icon="tool.icon" :elementUI="tool.toolId == 1111" @handleClick="handleToolFunContorl">
        <!-- <el-checkbox-group v-model="tool.checked" slot="menu-options" v-if="tool.menuOptions"> -->
        <ul slot="menu-options" class="discern-aithmetics">
          <li v-for="(option, idx) in tool.menuOptions">
            <el-checkbox v-model="option.checked" :key="'ops_' + idx" :label="option.label" @change="handleChecked($event, tool, option.index)" v-if="option.role && option.show" :disabled="option.disabled">
              <span v-if="!option.disabled">
                {{ option.label }}
              </span>
              <el-tooltip class="item" effect="dark" content="任务中可开启" placement="right-start" v-else>
                <span>{{ option.label }}</span>
              </el-tooltip>
            </el-checkbox>
          </li>
        </ul>
        <!-- </el-checkbox-group> -->
      </tool-item>
    </template>

    <transition name="el-zoom-in-center">
      <div v-if="showAIOptions" class="ai-tool-select-area">
        <div class="ai-module-select-wrap">
          <!-- <el-checkbox :label="model.type" v-for="model in models" :key="model.type" @change="handleSelectMode(model)">{{ model.type }}</el-checkbox> -->
          <el-radio-group v-model="distinguishModel" @change="handleSelectMode" :disabled="Boolean(distinguishModel)">
            <el-radio :label="model.type" v-for="model in models" :key="model.type" :disabled="model.disabled">{{ model.type }}</el-radio>
          </el-radio-group>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ToolItem from './component/Item.vue';

import { mapGetters } from 'vuex';
import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE';
import { getUserInformation } from '@/utils/auth';

import { getCurrentDiscernAithmetic, getAIType as getDiscernAithmetics } from '@/api/discern.js';
import statusInclude from '@/utils/statusCode';

export default {
  props: {
    isRecording: {
      type: Number,
      default: 0,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    siteMode: {
      type: Number,
      default: 1,
    },
    checked: {
      type: Boolean,
      default: true,
    },
    missionBatch: {
      type: String,
      default: '',
    },
  },
  components: {
    ToolItem,
  },
  computed: {
    ...mapGetters(['isEntityRecognition']),
    hasMode() {
      return this.models.length;
    },
    loadAithmeticView() {
      return this.showAIOptions;
    },
  },
  data() {
    return {
      distinguishModel: '',
      models: [],
      hasMenuOptions: true,
      showAIOptions: false,
      checkedCities: [],
      tools: {
        emergencyStop: {
          action: false,
          title: '紧急停止',
          icon: 'icon-jiting',
          toolId: 503,
          className: 'step',
        },
        viewFixed: {
          action: false,
          title: '视角锁定',
          icon: 'icon-suoding',
          toolId: 504,
          className: 'is-action',
        },
        views: {
          action: false,
          title: '视角选择',
          icon: 'icon-ziyuan',
          toolId: 505,
          menuOptions: [
            { label: '第一视角', show: true, checked: false, role: 1, index: 0 },
            { label: '第三视角', show: true, checked: false, role: 1, index: 1 },
            { label: '内窥视角', show: true, checked: false, role: 1, index: 2 },
            { label: '机械臂视角', show: true, checked: false, role: 1, index: 3 },
            // UPDATE 修改了 识别模块的 show 字段为 true。
            { label: '识别模块', show: true, checked: false, index: 4, disabled: true },
          ],
          checked: [],
          className: '',
        },

        snap: {
          action: false,
          title: '拍摄照片',
          icon: 'icon-paizhao',
          toolId: 507,
          className: '',
        },
        video: {
          action: true,
          title: '开始录像',
          icon: 'icon-stop-circle red',
          toolId: 508,
          className: '',
        },
        share: {
          action: true,
          title: '分享',
          icon: 'el-icon-share share',
          toolId: 1111,
          className: '',
          hide: true,
        },
        collapse: {
          action: true,
          title: '折叠面板',
          icon: 'icon-triangle-left',
          toolId: 509,
          className: '',
        },
      },
    };
  },
  created() {
    //初始化识别算法框
    this.updateDiscernAithmeticsAreaOptionState();
    //获取setting配置设置 识别模块的权限
    this.tools.views.menuOptions[4].role = this.isEntityRecognition;
    let userInfo = getUserInformation();
    let visitor = false;
    if (userInfo?.userInformation?.roleId === 'R0001') {
      visitor = true;
    }
    // console.log(userInfo);
    if (visitor) {
      let keys = Object.keys(this.tools);
      keys.forEach((key) => {
        this.tools[key].hide = true;
      });
    }
    /**
     * @Author: 篆愁君
     * @Date: 2022-10-08 12:45:51
     * @description:获取识别模块
     * @return {*} void
     * UPDATE 开启AI功能
     */
    this.getDiscernAithmetics();
  },

  methods: {
    initdistinguishModel() {
      return this.distinguishModel || '';
    },

    handleSelectMode(id, init) {
      const cur = this.models.find(({ type }) => type === id);
      if (init && cur) return cur;
      else cur && this.$emit('change:handleChangeAIMode', cur);
    },

    handleToolFunContorl(id) {
      let keys = Object.keys(this.tools);
      keys.forEach((e) => {
        if (this.tools[e].menuOptions && this.tools[e].toolId === id) {
          this.tools[e].action = !this.tools[e].action;
        }
      });
      this.$emit('handleToolFunContorl', id);
    },

    handleChecked(e, tool, index) {
      console.log(e, tool, index);
      if (tool.toolId == 505) {
        // 视角切换   0：'第一视角'，1: '第三视角', 2: '内窥视角',4: '识别模块',
        this.$emit('changeView', { show: e, index: index });
        if (index == 4) {
          // ai
          if (e) {
            !this.distinguishModel &&
              this.getCurrentDiscernAithmetic(this.missionBatch)
                .then(
                  (response) =>
                    new Promise((resolve) => {
                      if (response.data?.task_info?.status === '正在执行') {
                        const mode = this.fetchDiscernAithmeticMode(JSON.parse(response.data.task_info.extra_type_list)[0]);
                        resolve(mode.type);
                      } else {
                        resolve('');
                      }
                    }),
                )
                .then((result) => {
                  this.distinguishModel = result;
                  result && this.handleSelectMode(result);
                });
            this.showAIOptions = true;
          } else {
            this.showAIOptions = false;
          }
        } else if (index == 0) {
          // 第一视角
          // 有第三视角：机库——射频基站，射频基站
          // ：机库——射频基站
          if (e) {
            if (this.siteMode != SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox && this.siteMode != SITE_HIVE_TYPE.SITEMODE.radioFrequencyBox) {
              // 无第三视角
              this.tools.views.menuOptions[1].show = false;
            }
            if (this.siteMode != SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
              // 无内窥，机械臂 视角
              this.tools.views.menuOptions[2].show = false;
              this.tools.views.menuOptions[3].show = false;
            }

            // this.tools.views.menuOptions[4].show = true;

            //  this.tools.views.menuOptions[4].show = true;
          } else {
            // let index = this.tools.views.checked.indexOf('识别模块');
            // if (index > -1) {
            //   this.tools.views.checked.splice(index, 1);
            // }
            // this.showAIOptions = false;
            // this.tools.views.menuOptions[4].show = false;
          }
        }
      }
    },

    updateDiscernAithmeticsAreaOptionState() {
      // 当missionBatch 为空时算法不可开启
      this.tools.views.menuOptions[4].disabled = !this.missionBatch;
      if (this.tools.views.menuOptions[4].disabled) {
        this.tools.views.active = false;
        this.tools.views.menuOptions[4].checked = false;
        this.handleChecked(false, this.tools.views, 4);
      }
    },

    updateDiscernAithmeticsOptionState() {},

    fetchDiscernAithmeticMode(str) {
      return this.models.find(({ extra_type }) => extra_type === str);
    },

    ///////////接口 API调用///////////////////////////////////////////////////////////////////////////////////

    /**
     * @description: 获取AI识别模型
     * @return {*}
     */
    async getDiscernAithmetics() {
      const { code, reason, data } = await getDiscernAithmetics();
      if (!statusInclude(code)) this.$message.error('获取AI信息错误：' + reason);
      this.models = data;
    },

    /**
     * @description: 获取当前是否有开启的识别模块
     * @param {*} missionBatch
     * @return {*}
     */
    getCurrentDiscernAithmetic(missionBatch) {
      return new Promise(async (resolve, reject) => {
        const response = await getCurrentDiscernAithmetic(missionBatch);
        if (!statusInclude(response.code)) {
          reject(response);
          throw new Error(`查询错误:${response.reason}`);
        } else {
          resolve(response);
        }
      });
    },
  },

  watch: {
    isRecording(newVal, oldVal) {
      if (newVal == 0) {
        this.tools.video.action = true;
        this.tools.video.icon = 'icon-luxiang01 red';
        this.tools.video.title = '开始录像';
      } else if (newVal == 1) {
        this.tools.video.action = false;
        this.tools.video.icon = 'icon-stop-circle red';
        this.tools.video.title = '停止录像';
      }
    },

    siteMode(nV) {
      if (nV != SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox && nV != SITE_HIVE_TYPE.SITEMODE.radioFrequencyBox) {
        // 无第三视角
        this.tools.views.menuOptions[1].show = false;
      }
      if (nV != SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
        // 内窥视角
        this.tools.views.menuOptions[2].show = false;
      }
    },
    isOpen(newVal, oldVal) {
      if (newVal) {
        this.tools.collapse.action = false;
        this.tools.collapse.icon = 'icon-triangle-right';
        this.tools.collapse.title = '折叠面板';
      } else {
        this.tools.collapse.action = true;
        this.tools.collapse.icon = 'icon-triangle-left';
        this.tools.collapse.title = '展开面板';
      }
    },
    hasMode(n, o) {
      console.log(n);
    },

    missionBatch(newVal, oldVal) {
      //修改 options的识别状态
      this.updateDiscernAithmeticsAreaOptionState();

      // newVal &&
      //   this.getCurrentDiscernAithmetic(this.missionBatch)
      //     .then(
      //       (response) =>
      //         new Promise((resolve) => {
      //           if (response.data?.task_info?.status === '正在执行') {
      //             const mode = this.fetchDiscernAithmeticMode(JSON.parse(response.data.task_info.extra_type_list)[0]);
      //             resolve(mode.type);
      //           } else {
      //             resolve('');
      //           }
      //         }),
      //     )
      //     .then((result) => {
      //       this.distinguishModel = result;
      //     });
    },
  },
};
</script>

<style lang="scss" scoped>
.handle-page-tool-bar-wrapper {
  display: flex;
  flex-direction: row;
  .discern-aithmetics {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 80px;
    right: 0;
    background: #{$navBarBg};
    .el-checkbox {
      margin: 0;
      padding: 10px;
      .el-checkbox__label {
        color: #fff;
      }
    }
    .is-checked {
      .el-checkbox__label {
        color: #409eff;
      }
    }
  }
  ::v-deep .el-checkbox-group {
    position: absolute;
    top: 80px;
    right: 0;
    background: #{$navBarBg};
    .el-checkbox {
      margin: 0;
      padding: 10px;
      .el-checkbox__label {
        color: #fff;
      }
    }
    .is-checked {
      .el-checkbox__label {
        color: #409eff;
      }
    }
  }
}

.ai-tool-select-area {
  position: absolute;
  // width: 160px;
  top: 80px;
  right: 0px;
  z-index: 999;
  border-radius: 4px;
  background-color: #192b47;
  padding: 10px;
  .title {
    color: var(--color-primary);
  }
  .ai-module-select-wrap {
    .el-radio-group {
      display: flex;
      flex-direction: column;

      /deep/.el-radio {
        margin-right: 0px;
        &:nth-last-child(1) {
          margin: 0;
        }
        .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
          background-color: #ff4400;
        }
        .el-radio__input.is-disabled.is-checked + span.el-radio__label {
          color: #ff4400;
        }
      }
    }
    .el-checkbox {
      margin: 0;
    }
  }
}
::v-deep .el-radio {
  margin-bottom: 10px;
}
</style>

<template>
  <div v-draging class="uav-video-wrap">
    <div class="drag-box" v-loading="isLoad" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0 , 0 , 0 , 0)">
      <span class="error-message" v-if="message">视频加载失败!</span>
      <video :class="className" id="player" muted ref="RtsPlayerEl" style="width:100%; height:100%; object-fit: fill"></video>
      <div class=" ctrl-bar-wrapper">
        <div class="left-time-area-wrapper"></div>
        <div class="right-ctrl-area-wrapper">
          <el-tooltip class="item" effect="dark" content="刷新" placement="top-start">
            <span class="ctrl-item renovate" @click="setValue(className)">
              <i class="iconfont icon-shuaxin"></i>
            </span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="全屏" placement="top-start">
            <span class="ctrl-item fullscreen" @click="screenrc">
              <i class="iconfont icon-si-glyph-arrow-resize-2"></i>
            </span>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AliRts from '@/utils/RTS.js';
import flvjs from 'flv.js';
export default {
  name: 'RTSpullVideo',
  props: {
    url: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    delay: {
      type: Number,
      default: 0
    },
    playerType: {
      type: String
    }
  },
  data() {
    return {
      aliRts: null,
      RtsPlayerEl: null,
      isLoad: false,
      message: '',
      timer: null,
      container: null,
      timerId: null,
      flvPlayer: null,
      playerBaseClient: null,
      hide: true,
      rPlayerTimer: null,
      PLAY_EVENT: '',
      mediaDataSource: {
        type: 'flv',
        hasAudio: false,
        isLive: true,
        url: ''
      },
      optionalConfig: {
        cors: false,                    // 是否跨域
        enableWorker: true,             // 是否多线程工作
        enableStashBuffer: false,       // 是否启用缓存
        stashInitialSize: 8192,         // 缓存大小(kb)  默认384kb
        autoCleanupSourceBuffer: true,  // 是否自动清理缓存
        enableWorker: false,            //不启用分离线程
        reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
      },

    };
  },
  mounted() {
    this.container = document.querySelector('.' + this.className)
    this.container.addEventListener('playing', (res) => {
      this.isLoad = false
    })
    this.container.addEventListener('waiting', (res) => {
      console.log('waiting')
      this.isLoad = false
    })
  },
  methods: {
    /**
     * @description: 初始化播放器
     * @param {*}
     * @return {*}
     */
    initializePlayer() {
      this.message = false
      this.isLoad = true
      return new Promise((resolve, reject) => {
        const play = this.aliRts ? this.aliRts : new AliRTS.createClient();
        resolve(play)
      })
    },
    instantiatePlayer(url) {
      const { isSupported, createPlayer, Events } = flvjs
      const compatibility = isSupported()
      this.mediaDataSource.url = url
      if (compatibility) {//检测是否支持flv.js
        //实例化player
        this.playerBaseClient = createPlayer({
          type: 'flv',
          hasAudio: false,
          isLive: true,
          url: this.url //'http://10.0.221.210/0.flv'////
        }, {
          cors: false,                    // 是否跨域
          enableWorker: true,             // 是否多线程工作
          enableStashBuffer: false,       // 是否启用缓存
          stashInitialSize: 8192,         // 缓存大小(kb)  默认384kb
          autoCleanupSourceBuffer: true,  // 是否自动清理缓存
          enableWorker: false,            //不启用分离线程
          reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
        });
        this.playerBaseClient.attachMediaElement(this.container)
        this.playerBaseClient.load();
        this.playerBaseClient.play();
      } else {
        this.$message.error('该浏览器不支持此视频播放器')
      }
      /* 监听播放器错误，如果出现播放错误则重置播放器 */
      this.playerBaseClient.on(Events.ERROR, (errorType, errorDetail, errorInfo) => {
        if (this.container) {
          this.destroyPlayerBaseClient()
          createPlayer(this.mediaDataSource, this.optionalConfig);
        }
      });
      /* 监听画面卡死 */
      this.playerBaseClient.on("statistics_info", function (e) {
        if (this.lastDecodedFrame == 0) {
          this.lastDecodedFrame = e.decodedFrames;
          return;
        }
        if (this.lastDecodedFrame != e.decodedFrames) {
          this.lastDecodedFrame = e.decodedFrames;
        } else {
          this.lastDecodedFrame = 0;
        }
      });
      if (this.timerId !== null) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        if (this.container.buffered.length > 0) {
          const end = this.playerBaseClient.buffered.end(0);  // 视频结尾时间
          const current = this.playerBaseClient.currentTime;  //  视频当前时间
          const diff = end - current;     // 相差时间
          const diffCritical = 4;         // 这里设定了超过4秒以上就进行跳转
          const diffSpeedUp = 1;          // 这里设置了超过1秒以上则进行视频加速播放
          const maxPlaybackRate = 4;      // 自定义设置允许的最大播放速度
          let playbackRate = 1.0;         // 播放速度
          if (diff > diffCritical) {
            this.playerBaseClient.currentTime = end - 1.5;
            playbackRate = Math.max(1, Math.min(diffCritical, 16));
          } else if (diff > diffSpeedUp) {
            playbackRate = Math.max(1, Math.min(diff, maxPlaybackRate, 16))
          }
          this.playerBaseClient.playbackRate = playbackRate;
          if (this.playerBaseClient.paused) this.playerBaseClient.play()
        }
      }, 1000);
    },
    setValue(className) {
      this.$emit('setValue', className)
    },
    /**
     * @description: 全屏
     * @param {*}
     * @return {*}
     */
    screenrc() {
      this.$refs.RtsPlayerEl.webkitRequestFullscreen();
    },
    /**
     * @description: 停止拉流
     * @param {*}
     * @return {*}
     */
    stopPullData() {
      this.aliRts.unsubscribe();
    },
    destroyPlayerBaseClient() {
      if (this.playerBaseClient) {
        this.playerBaseClient.pause()
        this.playerBaseClient.unload()
        this.playerBaseClient.detachMediaElement()
        this.playerBaseClient.destroy()
        this.playerBaseClient = null
      }
    }
  },
  watch: {
    async url(newSrc, oldSrc) {
      this.aliRts && this.stopPullData()
      if (this.playerType === 'artc') {
        this.initializePlayer().then(player => {
          this.aliRts = player
          player.subscribe(this.url).then((remoteStream) => {
            remoteStream.play(this.$refs.RtsPlayerEl);
          }).catch(error => {
            this.message = true
            this.isLoad = false
          })
        })
      } else {
        await this.destroyPlayerBaseClient()
        this.instantiatePlayer('http://10.0.221.210/0.flv')
      }
    },
    aliRts(nVal, oldVal) {
      const PLAY_EVENT = {
        CANPLAY: "canplay",
        WAITING: "waiting",
        PLAYING: "playing"
      }
      nVal.on('onPlayEvent', (play) => {
        this.PLAY_EVENT = play.event
        if (play.event === PLAY_EVENT.CANPLAY) {
          //拉流可以播放
          console.log(1)
          this.rPlayerTimer && clearInterval(this.rPlayerTimer)
          this.rPlayerTimer = null
        } else if (play.event === PLAY_EVENT.WAITING) {
          this.isLoad = true
          console.log(2)
          this.rPlayerTimer = setInterval(() => {
            this.$emit('uRequest', this.className)
          }, 3000)
        } else if (play.event === PLAY_EVENT.PLAYING) {
          this.isLoad = false
          this.rPlayerTimer && clearInterval(this.rPlayerTimer)
          this.rPlayerTimer = null
          console.log(3)
        }
      })
      nVal.on('onError', (play) => {
        console.log('isError')
      })
    }
  },
  beforeDestroy() {
    /**
     * @description: 销毁播放器
     * @param {*}
     * @return {*}
     */
    this.aliRts && this.stopPullData()
    this.timerId && clearTimeout(this.timerId);
    this.timerId = null;
    this.rPlayerTimer && clearInterval(this.rPlayerTimer)
    this.destroyPlayerBaseClient()
  },
};
</script>

<style lang="scss" scoped>
.uav-video-wrap {
  width: 768px;
  height: 432px;
  font-size: 14px;
  color: #eee;
  font-family: '黑体';
  position: relative;
  background-color: #333;
  .drag-box {
    width: 768px;
    height: 432px;
    position: relative;
    .ctrl-bar-wrapper {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      z-index: 999;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .ctrl-item {
        margin: 0 10px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      .fullscreen {
        margin: 0 0 0 10px;
      }
    }
  }
  #player {
    width: 768px;
    height: 432px;
    background-color: #333;
  }

  .move-btn {
    width: 10px;
    height: 10px;
    background-color: green;
    position: absolute;
    border-radius: 50%;
    bottom: -5px;
    right: -5px;
    cursor: se-resize;
  }
  .error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    color: #fff;
  }
}
</style>

<template>
    <div class="altitude-wrap">
        <div class="altitude-charts" :style="{ width: size, height:size }" ref="altitude"></div>
        <span class="unit">M</span>
    </div>
</template>

<script>
let FlightHtfontsize = 20;
export default {
    name: 'Altitude',
     props: {
        altitudeTo: {
           
        },
        size:{
            type: String,
            default() {
                return '200px'
            },
        }
    },
    data() {
        return {
            myChart: {},
            randomData: [],
            date: [],
            timer: null,
            time: '',
        }
    },
    mounted() {
        this.setCharts()
    },
    methods: {
        setCharts() {
            this.myChart = this.$echarts.init(this.$refs.altitude)
            this.myChart.setOption(this.option)
        },
    },
    computed: {
        origin() {
            return this.altitudeTo
        },
       
        option() {
            let that = this
            let obj = {
                title: {
                    bottom: '4%',
                    left: '35%',
                    text: '飞行高度',
                    textStyle: {
                        color: '#333',
                        padding: '2',
                        fontSize: '12',
                        borderRadius: 2,
                        padding: 2,
                        textShadowBlur: 2,
                        textShadowOffsetX: 1,
                        textShadowOffsetY: 1,
                        textShadowColor: '#EEE',
                    },
                },
                tooltop: {
                    formatter: '{a} <br/>{b} : {c}%',
                },
                toolbox: {
                    show: false,
                },
                textStyle: {
                    color: '#333',
                },
                series: [
                    //外圈圆
                    {
                        name: '飞行高度',
                        type: 'pie',
                        z: 10,
                        radius: '70%',
                        center: ['50%', '50%'],
                        animation: false,
                        data: [{ value: 1 }],
                        labelLine: {
                            show: false,
                        },
                        itemStyle: {
                            color: '#3d3b4f',
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        axisLine: {
                            show: true,
                            length: 2,
                            lineStyle: {
                                width: 1,
                                color: '#1e90ff',
                            },
                        },
                    },

                    {
                        name: '水平速度',
                        type: 'gauge',
                        radius: '65%',
                        z: 30,
                        min: 0,
                        max: 20,
                        startAngle: 269.99,
                        endAngle: -90,
                        detail: { formatter: '{value}' },
                        splitNumber: that.splitNumber,
                        axisLine: {
                            // 坐标轴线
                            lineStyle: {
                                // 属性lineStyle控制线条样式
                                color: [[1, '#1e90ff']],
                                width: 6,
                                // shadowColor: "#fff", //默认透明
                                // shadowBlur: 10,
                            },
                        },
                        axisTick: {
                            // 坐标轴小标记
                            length: 6, // 属性length控制线长
                            lineStyle: {
                                color: 'auto',
                            },
                        },
                        splitLine: {
                            // 分隔线
                            length: 8, // 属性length控制线长
                            lineStyle: {
                                // 属性lineStyle（详见lineStyle）控制线条样式
                                color: 'auto',
                            },
                        },
                        axisLabel: {
                            borderRadius: 2,
                            padding: 2,
                            color: 'auto',
                            textShadowBlur: 2,
                            textShadowOffsetX: 1,
                            textShadowOffsetY: 1,
                            textShadowColor: '#222',
                        },
                        itemStyle: {
                            show: false,
                        },
                        detail: {
                            z: 1,
                            fontSize: FlightHtfontsize,
                            fontWeight: 'bolder',
                            borderRadius: 3,
                            backgroundColor: '#444',
                            borderColor: '#aaa',
                            shadowBlur: 5,
                            shadowColor: '#333',
                            shadowOffsetX: 0,
                            shadowOffsetY: 3,
                            borderWidth: 1,
                            textBorderColor: '#000',
                            textBorderWidth: 2,
                            textShadowBlur: 2,
                            textShadowColor: '#fff',
                            textShadowOffsetX: 0,
                            textShadowOffsetY: 0,
                            offsetCenter: [0, 0],
                            fontFamily: 'Arial',
                            width: 70,
                            color: '#eee',
                            rich: {
                                fontSize: '10',
                            },
                            title: {
                                name: 'M/S',
                                fontSize: '14',
                                fontWeight: 'bolder',
                                borderColor: '#aaa',
                                color: '#FFFFFF',
                                fontFamily: '黑体',
                                width: 20,
                                bottom: '20%',
                            },
                        },
                        data: [{ value: that.origin }],
                        pointer: {
                            show: false,
                        },
                        animationDuration: 4000,
                    },
                ],
            }
            return obj
        },
    },
    watch: {
        origin(newValue, oldValue) {
             (this.time = this.$moment().format('HH:mm:ss')),
                this.myChart.setOption(this.option, true)
        },
         size(v){
          this.$nextTick(()=>{
            FlightHtfontsize = parseInt(v.split('px')[0]/10)
            this.myChart.resize()
          })
        }
    },
}
</script>

<style lang="scss" scoped>
.altitude-wrap {
    position: relative;
    .altitude-charts {
        width: 2rem;
        height: 2rem;
    }
    .unit {
        position: absolute;
        left: 50%;
        bottom: 28%;
        transform: translate(-50%, 0);
        font-size: 14px;
        font-weight: 600;
        color: #fff;
    }
}
</style>
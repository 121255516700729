<template>
  <el-collapse-item title="站点远程维护" name="HiveControlPanel">
    <el-form size="small" class="hive-controlpanel-director">
      <!-- <el-form-item label="单步动作">

        <el-row>
          <el-col :span="12">
            <el-button type="success">打开顶盖</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger">关闭顶盖</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button type="success">升起平台</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger">降下平台</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button type="success">松开横杆</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger">夹紧横杆</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button type="success">松开卡爪</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger">夹紧卡爪</el-button>
          </el-col>
        </el-row>
      </el-form-item> -->
      <el-form-item label="快捷流程">

        <el-row>
          <el-col :span="12">
            <el-button type="success" @click="openHive">
              打开机库
            </el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger" @click="closeHive">
              关闭机库
            </el-button>
          </el-col>
        </el-row>

        <!-- <el-row style="font-size: 12px">
          <el-col :span="12">
            <el-button type="success">有无人机 开库</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger">有无人机 收库</el-button>
          </el-col>
        </el-row>
        <el-row style="font-size: 12px">
          <el-col :span="12">
            <el-button type="success">无无人机 开库</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger">无无人机 收库</el-button>
          </el-col>
        </el-row> -->
      </el-form-item>
<!-- 


      <el-form-item label="特殊指令">
        <div class="special-attr-wrapper">
          <span>无人机</span>
          <el-row>
            <el-col :span="8">
              <el-button type="success">唤醒</el-button>
            </el-col>
            <el-col :span="8">
              <el-button type="danger">关闭</el-button>
            </el-col>
            <el-col :span="8">
              <el-button type="warning">重启</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-button style="width: 100%" type="success">开机保持</el-button>
          </el-row>
        </div>
        <el-row>
          <el-col :span="12">
            <el-button type="success">开始充电</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="danger">停止充电</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-button style="width: 100%" type="success">自动换电</el-button>
        </el-row>
        <el-row>
          <el-button style="width: 100%" type="danger">状态重置</el-button>
        </el-row>
        <el-row>
          <el-tooltip class="item" effect="dark" content="硬软件设备状态置为初始状态" placement="left">
            <el-button style="width: 100%" type="danger">系统重置</el-button>
          </el-tooltip>
        </el-row>
        <el-row>
          <el-tooltip class="item" effect="dark" content="5个机械轴停止" placement="left">
            <el-button style="width: 100%" type="danger">急停</el-button>
          </el-tooltip>
        </el-row>
      </el-form-item>
 -->


    </el-form>
  </el-collapse-item>
</template>

<script>
export default {
  name: 'SiteMaintain',
  props: {},
  data() {
    return {};
  },
  methods: {
    openHive() {
      this.$emit('open', true);
    },
    closeHive() {
      this.$emit('open', false);
    }
  },
};
</script>

<style lang="scss" scoped>
.hive-controlpanel-director {
  .el-form-item {
    display: flex;
    flex-direction: column;

    .el-form-item__content {
      .el-row {
        margin: 10px 0;
        .el-col {
          text-align: center;
        }
      }
    }
    .special-attr-wrapper {
      padding: 10px;
      border: 1px solid #fff;
    }
  }
}

::v-deep .el-form-item__label {
  text-align: left;
}
</style>

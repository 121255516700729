<template>
    <div class="instrument-wrap">
        <div id="myChartinstrument" :style="{ width: size, height: size }" ref="myChartinstrument"></div>
    </div>
</template>

<script>
export default {
    name: 'Horizontal',
    props: {
        horizontalSpeed: {
            required: true,
            type: Number,
            default() {
                return 0
            },
        },
        size:{
            type: String,
            default() {
                return '200px'
            },
        }
    },
    data() {
        return {
            myChart: {},
            splitNumber: 10,
            dataArr: 8,
        }
    },
    mounted() {
        this.setEchart()
    },
    computed: {
        origin() {
            return this.horizontalSpeed
        },
        option() {
            let that = this
            let obj = {
                title: {
                    bottom: '4%',
                    left: '35%',
                    text: '水平速度',
                    textStyle: {
                        color: '#555',
                        padding: '2',
                        fontSize: '12',
                        borderRadius: 2,
                        padding: 2,
                        textShadowBlur: 2,
                        textShadowOffsetX: 1,
                        textShadowOffsetY: 1,
                        textShadowColor: '#EEE',
                    },
                },
                tooltop: {
                    formatter: '{a} <br/>{b} : {c}%',
                },
                toolbox: {
                    show: false,
                },
                textStyle: {
                    color: '#333',
                },
                series: [
                    //外圈圆
                    {
                        name: '垂直速度',
                        type: 'pie',
                        radius: '70%',
                        animation: false,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                        itemStyle: {
                            color: '#333',
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [{ value: 1 }],
                    },
                    {
                        name: '垂直速度',
                        type: 'pie',
                        z: 10,
                        radius: '65%',
                        center: ['50%', '50%'],
                        animation: false,
                        data: [{ value: 1 }],
                        labelLine: {
                            show: false,
                        },
                        itemStyle: {
                            color: '#3d3b4f',
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        axisLine: {
                            show: true,
                            length: 2,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [0.5, '#119ade'],
                                    [1, '#e5ac0c'],
                                ],
                            },
                        },
                    },
                    {
                        name: '水平速度',
                        type: 'gauge',
                        radius: '65%',
                        z: 30,
                        min: 0,
                        max: 20,
                        detail: { formatter: '{value}' },
                        splitNumber: that.splitNumber,
                        axisLine: {
                            // 坐标轴线
                            lineStyle: {
                                // 属性lineStyle控制线条样式
                                color: [
                                    [0.23, '#EEE'],
                                    [0.82, '#1e90ff'],
                                    [1, '#F40'],
                                ],
                                width: 6,
                                // shadowColor: "#fff", //默认透明
                                // shadowBlur: 10,
                            },
                        },
                        axisTick: {
                            // 坐标轴小标记
                            length: 6, // 属性length控制线长
                            lineStyle: {
                                color: 'auto',
                            },
                        },
                        splitLine: {
                            // 分隔线
                            length: 8, // 属性length控制线长
                            lineStyle: {
                                // 属性lineStyle（详见lineStyle）控制线条样式
                                color: 'auto',
                            },
                        },
                        axisLabel: {
                            borderRadius: 2,
                            padding: 2,
                            color: 'auto',
                            textShadowBlur: 2,
                            textShadowOffsetX: 1,
                            textShadowOffsetY: 1,
                            textShadowColor: '#222',
                        },
                        itemStyle: {
                            show: false,
                        },
                        detail: {
                            // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                            formatter: function (value) {
                                value = (value + '').split('.')
                                value.length < 2 && value.push('00')
                                return (
                                    ('00' + value[0]).slice(-2) +
                                    '.' +
                                    (value[1] + '00').slice(0, 2)
                                )
                            },
                            // z:1,
                            fontSize: '12',
                            fontWeight: 'bolder',
                            borderRadius: 3,
                            backgroundColor: '#444',
                            borderColor: '#aaa',
                            shadowBlur: 5,
                            shadowColor: '#333',
                            shadowOffsetX: 0,
                            shadowOffsetY: 3,
                            borderWidth: 1,
                            textBorderColor: '#000',
                            textBorderWidth: 2,
                            textShadowBlur: 2,
                            textShadowColor: '#fff',
                            textShadowOffsetX: 0,
                            textShadowOffsetY: 0,
                            offsetCenter: [0, 45],
                            fontFamily: 'Arial',
                            width: 20,
                            color: '#eee',
                            rich: {
                                fontSize: '10',
                            },
                        },
                        title: {
                            name: 'M/S',
                            fontSize: 14,
                            fontWeight: 'bolder',
                            borderColor: '#aaa',
                            color: '#FFFFFF',
                            fontFamily: '黑体',
                            // width: 20,
                            // textAlign : "auto",
                            bottom: '20%',
                        },
                        data: [{ value: that.origin, name: 'M/S' }],
                        pointer: {
                            show: true,
                            length: '60%',
                            radius: '20%',
                            z: 10,
                            zlevel: 20,
                            width: 4, //指针粗细
                        },
                        animationDuration: 4000,
                    },
                ],
            }
            return obj
        },
    },
    methods: {
        setEchart() {
            let dom = this.$refs.myChartinstrument
            this.myChart = this.$echarts.init(dom)
            try {
                this.myChart.setOption(this.option)
            } catch {
                console.error('error')
            }
        },
    },
    watch: {
        origin(newValue, oldValue) {
            this.myChart.setOption(this.option, true)
        },
         size(v){
         this.$nextTick(()=>{
            this.myChart.resize()
          })
        }
    },
}
</script>

<style>
</style>
import parsingHiveInfomation from './parsingHiveInfomation.js';
function parsingInfomation(data) {
  const { type } = data;
  const tempData = data;
  if (type === 1) {
    const {
      UAVID, //无人机ID
      isManualMode,
      isTargetTrace,
      targetTrace,
      missionID, //任务编号
      missionBatch, //任务序列号
      longitudeGCJ, //无人机02坐标
      latitudeGCJ,
      longitudeWGS, //无人机84坐标
      latitudeWGS,
      altitude, //无人机高度
      azimuth, //无人机偏航角
      GPSCount, //GPS星数
      GPSLevel, //GPS信号等级
      upLink, //上行信号
      downLink, //下行信号
      isSCOnline, //天枢状态
      verticalSpeed, //垂直速度
      horizontalSpeed, //水平速度
      isUAVOnline, //无人机是否在线
      isGSOnline, //遥控器是否在线
      gimbalPitch, //云台俯仰角
      gimbalYaw, //云台偏转角
      gimbalRoll, //云台翻滚角度
      distanceStart, //距离起飞点距离
      isRecording, //是否录像
      waypointIndex, //在第几个点
      zoom, //变焦倍数
      timestamp, //时间戳
      subState, //工作状态
    } = data;

    //  data.batteries=[data.batteries[0]]// 测试代码 一块电池
    tempData.UAVID = UAVID;
    tempData.batteries = data.batteries;
    tempData.missionID = missionID;
    tempData.isManualMode = isManualMode;
    tempData.isTargetTrace = isTargetTrace;
    tempData.targetTrace = targetTrace;

    tempData.missionBatch = missionBatch;
    tempData.longitudeGCJ = longitudeGCJ;
    tempData.latitudeGCJ = latitudeGCJ;
    tempData.longitudeWGS = longitudeWGS;
    tempData.latitudeWGS = latitudeWGS;
    tempData.altitude = parseFloat(altitude).toFixed(1);
    tempData.azimuth = azimuth;
    tempData.GPSCount = GPSCount;
    tempData.GPSLevel = GPSLevel;
    tempData.upLink = upLink; //遥控信号
    tempData.downLink = downLink; //图传信号
    tempData.isOSDKAvailable = isSCOnline ? 1 : 0;
    tempData.horizontalSpeed = horizontalSpeed;
    tempData.isUAVOnline = isUAVOnline ? 1 : 0;
    tempData.isGSOnline = isGSOnline ? 1 : 0;
    tempData.verticalSpeed = verticalSpeed * -1;
    tempData.distanceStart = distanceStart;
    tempData.gimbalPitch = gimbalPitch;
    tempData.gimbalYaw = gimbalYaw;
    tempData.gimbalRoll = gimbalRoll;
    tempData.timestamp = timestamp;
    tempData.subState = subState;
    tempData.zoom = zoom;
    tempData.isRecording = isRecording;
    tempData.waypointIndex = waypointIndex;
    tempData.update = new Date().getTime() + 5000;
  } else if (type === 2) {
    tempData.workState = parsingHiveInfomation.parseWorkState(data.workState); //subState
    tempData.flowState = parsingHiveInfomation.parseFlowState(data.flowState); //flowState
    tempData.roofState = parsingHiveInfomation.parseRoofState(data.roofState); //roofState
    tempData.stageState = parsingHiveInfomation.parseStageState(data.stageState); //stageState
    tempData.braceState = parsingHiveInfomation.parseBraceState(data.braceState); //braceState
    tempData.clawState = parsingHiveInfomation.parseClawState(data.clawState); // clawState
    tempData.chargeOn = data.chargeOn ? '充电' : '空闲';
    tempData.chargeVoltageLevel = data.chargeVoltageLevel;
    tempData.isInChargingFlow = data.isInChargingFlow ? '流程中' : '空闲';
    tempData.windSpeed = `${data.windSpeed ? data.windSpeed.toFixed(1) : 0}m/s`;
    tempData.isRaining = data.isRaining ? '有雨' : '无雨';
    tempData.update = new Date().getTime() + 5000;
  } else {
    tempData = data;
  }
  return { tempData, type };
}
export { parsingInfomation };

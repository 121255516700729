<template>
  <div class="stick" />
</template>

<script>
import { UAV_ACTION } from '@/enum/UAV_ACTION';

const MAX_LIMIT = 80;
export default {
  name: 'Stick',
  data() {
    return {
      websocket: null
    };
  },
  mounted() {
    this.wakenStickExe();
  },
  beforeDestroy() {
    this.websocket.close();
  },
  methods: {

    // 唤起本机摇杆exe程序
    wakenStickExe() {
      const that = this;
      // 判断当前浏览器是否支持WebSocket
      if ('WebSocket' in window) {
        this.websocket = new WebSocket('ws://127.0.0.1:10240');
        // 连接发生错误的回调方法
        this.websocket.onerror = function () {
          console.error('WebSocket连接发生错误');
        };

        // 连接成功建立的回调方法
        this.websocket.onopen = function () {
          console.error('WebSocket连接成功');
        };

        // 接收到消息的回调方法
        this.websocket.onmessage = function (event) {
          if (typeof (event.data) === 'string') {
            // data :  callback, button,value,

            const messageList = event.data.split(',');
            console.log(messageList);
            const message = messageList[0];
            const msg = {
              root: 'uav',
              cmd: '',
              param: 0,
              stop: false
            };

            if (message === 'OnStickConnected') {
              // todo

            } else if (message === 'OnThrottleConnected') {
              // 油门

            } else if (message === 'OnStickAxesValue') {
              let zeroCount = 0;
              // messageList:[msg,左右,前后,无用值，上下，左右转]
              // 字符串类型转换为数值，其中不处理3下标的值（无用值）
              for (let i = 1; i < messageList.length; i += 1) {
                messageList[i] = -parseFloat(Number(messageList[i]).toFixed(1));
                if (i !== 3 && Math.abs(messageList[i]) <= 0.1) {
                  zeroCount += 1;
                }
              }
              if (zeroCount === 4) {
                msg.stop = true;
              }
              // 前左上：都为负数
              // 通过ws发送无人机位置控制(所有值) joystick: { vertical: 0, pitch: 0, roll: 0, yaw: 0 }
              // 前后：pitch，左右：roll，左右转：yaw，上下：vertical（左上：发送给uav要正）
              msg.cmd = 'joystick';
              const [, roll = 0, pitch = 0, , vertical = 0, yaw = 0] = messageList;
              msg.joystick = { vertical: vertical * MAX_LIMIT, pitch: pitch * MAX_LIMIT, roll: roll * MAX_LIMIT, yaw: yaw * MAX_LIMIT / 5 };
              msg.joystick.roll = -msg.joystick.roll;
              msg.joystick.yaw = -msg.joystick.yaw;
            } else if (message === 'OnThrottleAxesValueChanged') {
              // todo

            } else if (message === 'OnStickButtonPressed') {
              // 云台
              msg.stop = false;
              // eslint-disable-next-line radix
              const button = parseInt(messageList[1]);
              if (button === 11) { // 云台 上
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.pitch;
                msg.param = 1;
              } else if (button === 12) { // 云台 右
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.head;
                msg.param = 1;
              } else if (button === 13) { // 云台 下
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.pitch;
                msg.param = -1;
              } else if (button === 14) { // 云台 左
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.head;
                msg.param = -1;
              }
            } else if (message === 'OnStickButtonReleased') {
            //   ['OnStickButtonReleased', messageList[1]];
              msg.stop = true;
              // 点击按钮事件
              // eslint-disable-next-line radix
              const button = parseInt(messageList[1]);
              if (button === 1) { // 任务恢复、暂停
                msg.root = 'mission';
                msg.cmd = UAV_ACTION.MISSION.pause;
              } else if (button === 6) { // 急停
                msg.root = 'mission';
                msg.cmd = UAV_ACTION.MISSION.urgencyStop;
              } else if (button === 2) { // A键 拍照
                msg.root = 'camera';
                msg.cmd = UAV_ACTION.CAMERA.snap;
              } else if (button === 5) { // 录像
                msg.root = 'camera';
                msg.cmd = UAV_ACTION.CAMERA.video;
              } else if (button === 3) { // 云台复位
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.recover;
              } else if (button === 11) { // 云台 上
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.pitch;
                msg.param = 1;
              } else if (button === 12) { // 云台 右
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.head;
                msg.param = 1;
              } else if (button === 13) { // 云台 下
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.pitch;
                msg.param = -1;
              } else if (button === 14) { // 云台 左
                msg.root = 'gimbal';
                msg.cmd = UAV_ACTION.GIMBAL.head;
                msg.param = -1;
              }
            } else if (message === 'OnStickPovPressed') {
              // infor = ['OnStickPovPressed', messageList[1]];

            } else if (message === 'OnStickPovReleased') {
              //   infor = ['OnStickPovReleased', messageList[1]];

              // 云台 上下，焦距+-
              // eslint-disable-next-line radix
              const button = parseInt(messageList[1]);
              if (button === 1) { // 上 焦距+-
                msg.root = 'camera';
                msg.cmd = UAV_ACTION.CAMERA.zoom;
                msg.param = 1;
              } else if (button === 4) { // 下 焦距+-
                msg.root = 'camera';
                msg.cmd = UAV_ACTION.CAMERA.zoom;
                msg.param = -1;
              } else if (button === 8) { // 左 镜头切换
                msg.root = 'camera';
                msg.cmd = UAV_ACTION.CAMERA.lens;
                msg.param = -1;
              } else if (button === 2) { // 右 镜头切换
                msg.root = 'camera';
                msg.cmd = UAV_ACTION.CAMERA.lens;
                msg.param = 1;
              }
            } else if (message === 'OnThrottleButtonPressed') {
            //  infor = ['OnThrottleButtonPressed', messageList[1]];
            } else if (message === 'OnThrottleButtonReleased') {
            //  infor = ['OnThrottleButtonReleased', messageList[1]];
            }
            // console.log(infor);
            if (msg.cmd) {
              that.$emit('message', msg);
            }
          }
        };

        // 连接关闭的回调方法
        this.websocket.onclose = function () {
          console.error('WebSocket连接关闭');
          that.$emit('closed');
        };
      } else {
        alert('当前浏览器 Not support websocket');
      }

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = function () {
        this.websocket.close();
      };
    },

    // 关闭本机摇杆exe程序
    closeStickExe() {

    }
  }

};
</script>

<style lang="scss" scoped>
.stick {
  width: 0px;
  height: 0px;

}
</style>

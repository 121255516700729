<template>
  <div class="hive-information-wrapper" style="color:#F40">
    <s-alert :type="hiveInformation.workState?hiveInformation.workState:0 | classificationChart" style="margin-bottom: 10px;">{{ hiveInformation.workState?hiveInformation.workState:'离线' }}</s-alert>
    <el-card>
      <template v-if="HM == hiveModel.UH_MK5">
        <HiveModel slot="header" :roofState="hiveInformation.roofState" :stageState="hiveInformation.stageState" :braceState="hiveInformation.braceState" :clawState="hiveInformation.clawState" />
      </template>
      <template v-if="HM == hiveModel.UH_MK4 || HM == hiveModel.UH_MK4_Pro">
        <HiveModelMK4 slot="header" :roofState="hiveInformation.roofState" :stageState="hiveInformation.stageState" :braceState="hiveInformation.braceState" :clawState="hiveInformation.clawState" />
      </template>
      <div class="hive-info-wrap">
        <p>机库编号：{{ (siteDetail&&siteDetail.hiveInfo&&siteDetail.hiveInfo.hiveID)?siteDetail.hiveInfo.hiveID:'未知' }}</p>
        <p>机库类型：{{  hiveModelComputed }}</p>
      </div>
    </el-card>
    <ul class="floor-wrapper">
      <li class=" floor-item">
        <span>天花板状态</span>
        <span>{{ hiveInformation.roofState }}</span>
      </li>
      <li class="floor-item">
        <span>工作状态</span>
        <span>{{ hiveInformation.flowState }}</span>
      </li>
      <li class="floor-item">
        <span>升降台状态</span>
        <span>{{ hiveInformation.stageState }}</span>
      </li>
      <li class="floor-item">
        <span>横杆状态</span>
        <span>{{ hiveInformation.braceState }}</span>
      </li>
      <li class="floor-item">
        <span>卡爪状态</span>
        <span>{{ hiveInformation.clawState }}</span>
      </li>
      <li class="floor-item">
        <span>是否充电</span>
        <span>{{ hiveInformation.chargeOn }}</span>
      </li>
      <li class="floor-item">
        <span>充电流程</span>
        <span>{{ hiveInformation.isInChargingFlow }}</span>
      </li>
      <!-- <li class="floor-item">
                <span>充电电压</span>
                <span>{{ hiveInformation.chargeVoltageLevel }}v</span>
            </li> -->
    </ul>
  </div>
</template>

<script>
import HiveModel from './HiveModel/hiveModel';
import HiveModelMK4 from './HiveModel/hiveModelMK4.vue';

import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE'
export default {
  props: {
    HM: {
      type: String,
      default: SITE_HIVE_TYPE.HIVEMODEL.UH_MK5,
    },
    hiveInformation: {
      type: Object,
      default: {},
    },
    siteDetail: {
      type: Object,
      default: {},
    },
  },
  components: {
    HiveModel,
    HiveModelMK4,
  },

  data() {
    return {
      hiveModel: {
        UH_MK3: SITE_HIVE_TYPE.HIVEMODEL.UH_MK3, // 
        UH_MK4: SITE_HIVE_TYPE.HIVEMODEL.UH_MK4, // 
        UH_MK4_Pro: SITE_HIVE_TYPE.HIVEMODEL.UH_MK4_Pro,
        UH_MK5: SITE_HIVE_TYPE.HIVEMODEL.UH_MK5,
        MH_MK3: SITE_HIVE_TYPE.HIVEMODEL.MH_MK3, // 移动机库
      }
    }
  },
  created() {
    //   this.hiveModel =  SITE_HIVE_TYPE.HIVEMODEL
  },
  computed: {
    hiveModelComputed() {
      let model = '';
      if (this.siteDetail?.hiveInfo?.hiveModel) {
        model = this.siteDetail.hiveInfo.hiveModel
      }
      return SITE_HIVE_TYPE.HIVEMODEL.getDescFromValue(model)
    }
  }

};
</script>

<style lang="scss" scoped>
.hive-information-wrapper {
  height: 100%;
  .el-card {
    position: relative;
    ::v-deep .el-card__header {
      display: flex;
      padding: 30px 20px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #{$layoutLeftMenuBg};
    }
    ::v-deep .el-card__body {
      height: 120px;
      color: #fff;
      background: #{$layoutLeftMenuBg};
      border: 1px solid #0b137e;
      border-image: linear-gradient(#24952f, #313131) 20 20;

      .hive-info-wrap {
        height: 100%;
        p {
          margin: 0;
          padding: 10px;
          font-size: 14px;
        }
        // p:nth-first-child(1) {
        //     margin: 0;
        //     // padding-top: 10px;
        // }
        background: #{$leftMenuItem};
      }
    }
  }
  ul.floor-wrapper {
    padding: 0;
    font-size: 12px;
    > li {
      width: 100%;
      height: 30px;
      line-height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: aquamarine;
      background: #1f2d3d;
      border-radius: 4px;
      margin: 4px 0;
      span {
        padding: 0 12px;
      }
    }
  }
}
</style>

<template>
  <div class="silder-bar-wrapper">
    <input type="range" :ref="refAttribute" :min="min" v-model="sliderValue" :max="max" @change="handleSlide" />
    <div class="ctrl-area">
      <div class="ctrl-button">
        <input type="button" class="ctrl-btn reduction" value="-" @click="handleReduction(sliderValue)" />
        <span>{{ min }}</span>
      </div>
      <span>{{ sliderValue }}</span>
      <div class="ctrl-button">
        <span>{{ max }}</span>
        <input type="button" class="ctrl-btn add" value="+" @click="handleAdd(sliderValue)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderBar',
  props: ['max', 'min', 'value', 'refAttribute', 'autoInit', 'offset'],
  data() {
    return {
      sliderValue: 0,
      mouseActive: false,
    };
  },
  mounted() {
    const inputEle = this.$refs[this.refAttribute];
    inputEle.onmousedown = event => {
      this.mouseActive = true;
    };
    inputEle.onmouseup = event => {
      this.mouseActive = false;
    };
  },
  methods: {
    handleSlide() {
      this.change(this.sliderValue);
    },
    handleReduction() {
      console.log(this.sliderValue, this.min);
      if (this.sliderValue > this.min) {
        this.sliderValue -= this.offset;
        this.change(this.sliderValue);
      }
    },
    handleAdd() {
      console.log(this.sliderValue, this.max);
      if (this.sliderValue < this.max) {
        this.sliderValue += this.offset;
        this.change(this.sliderValue);
      }
    },
    autoInitValue() {
      this.autoInit &&
        setTimeout(() => {
          this.sliderValue = 0;
        }, 500);
    },
    change(v) {
      this.$emit('change', v);
      this.autoInitValue();
    },
  },
  watch: {
    value(n, o) {
      this.mouseActive || (this.sliderValue = n);
    },
  },
};
</script>

<style lang="scss" scoped>
.silder-bar-wrapper {
  width: 100%;
  height: 40px;
  .ctrl-area {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .ctrl-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        display: block;
        width: 40px;
        text-align: center;
      }
      input{
        color: black;
      }
    }
  }
  input[type='range'] {
    -webkit-appearance: none;
    background: #409eff;
    display: block;
    border-radius: 4px;
    width: 100%;
    height: 8px;
    outline: none;
    box-shadow: 0 0 6px #409eff;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    line-height: 8px;
    background: #364fc7;
    border-radius: 50%;
    transform: 0.3s;
    position: relative;
    border: 1px solid #fff;
  }
  input[type='range']::-webkit-slider-thumb::after {
    margin-right: 16px;
    background: #f53b57;
    width: 80px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    color: #fff;
    position: position;
  }
  input[type='range']:active::-webkit-slider-thumb {
    background: #ef5777;
    box-shadow: 0 0 0 6px rgba(155, 73, 146, 0.5);
  }
}
</style>

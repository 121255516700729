<template>
    <div class="verticalVelocity-wrap">
        <div id="verticalVelocity" :style="{ width: size, height: size }" ref="verticalVelocity"></div>
    </div>
</template>

<script>
export default {
    name: 'VerticalVelocity',
    props: {
        verticalSpeed: {
            type: Number,
            default() {
                return 0
            },
        },
        size:{
            type: String,
            default() {
                return '200px'
            },
        }
    },
    data() {
        return {
            myChart: null,
            pillarData: {},
        }
    },
    mounted() {
        this.setEchart()
    },
    methods: {
        setEchart() {
            this.myChart = this.$echarts.init(this.$refs.verticalVelocity)
            this.myChart.setOption(this.option)
        },
    },
    computed: {
        origin() {
            return this.verticalSpeed
        },
        option() {
            let that = this
            let obj = {
                title: {
                    bottom: '4%',
                    left: '35%',
                    text: '垂直速度',
                    textStyle: {
                        color: '#555',
                        padding: '2',
                        fontSize: '12',
                        borderRadius: 2,
                        padding: 2,
                        textShadowBlur: 2,
                        textShadowOffsetX: 1,
                        textShadowOffsetY: 1,

                        textShadowColor: '#EEE',
                    },
                },
                series: [
                    //外圈圆
                    {
                        name: '垂直速度',
                        type: 'pie',
                        radius: '70%',
                        animation: false,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                        itemStyle: {
                            color: '#333',
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [{ value: 1 }],
                    },
                    {
                        name: '垂直速度',
                        type: 'pie',
                        radius: '65%',
                        animation: false,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                        itemStyle: {
                            color: '#1e90ff',
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [{ value: 1 }],
                    },
                    {
                        name: '垂直速度',
                        type: 'pie',
                        z: 10,
                        radius: '60%',
                        center: ['50%', '50%'],
                        animation: false,
                        data: [{ value: 1 }],
                        labelLine: {
                            show: false,
                        },
                        itemStyle: {
                            color: '#3d3b4f',
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        axisLine: {
                            show: true,
                            length: 2,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [0.5, '#119ade'],
                                    [1, '#e5ac0c'],
                                ],
                            },
                        },
                    },
                    {
                        name: '垂直速度',
                        type: 'gauge',
                        radius: '59%',
                        min: 5, //最小刻度
                        max: -5, //最大刻度
                        splitNumber: 10, //刻度数量
                        startAngle: 90,
                        endAngle: -90,
                        z: 20,
                        axisLabel: {
                            show: true,
                            color: 'auto',
                            fontSize: 10,
                            formatter: function (v) {
                                return v
                            },
                        }, //内刻度线
                        axisTick: {
                            show: false,
                            splitNumber: 2,
                            lineStyle: {
                                color: '#c2ccd0',
                                width: 1,
                                length: -10,
                                distance: 0,
                            },
                            // length: -20,
                        }, //分隔刻度线
                        splitLine: {
                            show: true,
                            length: 6,
                            lineStyle: {
                                color: '#EEE',
                                width: 2,
                            },
                        },
                        axisLine: {
                            show: true,
                            length: 2,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [0.5, '#119ade'],
                                    [1, '#e5ac0c'],
                                ],
                            },
                        },

                        pointer: {
                            show: true,
                            length: '70%',
                            radius: '20%',
                            zlevel: 20,
                            width: 3, //指针粗细
                        },
                        title: {
                            name: 'M/S',
                            fontSize: '14',
                            fontWeight: 'bolder',
                            borderColor: '#aaa',
                            color: '#FFFFFF',
                            fontFamily: '黑体',
                            width: 20,
                            // textAlign : "auto",
                            bottom: '20%',
                        },
                        detail: { formatter: '{value}' },
                        data: [{ value: this.origin, name: 'M/S' }],
                    },
                ],
                detail: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    formatter: function (value) {
                        value = (value + '').split('.')
                        value.length < 2 && value.push('00')
                        return (
                            ('00' + value[0]).slice(-2) +
                            '.' +
                            (value[1] + '00').slice(0, 2)
                        )
                    },
                    // z:1,
                    fontSize: '12',
                    fontWeight: 'bolder',
                    borderRadius: 3,
                    backgroundColor: '#444',
                    borderColor: '#aaa',
                    shadowBlur: 5,
                    shadowColor: '#333',
                    shadowOffsetX: 0,
                    shadowOffsetY: 3,
                    borderWidth: 1,
                    textBorderColor: '#000',
                    textBorderWidth: 2,
                    textShadowBlur: 2,
                    textShadowColor: '#fff',
                    textShadowOffsetX: 0,
                    textShadowOffsetY: 0,
                    offsetCenter: [-10, 110],
                    fontFamily: 'Arial',
                    width: 20,
                    color: '#eee',
                    rich: {
                        fontSize: '10',
                    },
                },
            }
            return obj
        },
    },
    watch: {
        origin(newValue, oldValue) {
            this.myChart.setOption(this.option, true)
        },
         size(v){
          this.$nextTick(()=>{
            this.myChart.resize()
          })
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
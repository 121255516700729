<template>
  <div class="schedule-box-wrap">
    <el-table :data="planList" style="width: 100%">
      <el-table-column prop="date" label="执飞时间" width="80px" align="center">
        <template slot-scope="scope">
          <span>{{ $moment(scope.row.excuteTime * 1000).format("LTS") }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="missionName"
        label="任务名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="missionState"
        label="任务状态"
        width="80px"
        align="center"
      >
        <template slot-scope="scope">
          <span :class="subState(scope.row.excuteState)">{{
            state(scope.row.excuteState)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="missionState"
        label="操作"
        width="80px"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            :type="scope.row.excuteState ? 'danger' : 'succes'"
            :disabled="scope.row.excuteState ? true : false"
            @click="removeFloorItem(scope.row)"
            >取消</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    planList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    state(i) {
      let res = "未知";
      switch (i) {
        case 0:
          res = "未执行";
          break;
        case 1:
          res = "已执行";
          break;
        default:
          res = "未知";
      }
      return res;
    },
    removeFloorItem(item) {
      this.$emit("removeFloorItem", item.CMID);
    },
  },
  computed: {
    subState(i) {
      return (i) => {
        let _className = "red";
        switch (i) {
          case 0:
            _className = "orange";
            break;
          case 1:
            _className = "green";
            break;
          default:
            _className = "red";
        }
        return _className;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-box-wrap {
  width: 100%;
  height: 100%;
  .red {
    color: #f40;
  }
  .green {
    color: green;
  }
  .yellow {
    color: yellow;
  }
  .orange {
    color: orange;
  }
}
</style>

<template>
  <div class="tool-item-wrapper">
    <div @click="handleClick(toolId)">
      <i :class="[className, icon, elementUI ? '' : 'iconfont']"></i>
      <span>{{ title }}</span>
    </div>
    <slot name="menu-options" v-if="action"></slot>
  </div>
</template>

<script>
export default {
  props: {
    toolId: {
      type: Number,
    },
    elementUI: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    hasMenuOptions: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isAction: false,
    };
  },
  methods: {
    handleClick(id) {
      this.$emit('handleClick', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tool-item-wrapper {
  padding: 10px;
  position: relative;
  background: #{$navBarBg};
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    /deep/ .el-checkbox {
      width: 112px;
    }
    i {
      padding: 4px 5px;
      margin-bottom: 6px;
      line-height: 22px;
      font-size: 22px;
      color: #313131;
      background: #fff;
      border-radius: 4px;
    }
    .is-action {
      background: #f40;
      color: #fff;
    }

    .step {
      background: #f40;
      color: #fff;
    }
    .red {
      color: #f40;
    }
    span {
      font-size: 14px;
      font-family: 'Alibaba-PuHuiTi-Medium';
    }
  }
  div:active > i {
    background: #131a24;
    color: #fff !important;
    transition: 4;
  }
}
</style>

const MODULES = [
  { icon: 'icon-rizhiguanli', label: '人车识别', code: 6147, isShow: true },
  { icon: 'icon-shujutongji', label: '垃圾识别', code: 4100, isShow: true },
  { icon: 'icon-box', label: '车牌识别', code: 12289, isShow: true },
  { icon: 'icon-box', label: '蓝藻检测', code: 8193, isShow: true },
  { icon: 'icon-shujutongji', label: '偷鱼检测', code: 16385, isShow: true },
  { icon: 'icon-box', label: '违建检测', code: 4104, isShow: true },
  { icon: 'icon-box', label: '烟火检测', code: 20481, isShow: true },
  { icon: 'icon-shujutongji', label: '安全帽检测', code: 24577, isShow: false }
]
export {
  MODULES
}
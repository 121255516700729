<template>
  <div class="flightIndicator">
    <!-- <span id="airspeed"></span> -->
    <!-- <span id="altimeter"></span> -->
    <span id="heading"></span>
    <span class="instrument-type">飞行航向</span>
    <!-- <span id="variometer"></span> -->
    <!-- <span id="turn_coordinator"></span> -->
  </div>
</template>

<script>
import $ from '@/utils/jquery-v.js';
import flightIndicator from '@/utils/jquery.flightindicators.min.js';
export default {
  name: 'FlightIndicator',
  props: {
    compassState: {
      type: Number,
      default() {
        return 0;
      },
    },
     size:{
            type: String,
            default() {
                return '200px'
            },
        }
  },
  data() {
    return {
      airspeed: null,
      heading: null,
    };
  },
  mounted() {
    // this.setFlightIndicator()
    this.state();
  },
  methods: {
    state() {
      var first_attitude = $.flightIndicator('#first_attitude', 'attitude', {
        size: 170,
        roll: 8,
        pitch: 3,
        showBox: false,
      });
      // Dynamic examples

      this.heading = $.flightIndicator('#heading', 'heading', {
        heading: 150,
        size: 170,
        showBox: false,
      });
      this.heading.setHeading(0);
     
    },
  },
  watch: {
    compassState(newVal, oldVal) {
      this.heading.setHeading(newVal);
    },
    size(v){
         this.heading.resize(parseInt(parseInt(v.split('px')[0])/20*17))
        }
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.flightIndicator {
  position: relative;
  .instrument-type {
    position: absolute;
    left: 58px;
    bottom: 11px;
    font-weight: 600;
    color: #333;
    font-size: 12px;
  }
}
</style>

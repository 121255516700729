/*
 * @Author: 篆愁君
 * @Date: 2021-10-24 17:42:18
 * @LastEditors: 篆愁君
 * @LastEditTime: 2021-10-24 17:42:19
 * @Description: file content
 */
export const keyToZhCn = key => {
    let zh = '';
    switch (key) {
        case 'flowState':
            zh = '工作状态';
            break;
        case 'roofState':
            zh = '天花板状态';
            break;
        case 'stageState':
            zh = '升降台状态';
            break;
        case 'braceState':
            zh = '横杆状态';
            break;
        case 'clawState':
            zh = '卡爪状态';
            break;
        case 'missionID':
            zh = '任务编号';
            break;
        case 'missionBatch':
            zh = '任务批次号';
            break;
        case 'longitudeWGS':
            zh = '无人机坐标';
            break;
        case 'latitudeWGS':
            zh = '无人机坐标';
            break;
        case 'subState':
            zh = '无人机工作状态';
            break;
        case 'battery1Percent':
            zh = '电池1';
            break;
        case 'battery1Temperature':
            zh = '电池1温度';
            break;
        case 'battery2Percent':
            zh = '电池2';
            break;
        case 'battery2Temperature':
            zh = '电池2温度';
            break;
        case 'percent':
            zh = '总电量';
            break;
        case 'windSpeed':
            zh = '风速';
            break;
        case 'isRaining':
            zh = '雨雪报警';
        case 'newIsRaining':
            zh = '天气预报';
            break;
    }
    return zh;
};

import request from "@/utils/request"
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;


//根据批次号获取无人机飞行相关信息
function getBatchinfo(missionBatch = '') {
  return request({
    url: `${server}/${base_url.core}/flightflows/batchinfo?missionBatch=${missionBatch}`,
    method: 'GET'
  })
}

export {
  getBatchinfo
}


import createEnum from "./createEnum"

export const HIVE_STATE = {
  WORK: createEnum({
    free: [100, '空闲'],
    opening: [200, '打开中'], // 开机库
    closing: [300, '关闭中'],
    uavCharging: [400, '无人机充电中'],
    uavChargeExchanging: [500, '无人机换电中'],
    maintaining: [600, '维护中'],
    error: [700, '故障'],
  }, '未知'),

  ROOF: createEnum({
    unkown: [0, '未知'],
    opened: [1, '已打开'], // 开机库
    opening: [2, '打开中'], // 开机库
    cloed: [3, '已关闭'],
    closing: [4, '关闭中'],
  }, '未知'),
  FLOW: createEnum({
    free: [0, '空闲'],
    flying: [1, '起飞中'], // 开机库
    standby: [2, '待机中'], // 开机库
    landing: [3, '降落中'],
    deviceRecycle: [4, '回收设备'],
    maintaining: [5, '维护中'],
    activeUAV1: [6, '激活无人机I'],
    activeUAV2: [7, '激活无人机II'],
    selfDefine: [10, '自定义动作'],
    error: [255, '错误'],
  }, '未知'),
  STAGE: createEnum({
    unkown: [0, '未知'],
    top: [1, '顶部'], // 开机库
    rising: [2, '上升中'], // 开机库
    bottom: [3, '底部'],
    dropping: [4, '下降中'],
    undefined: [255, '未定义'],
  }, '未知'),

  BRACE: createEnum({
    unkown: [0, '未知'],
    clamp: [1, '夹紧'], // 开机库
    clamping: [2, '夹紧中'], // 开机库
    loose: [3, '松开'],
    loosing: [4, '松开中'],
    undefined: [255, '未定义'],
  }, '未知'),
  CLAW: createEnum({
    daiming2: [0, '未知'],
    zhunbeizhong: [1, '夹紧'], // 开机库
    qifeizhong: [2, '夹紧中'], // 开机库
    renwuzhong2: [3, '松开'],
    fanhangzhong2: [4, '松开中'],
    undefined: [255, '未定义'],
  }, '未知'),
}

// 绿色的状态（正常）
export const HIVE_WORK_GREEN = [
  HIVE_STATE.WORK.free,
  HIVE_STATE.WORK.opening

]

// 红色的状态（不正常）
export const HIVE_WORK_RED = [
  HIVE_STATE.WORK.error
]

// 橘色的状态（警告）
export const HIVE_WORK_ORANGE = [
  HIVE_STATE.WORK.closing,
  HIVE_STATE.WORK.uavCharging,
  HIVE_STATE.WORK.uavChargeExchanging,
  HIVE_STATE.WORK.maintaining
]






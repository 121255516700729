import createEnum from './createEnum';

export const UAV_ACTION = {
  POS: createEnum(
    {
      // 无人机空间位置
      height: [201, '高度调整'],
      forward: [202, '向前调整'],
      backward: [203, '向后调整'],
      left: [204, '向左调整'],
      right: [205, '向右调整'],
      head: [206, '无人机朝向调整'],
    },
    '未知',
  ),

  MISSION: createEnum(
    {
      // 无人机任务
      pause: [110, '暂停'],
      stop: [111, '停止'],
      recover: [112, '恢复'],
      urgencyStop: [113, '紧急停止'],
      backtoStart: [114, '返回起始点'],
      backtoAlternate: [115, '返回备降点'],
      tempPt: [116, '指点飞行'],
    },
    '未知',
  ),

  GIMBAL: createEnum(
    {
      // 云台
      pitch: [401, '俯仰角'],
      head: [402, '航偏角'],
      recover: [403, '复位'],
    },
    '未知',
  ),

  CAMERA: createEnum(
    {
      // 相机
      snap: [100, '拍照'],
      video: [101, '录像'],
      videoStop: [102, '停止录像'],
      zoom: [103, '变焦'],
      zoomStop: [104, '停止变焦'],
      lens: [105, '镜头切换'],
      track: [107, '目标追踪'],
    },
    '未知',
  ),

  RC: createEnum(
    {
      // 遥控器
      onOrOff: [301, '遥控器开关机'],
    },
    '未知',
  ),

  MEDIA: createEnum(
    {
      // 媒体资源
      upload: [120, '媒体数据从无人机SD卡上传至云端'],
    },
    '未知',
  ),

  // 喊话器501-语音传输 502-音量调节 503-停止语音播放
  MEGOPHONE: createEnum(
    {
      // 喊话器
      play: [501, '播放'],
      volume: [502, '调节音量'],
      pause: [503, '暂停'],
    },
    '未知',
  ),
};

import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

/**
 * @description: 无人机任务控制
 * @param {id} 无人机编号
 * @param {type} 动作类型 //110-暂停 ，111-停止，112-恢复 ，113-紧急停止 114-返回起始点 115-返回备降点
 * @return {Promise}
 */

function missionSetting(id = '', type = '') {
  return request({
    url: `${server}/${base_url.core}/uavaction/missionsetting?UAVID=${id}&type=${type}`,
    method: 'POST',
  });
}

/**
 * @description:
 * @param {String} id 无人机编号
 * @param {Number} type 301-遥控器开关机
 * @param {Number} param 301（1-开启遥控器，2-关闭遥控器）
 * @return {Object<Promise>}
 */
function rcSetting(id = '', type = '', param = '') {
  return request({
    url: `${server}/${base_url.core}/rcsetting?UAVID=${id}&type=${type}&param=${param}`,
  });
}

/**
 * @description: 无人机空间位置设置
 * @param {String} id 无人机编号
 * @param {Number} type 201-高度调整 202-向前调整 203-向后调整 204-向左调整 205-向右调整 206-无人机朝向调整
 * @param {Number} param 参数值
 * @return {Object<Promise>}
 */
function locationSetting(id, type, param = '') {
  return request({
    url: `${server}/${base_url.core}/uavaction/locationsetting?UAVID=${id}&type=${type}&param=${param}`,
    method: 'POST',
  });
}

/**
 * @description: 无人机虚拟摇杆设置
 * @param {Object} data
 * @return {Object:<Promise>}
 */
function joystickSetting(data) {
  return request({
    url: `${server}/${base_url.core}/uavaction/joysticksetting`,
    method: 'POST',
    data,
  });
}

/**
 * @description: 无人机相机设置
 * @param {String} id 无人机编号
 * @param {Number} type 类型，
 * @param {Number} param 值
 * @return {Object<Promise>}
 */

function cameraSetting(id, type, param = '', coorX = '', coorY = '', PZoom = '') {
  console.info(coorX, coorY, PZoom);
  return request({
    url: `${server}/${base_url.core}/uavaction/camerasetting?UAVID=${id}&type=${type}&param=${param}&coorX=${coorX}&coorY=${coorY}&PZoom=${PZoom}`,
    method: 'POST',
  });
}

// 107-目标追踪
function cameraFollwByCoors(id, coorLoc = null) {
  let data = { paramAr: coorLoc, UAVID: id, type: 107 },
    url = `${server}/${base_url.core}/uavaction/camerasetting`;
  return request({
    url: url,
    data: data,
    method: 'POST',
  });
}

// 108-开启或关闭目标追踪
function startCameraTrack(UAVID, isTargetTrace) {
  let data = { UAVID, isTargetTrace, type: 108 },
    url = `${server}/${base_url.core}/uavaction/camerasetting?UAVID=${UAVID}&type=108&isTargetTrace=${isTargetTrace}`;
  return request({
    url: url,
    data: data,
    method: 'POST',
  });
}

/**
 * @description: 区域变焦
 * @param {String} id 无人机id
 * @param {Array} coorLoc [[上左]，[上右]，[右下]，[下左]]
 * @return {Object<Promise>}
 */
function cameraZoomByCoors(id, coorLoc = null) {
  let data = { paramAr: coorLoc, UAVID: id, type: 106 },
    url = `${server}/${base_url.core}/uavaction/camerasetting`;
  return request({
    url: url,
    data: data,
    method: 'POST',
  });
}
/**
 * @description: 无人机云台设置
 * @param {String} id 无人机id
 * @param {Number} type 401-俯仰角 402-航偏角 403-复位
 * @param {String} param //具体的偏转角度值
 * @return {Object<Promise>}
 */
function gimbalSetting(id, type, param) {
  return request({
    url: `${server}/${base_url.core}/uavaction/gimbalsetting?UAVID=${id}&type=${type}&param=${param}`,
    method: 'POST',
  });
}

/**
 * @description: 无人机全自动任务控制
 * @param {Object} data
 * @return {Object<Promise>}
 */

function automaticSetting(data) {
  return request({
    url: `${server}/${base_url.core}/uavaction/missionadd`,
    method: 'POST',
    data,
  });
}

/**
 * @description: 喊话器
 * @param {Object} data
 * @return {Object<Promise>}
 */

function megophonesetting(data) {
  return request({
    url: `${server}/${base_url.core}/uavaction/megophonesetting`,
    method: 'POST',
    data,
  });
}

/**
 * @description: 指点飞行
 * @param {Object} data
 * @return {Object<Promise>}
 */

function uavpointingflight(data) {
  return request({
    url: `${server}/${base_url.core}/uavaction/pointingflight`,
    method: 'POST',
    data,
  });
}

/**
 * @description: 激光测距  109
 * @param {Object} data
 * @return {Object<Promise>}
 */
function startLaserGuage(UAVID, isLaserDistance) {
  let data = { UAVID, isLaserDistance, type: 109 },
    url = `${server}/${base_url.core}/uavaction/camerasetting?UAVID=${UAVID}&type=109&isLaserDistance=${isLaserDistance}`;
  return request({
    url: url,
    data: data,
    method: 'POST',
  });
}

// 机库开合
function openHive(hiveID, t) {
  const data = { hiveID, type: t ? 101 : 102 };
  const url = `${server}/${base_url.core}/hiveaction/maintenance`;
  return request({
    url,
    data,
    method: 'POST'
  });
}
// 指点变焦
function cameraAutoByPoint(id, coorAr = null) {
  const data = { coorAr, UAVID: id, type: 110, zoom: 0 };
  const url = `${server}/${base_url.core}/uavaction/camerasetting`;
  return request({
    url,
    data,
    method: 'POST'
  });
}
export { uavpointingflight, missionSetting, rcSetting, locationSetting, joystickSetting, cameraSetting, cameraZoomByCoors, cameraAutoByPoint, cameraFollwByCoors, startCameraTrack, gimbalSetting, automaticSetting, megophonesetting, startLaserGuage, openHive };

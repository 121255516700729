import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

function mediaSetting(id = '', type = '') {
  return request({
    url: `${server}/${base_url.core}/uavaction/mediasetting?UAVID=${id}&type=${type}`,
    method: 'POST',
  })
}

export {
  mediaSetting,
}
<template>
  <div class="examine-bar-wrap">
    <div class="title" :class="state | classNameStatus">起飞前检查清单</div>
    <div class="announcement-wrap">
      <img class="pic-icon" src="~@/assets/images/examine/checklist.png" alt="" />
      <i class="announcement">我们需要检查各项参数，以确保飞行安全性</i>
    </div>
    <div class="check-area-wrap tab-title-bar">
      <el-row class="target">
        <el-col class="icon" :span="4">
          <img src="~@/assets/images/examine/weather.png" alt="" style="width: 70px; height: 70px; opacity: 0" />
        </el-col>
        <el-col :span="20">
          <ul class="check-target intitle">
            <li v-for="item in checkGroup.title" :key="item.label">
              <div class="msg">
                <span class="label">{{ item.label }}</span>
                <span class="val-box">{{ item.value }}</span>
                <span class="standard">{{ item.standard }}</span>
              </div>
              <img :src="item.status ? pic.through : pic.error" alt="" v-if="item.status !== ''" />
              <i class="el-icon-loading" style="margin-right: 17px; color: #69c0ff; font-size: 24px" v-else></i>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div class="check-area-wrap">
      <el-row class="target weather" v-if="site && site.siteMode == 1">
        <el-col class="icon" :span="4">
          <img src="~@/assets/images/examine/weather.png" alt="" style="width: 70px; height: 70px" />
        </el-col>
        <el-col :span="20">
          <ul class="check-target">
            <li v-for="item in checkGroup.weather" :key="item.label">
              <div class="msg">
                <span class="label">{{ item.label }}</span>
                <span class="val-box">{{ item.value }}</span>
                <i class="standard">{{ item.standard }}</i>
              </div>
              <img :src="item.status ? pic.through : pic.error" alt="" v-if="item.status !== ''" />
              <i class="el-icon-loading" style="margin-right: 17px; color: #69c0ff; font-size: 24px" v-else></i>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row class="target mission">
        <el-col class="icon" :span="4">
          <img src="~@/assets/images/examine/mission.png" alt="" style="width: 70px; height: 70px" />
        </el-col>
        <el-col :span="20">
          <ul class="check-target">
            <li v-for="item in checkGroup.mission" :key="item.label">
              <div class="msg">
                <span class="label">{{ item.label }}</span>
                <span class="val-box">{{ item.value }}</span>
                <i class="standard">{{ item.standard }}</i>
              </div>
              <img :src="item.status ? pic.through : pic.error" alt="" v-if="item.status !== ''" />
              <i class="el-icon-loading" style="margin-right: 17px; color: #69c0ff; font-size: 24px" v-else></i>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row class="target hive" v-if="site && site.siteMode == 1">
        <el-col :span="4">
          <img src="~@/assets/images/examine/hive.png" alt="" style="width: 70px; height: 70px" />
        </el-col>
        <el-col class="icon" :span="20">
          <ul class="check-target">
            <li v-for="item in checkGroup.hive" :key="item.label">
              <div class="msg">
                <span class="label">{{ item.label }}</span>
                <span class="val-box">{{ item.value }}</span>
                <i class="standard">{{ item.standard }}</i>
              </div>
              <img :src="item.status ? pic.through : pic.error" alt="" v-if="item.status !== ''" />
              <i class="el-icon-loading" style="margin-right: 17px; color: #69c0ff; font-size: 24px" v-else></i>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row class="target uav">
        <el-col class="icon" :span="4">
          <img src="~@/assets/images/examine/uav.png" alt="" style="width: 70px; height: 70px" />
        </el-col>
        <el-col :span="20">
          <ul class="check-target">
            <template v-for="item in checkGroup.uav">
              <li v-if="!item.hide"  :key="item.label">
              <div class="msg">
                <span class="label">{{ item.label }}</span>
                <span class="val-box">{{ item.value }}</span>
                <i class="standard">{{ item.standard }}</i>
              </div>
              <img :src="item.status ? pic.through : pic.error" alt="" v-if="item.status !== ''" />
              <i class="el-icon-loading" style="margin-right: 17px; color: #69c0ff; font-size: 24px" v-else></i>
            </li>
            </template>
          </ul>
        </el-col>
      </el-row>
      <el-row class="target hive" v-if="endSiteID">
        <el-col :span="4">
          <img src="~@/assets/images/examine/hive.png" alt="" style="width: 70px; height: 70px" />
        </el-col>
        <el-col class="icon" :span="20">
          <ul class="check-target">
            <li>
              <div class="msg">
                <span class="label">终点机库状态</span>
                <span class="val-box">{{ endHiveInfo && endHiveInfo.flowState }}</span>
                <i class="standard"></i>
              </div>
              <img :src="through ? pic.through : pic.error" alt="" v-if="endHiveChecked" />
              <i class="el-icon-loading" style="margin-right: 17px; color: #69c0ff; font-size: 24px" v-else></i>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div class="check-page-footer">
      <div class="footer-message">
        <i class="el-icon-loading" v-if="load"></i>
        <img :src="pic[evaluation.type]" alt="" style="width: 40px; height: 40px" v-else />
        <span style="padding: 0px 20px">{{ evaluation.message }}</span>
      </div>
      <div class="but-group">
        <el-button class="btn el-icon el-icon-s-promotion" size="small" type="success"  @click="handelExamine(true)">{{ load ? '起飞检查中' : '发送任务' }}</el-button>
        <el-button class="btn cancel" size="small" type="warning" @click="handelExamine(false)">取消发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import parsingDroneInfomation from '@/utils/parsingDroneInfomation';
import { keyToZhCn } from '@/utils/KeyToZhCN.js';
import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE.js';

const CONTROL_VALUE = {
  batteryTemperature: 50,
  batteryPercent: 60

};
export default {
  name: 'Examine',
  props: {
    hiveInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    endHiveInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    endSiteID: {
      type: String,
      default() {
        return null;
      },
    },
    site: {
      type: Object,
      default() {
        return {};
      },
    },

    uavInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    weather: {
      type: Object,
      default() {
        return {};
      },
    },
    netWeather: {
      type: Object,
      default() {
        return {};
      },
    },
    hiveData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  filters: {
    classNameStatus(state) {
      const classNames = {
        0: 'warning',
        1: 'success',
        2: 'error',
      };
      return classNames[state];
    },
  },
  data() {
    return {
      CONTROL_VALUE,
      endHiveChecked: false,
      through: false,
      CHECKOPTION: {
        flowState: 'flowState',
        clawState: 'clawState',
        subState: 'subState',
        battery1Percent: 'battery1Percent',
        battery1Temperature: 'battery1Temperature',
        battery2Percent: 'battery2Percent',
        battery2Temperature: 'battery2Temperature',
        percent: 'percent',
        windSpeed: 'windSpeed',
        isRaining: 'isRaining',
      },
      state: 0,
      time: 1000,
      hiveStuts: 'wait',
      uavStuts: 'wait',
      weatherStuts: 'wait',
      globalStuts: 'wait',
      hiveInitStateErrorSheet: '',
      uavInitStateErrorSheet: '',
      weatherInitStateErrorSheet: '',
      globalInitStateErrorSheet: '',
      ErrorSheet: '',
      state: 0,
      messageGather: [],
      load: false,
      num: 0,
      hasErr: false,
      pic: {
        through: require('@/assets/images/examine/yes.png'),
        error: require('@/assets/images/examine/no.png'),
        warning: require('@/assets/images/examine/warning.png'),
      },
      checkGroupKey: [],
      checkGroup: {
        //weather,mission,hive,uav,
        title: [{ label: '检查项', value: '实际值', standard: '控制值' }],
        weather: [
          {
            label: '当前风速',
            value: '',
            status: '',
            standard: '小于6.5m/s',
          },
          {
            label: '当前雨雪',
            value: '',
            status: '',
            standard: '无雨',
          },
        ],
        mission: [
          {
            label: '任务里程',
            value: '',
            status: '',
            standard: '小于30KM',
          },
          {
            label: '任务时间',
            value: '',
            status: '',
            standard: '小于续航时间',
          },
        ],
        hive: [
          {
            label: '起点机库状态',
            value: '',
            status: '',
            standard: '',
          },
        ],
        uav: [
          {
            label: '无人机状态',
            value: '',
            status: '',
            standard: '',
          },
          {
            label: '续航时间',
            value: '',
            status: '',
            standard: '大于任务时间',
          },
          {
            label: '电池1电量',
            value: '',
            status: '',
            standard: '大于60%',
          },
          {
            label: '电池1温度',
            value: '',
            status: '',
            standard: '小于50℃',
          },
          {
            label: '电池2电量',
            value: '',
            status: '',
            standard: '大于60%',
            hide:false
          },
          {
            label: '电池2温度',
            value: '',
            status: '',
            standard: '小于50℃',
             hide:false
          },
        ],
      },
      rules: ['雨', '雪', '雾', '霾', '雷', '沙'],
      hasErrMessage: [],
      evaluation: {
        message: '',
        type: '',
      },
      isDisabled: false,
      K0: 22.03,
      K1: 9.226,
      K2: 0.2791,
      C: 0.85,
    };
  },
  watch: {
    num(newVal) {
      console.error(newVal)
      this.hasErr || (this.hasErr = true);
      if (newVal === 0) this.hasErr = false;
    },
    hasErr(newVal){
      console.error(newVal)
    },
    'uavInfo.batteries'(){
      if(this.uavInfo.batteries.length==1){
        delete this.CHECKOPTION['battery2Percent'];
        delete this.CHECKOPTION['battery2Temperature'];
        this.checkGroup.uav[4].hide=true
         this.checkGroup.uav[5].hide=true
       }
    },
    endSiteID() {
      this.endHiveChecked = false;
    },

    /**
     * @description: 自检完成是否有错误
     * @param {*} newVal
     * @param {*} oldVal
     * @return {*}
     * @author: 篆愁君
     */
    load(newVal, oldVal) {
      if (newVal === false) {
        this.state = this.hasErr ? 2 : 1;
      }
    },
  },

  created() {
    if (this.site && this.site.siteMode != 1) {
      delete this.CHECKOPTION['isRaining'];
      delete this.CHECKOPTION['windSpeed'];
      delete this.CHECKOPTION['clawState'];
      delete this.CHECKOPTION['flowState'];
       this.CONTROL_VALUE.batteryPercent = 30;
    this.checkGroup.uav[2].standard = `大于${this.CONTROL_VALUE.batteryPercent}%`;
    this.checkGroup.uav[4].standard = `大于${this.CONTROL_VALUE.batteryPercent}%`;
    }
  },

  mounted() {
    this.examine_state()
      .then((_) => {
        const { weather, mission, hive, uav } = this.checkGroup;
        let hiveTag = this.site && this.siteMode == 1;
        if (hiveTag) {
          weather.forEach((e) => {
            if (e.status === '') {
              e.status = false;
              e.value = '未知';
            }
          });
        }

        mission.forEach((e) => {
          if (e.status === '') {
            e.status = false;
            e.value = '未知';
          }
        });
        if (hiveTag) {
          hive.forEach((e) => {
            if (e.status === '') {
              e.status = false;
              e.value = '未知';
            }
          });
        }

        uav.forEach((e) => {
          if (e.status === ''&&!e.hide) {
            this.hasErrMessage.push('error');
            e.status = false;
            e.value = '未知';
          }
        });
        return this.hasErrMessage;
      })
      .then((res) => {
        console.error(res)
        this.load = false;
        const len = res.length;
        if (len === 0) {
          this.evaluation.message = '可以起飞';
          this.evaluation.type = 'through';
        } else {
          if (res.indexOf('error') !== -1) {
            this.evaluation.message = '不允许起飞';
            this.evaluation.type = 'error';
            this.isDisabled = true;
          } else {
            this.evaluation.message = '不建议起飞';
            this.evaluation.type = 'warning';
          }
        }
        return true;
      });
  },
  methods: {
    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    /* 报文数据检测 */
    detectionMessage(key, stateItem, valGather = 0) {
      console.warn(key + '==========================='+valGather);
      switch (key) {
        case 'flowState':
          if (this.hiveData.hiveModel === 'HUB') return;
          if (valGather === '空闲' || valGather === '维护中') {
            stateItem.state = true;
          } else {
            stateItem.state = false;
          }
          this.checkGroup.hive[0].value = valGather;
          this.checkGroup.hive[0].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('error');
          break;
        case 'subState':
          stateItem.subState = parsingDroneInfomation.droneSubState(stateItem.subState);
          valGather = stateItem.subState;
          if (valGather === '待命' || valGather === '可调飞'|| valGather === '充电可执飞') {
            stateItem.state = true;
          } else {
            stateItem.state = false;
          }
          this.checkGroup.uav[0].value = valGather === '雨天' ? '不可飞' : valGather;
          this.checkGroup.uav[0].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('error');
          break;
        case 'battery1Percent':
          stateItem.state = valGather > this.CONTROL_VALUE.batteryPercent;
          this.checkGroup.uav[2].value = valGather + '%';
          this.checkGroup.uav[2].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('error');
          break;
        case 'battery1Temperature':
          stateItem.state = valGather < 50 ? true : false;
          this.checkGroup.uav[3].value = parseInt(valGather) + '℃';
          this.checkGroup.uav[3].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('error');
          break;
        case 'battery2Percent':
          stateItem.state = valGather > this.CONTROL_VALUE.batteryPercent;
          this.checkGroup.uav[4].value = valGather + '%';
          this.checkGroup.uav[4].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('error');
          break;
        case 'battery2Temperature':
          stateItem.state = valGather < 50 ? true : false;
          this.checkGroup.uav[5].value = parseInt(valGather)  + '℃';
          this.checkGroup.uav[5].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('error');
          break;
        case 'percent':
          valGather = 0;
          if(this.uavInfo.batteries&&this.uavInfo.batteries.length>0){
            for(let battery of this.uavInfo.batteries){
                valGather=valGather+battery.batteryPercent
            }
            valGather= parseInt(valGather/this.uavInfo.batteries.length)
          }
          console.error(valGather)
          const { mileage, timeoflight } = this.$store.state.mission.distribute;
          const res = this.batteryLife(valGather, timeoflight);
          this.checkGroup.mission[0].value = this.toFilters(mileage / 1000, 2) + 'km';
          this.checkGroup.mission[0].status = mileage > 30000 ? false : true;
          this.checkGroup.mission[1].value = this.toFilters(timeoflight / 60, 2) + 'min';
          this.checkGroup.mission[1].status = res.flag;
          this.checkGroup.uav[1].value = this.T + 'min';
          this.checkGroup.uav[1].status = this.T * 60 > timeoflight ? true : false;
          this.checkGroup.mission[1].status || this.hasErrMessage.push('error');
          if (!this.checkGroup.mission[1].status) {
            this.num++;
          }
          if (!this.checkGroup.mission[0].status) {
            this.num++;
          }
          if (!this.checkGroup.uav[1].status) {
            this.num++;
          }
          break;
        case 'windSpeed':
          stateItem.state = parseFloat(this.toFilters(valGather)) < 6.5 ? true : false;
          this.checkGroup.weather[0].value = parseFloat(this.toFilters(valGather, 1)) + 'm/s';
          this.checkGroup.weather[0].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('warning');
          break;
        case 'isRaining':
          if (this.hiveData.hiveModel === 'HUB') return;
          stateItem.state = valGather === '无雨' ? true : false;
          this.checkGroup.weather[1].value = valGather;
          this.checkGroup.weather[1].status = stateItem.state;
          stateItem.state || this.hasErrMessage.push('warning');
          break;
        // this.checkGroup.weather[2].value = valGather
        // this.checkGroup.weather[2].status = !this.contains(valGather, this.rules)
        // this.checkGroup.weather[2].status || this.hasErrMessage.push('warning')
        default:
          stateItem.state = true;
          break;
      }
      if (key != 'percent' && !stateItem.state) {
        this.num++;
      }
      return stateItem;
    },
    /**
     * @description: 计算续航时间
     * @param {*} totalAmount 总电量
     * @param {*} consumption 飞行时间
     * @return {*} true Boolean
     */
    batteryLife(totalAmount, timeoflight) {
      console.error(arguments)
      const time = 33.3 * (totalAmount / 100 - 0.1);
      const is = timeoflight > time * 60 ? false : true;
      return { flag: is, time: this.toFilters(time, 2) };
    },
    examine_state() {
      return new Promise(async (resolve, reject) => {
        let _hiveInfo = JSON.parse(JSON.stringify(this.hiveInfo));
        let _uavInfo = JSON.parse(JSON.stringify(this.uavInfo));
        let _weather = JSON.parse(JSON.stringify(this.weather));

        let stateGather = {};
        Object.assign(stateGather, _hiveInfo, _uavInfo, _weather);
        stateGather = JSON.parse(JSON.stringify(stateGather));
        let  batteries  = stateGather.batteries;
        let keyGather = Object.keys(this.CHECKOPTION);
        const len = keyGather.length;
        let F1 =0;
        if(batteries&&batteries.length>0){
          F1 = this.calculateBatteryLife(this.K0, this.K1, batteries[0].batteryPercent, this.K2, batteries[0].cycleCount);
            if(batteries&&batteries.length==2){
          F1 += this.calculateBatteryLife(this.K0, this.K1, batteries[1].batteryPercent, this.K2, batteries[1].cycleCount);
        }
        }
        // 预计飞行时间
        this.T = parseInt(this.C *F1 / 60);
        this.load = true;
        for (let i = 0; i < len; i++) {
          let stateItem = {
            [keyGather[i]]: stateGather[keyGather[i]],
            state: true,
            label: keyToZhCn(keyGather[i]),
          };
          await this.sleep(100);
          if (stateItem.label) {
               if(keyGather[i].indexOf('battery')==-1){
                this.detectionMessage(keyGather[i], stateItem, stateGather[keyGather[i]])
               }else{
              let key = keyGather[i]
              if(key=='battery1Percent'){
                this.detectionMessage(keyGather[i], stateItem, batteries[0].batteryPercent)
              }else if(key=='battery1Temperature'){
                this.detectionMessage(keyGather[i], stateItem, batteries[0].temperature)
              }else if(key=='battery2Percent'&&this.CHECKOPTION.battery2Percent){
                this.detectionMessage(keyGather[i], stateItem, batteries[1].batteryPercent)
              }else if(key=='battery2Temperature'&&this.CHECKOPTION.battery2Temperature){
                 this.detectionMessage(keyGather[i], stateItem, batteries[1].temperature)
              }
            }
            
          };
        }
        if (this.endSiteID) {
          this.through = false;
          await this.sleep(100);
          if (this.endHiveInfo) {
            if (this.endHiveInfo.flowState === '空闲' || this.endHiveInfo.flowState === '维护中') {
              this.through = true;
            }
          }
          this.endHiveChecked = true;
        }

        this.$message({ type: this.num == 0 ? 'success' : 'warning', message: `起飞前检测已完成${this.num == 0 ? '' : '共有:' + this.num + '项未通过'}` });
        resolve(this.hasErrMessage);
      });
    },

    /* 计算单块电池续航时间 */
    calculateBatteryLife(K0, K1, Bc, K2, Cy = 30) {
      return K0 + K1 * Bc + K2 * Cy;
    },

    handelExamine(start) {
      this.$emit('handelExamine', start);
    },

    /* 检测是否出现某些字样 */
    contains(val, rules) {
      return rules.find((e) => {
        if (val.indexOf(e) !== -1) {
          return true;
        }
      });
    },
    /* 保留小数 */
    toFilters(v, n) {
      if (!v) return 0;
      const str = parseFloat(v).toFixed(n);
      return `${str}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.examine-bar-wrap {
  width: 670px;
  height: 800px;
  position: relative;
  background: #00000099;
  .title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    &.warning {
      background: #ffc000;
    }
    &.success {
      background: #{$panGreen};
    }
    &.error {
      background: #{$error};
    }
  }
  .announcement-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    .pic-icon {
      width: 40px;
      height: 40px;
    }
    .announcement {
      display: inline-block;
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
  }

  .check-area-wrap {
    width: 100%;
    height: 450px;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: auto;

    .target {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      border-bottom: 1px solid #eee;
      .el-col:first-child {
        text-align: center;
      }
      .check-target {
        li {
          height: 30px;
          line-height: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 10px;
          .msg {
            color: #fff;
            font-size: 16px;
            span {
              display: inline-block;
            }
            .label {
              padding-right: 10px;
              width: 120px;
            }
            .val-box {
              width: 120px;
            }
            .standard {
              font-size: 10px;
              color: #bfbfbf;
            }
            .intitle {
              font-size: 16px;
              color: #fff;
            }
          }
          img {
            width: 20px;
            height: 20px;
            line-height: 30px;
          }
        }
      }
      .intitle {
        li {
          .msg {
            span {
              font-weight: 600;
            }
            > .standard {
              font-size: 16px;
              color: #fff;
            }
          }
          img {
            padding-right: 20px;
            width: 20px;
            height: 20px;
            line-height: 30px;
          }
        }
      }
    }
    .target:last-child {
      border: none;
    }
  }
  .tab-title-bar {
    height: 60px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    .target {
      width: 100%;
      height: 100%;
      .icon {
        img {
          height: 50px;
          display: none;
        }
      }
    }
  }
  .check-page-footer {
    width: 100%;
    height: 160px;
    position: absolute;
    bottom: 0px;
    .footer-message {
      width: 100%;
      height: 80px;
      line-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #eee;
      color: #fff;
      font-size: 26px;
    }
    .but-group {
      padding: 0 20px;
      .btn {
        float: left;
      }
      .cancel {
        float: right;
      }
    }
  }
  .message-gather {
    height: 360px;
    overflow: auto;
    padding: 0px;
    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
      transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-fade-enter,
    .slide-fade-leave-to .slide-fade-leave-active {
      transform: translateX(10px);
      opacity: 0;
    }
    li.message-item {
      border-bottom: 1px solid #fff;
      height: 30px;
      line-height: 30px;
      padding: 0 30px;
      margin: 20px;
      color: #fff;
      span {
        display: inline-block;
      }
      .msg-label {
        width: 200px;
      }
      .err-msg {
        width: 500px;
      }
      .btn {
        border: 1px solid #fff;
        margin-right: 10px;
        float: right;
      }
    }
    li.error-item {
      border-color: #f40;
      background: #eee;
      font-weight: 600;
      color: #f40;
    }
  }
  .statement-ignition-wrap {
    height: 116px;
    padding: 20px;
    color: #fff;
    position: relative;
    .statement {
      float: left;
      color: #f40;
      font-weight: 600;
    }
    .announcement-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .err-count {
        padding: 6px;
      }
    }
    .ignition-wrap {
      width: 132px;
      height: 132px;
      float: right;
      .ignition {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        .btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
          margin-top: 34px;
        }
      }
    }
  }
}
</style>


<template>
  <div class="check-list-wrap">
    <strong class="log-title-bar">站点日志</strong>
    <ul class="check-list">
      <li class="item" v-for="(item, index) in checkList" :key="index">
        <span class="item-name">{{item.event}}</span>
        <span class="date">{{item.date}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CheckList',
  props: {
    checkList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
$menuBg: #2d3035;
$menuHover: #22252a;
$menuText: #bfcbd9;
.check-list-wrap {
  font-size: 12px;
  position: relative;
  .log-title-bar {
    width: 100%;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    padding-left: 14px;
    position: absolute;
    top: 0;
    background: #{$menuBg};
    color: #{$menuText};
  }
  .check-list {
    width: 300px;
    height: 200px;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 30px;
    margin: 0px;
    overflow: hidden;
    overflow-y: scroll;
    .item {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0 4px;
      margin: 4px;
      background-color: #313131aa;
      color: #76ff03;
      .item-name {
        float: left;
      }
      .date {
        float: right;
      }
    }
  }
}
</style>

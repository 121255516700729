import request from "@/utils/request";
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

//获取AI算法类型
export function getAIType() {
  return request({
    url: `${server}/dataCenter/AI/V2/getAIType`,
    method: 'GET',
  })
}

//请求实时的ai视频流
export function getAIVideoStream(data) {
  return request({
    url: `${server}/dataCenter/AI/V2/aiVideoStream`,
    method: 'POST',
    data
  })
}

export function getCurrentDiscernAithmetic(missionBatch) {
  return request({
    url: `${server}/dataCenter/AI/V2/taskExist?missionBatch=${missionBatch}`,
    method: 'GET',
  })
}

export function unEnableAIStream(missionBatch) {
  return request({
    url: `${server}/dataCenter/AI/V2/colseAITask?missionBatch=${missionBatch}`,
    method: 'GET'
  })
}

<template>
    <ul class="signal-strength-wrapper">
        <li class="rssi-n" v-for="item in level" :key="item + random"></li>
        <li class="rssi-n grey" v-for="item in levelLeft" :key="item + random+'grey'"></li>
    </ul>
</template>

<script>
export default {
    name: 'RSSI',
    props: {
        level: {
            type: Number,
            default: 0
        },
    },

    computed: {
        levelLeft() {
            return 5 - this.level
        },
        random() {
            return Math.random() * 1000
        }
    }
}
</script>

<style lang="scss" scoped>
.signal-strength-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0;
    margin: 0;
    $_h: 12px;

    .rssi-n {
        width: 3px;
        border-radius: 10px;
        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                height: $_h - 2 * (5 - $i);
                background: #{$panGreen};
                margin: 0 3px;
            }
        }
    }
    .grey {
        background: grey !important;
    }
}
</style>

<template>
  <div class="issue-altitude-wrap">
    <ul class="disk">
      <!-- <li class="up" @click="debounce(setValue,2000,'up')"><i class="iconfont icon-triangle-top"></i></li>
      <li class="down" @click="debounce(setValue,2000,'down')"><i class="iconfont icon-triangle-bottom"></i></li> -->

      <li v-for="item in actions" :class="item.type" :key="'action_' + item.type">
        <button :disabled="item.disabled ? true : false" class="specital-btn" @click="setValue(item)">
          <i class="iconfont icon-triangle-top" v-if="item.type === 'up'"></i>
          <i class="iconfont icon-triangle-bottom" v-else></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'IssueAltitude',
  data() {
    return {
      actions: [
        {
          type: 'up',
          disabled: false
        },
        {
          type: 'down',
          disabled: false
        }
      ]
    };
  },
  methods: {
    deBounce2Seconds(fn,time=2000) {
      setTimeout(() => {
        fn();
      }, time);
    },
    setValue(item) {
      item.disabled = true;
      this.deBounce2Seconds(() => {
        item.disabled = false;
      });
      this.$emit('setValue', item.type);
    }
  }
};
</script>

<style lang="scss" scoped>
.specital-btn {
  width: 30px;
  height: 30px;
  left: -5px;
  top: -5px;
  position: relative;
  border: 0;
  background-color: transparent;
  >i{
   font-size: 30px;
  }
  &[disabled]{
     cursor: not-allowed;

    }
}
.issue-altitude-wrap {
  width: 100px;
  height: 100px;
  padding-bottom: 10px;
  .disk {
    width: 30px;
    height: 100px;
    background-color: #{$navBarBg};
    box-shadow: 0 2px 1.2px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #666;
    margin: 0 auto;
    li {
      position: absolute;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      .iconfont:active {
        color: #eee;
        transform: scale(0.8);
      }
      .iconfont {
        color: #666;
        text-shadow: 1px 1px 1px #f40;
      }
    }
    .up {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .down {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>

<template>
    <div class="cell-wrap">
        <div id="liquidFill" :style="{ width: size, height: size }" ref="liquidFill"></div>
    </div>
</template>

<script>
import echartsLiquidfill from 'echarts-liquidfill'
let BatFontSize=30;
export default {
    name: 'Battery',
    props: {
        cellData: {
            required: true,
            type: Number,
            default() {
                return 0
            },
        },
        size:{
            type: String,
            default() {
                return '200px'
            },
        }
    },
    data() {
        return {
            counter: 1,
            myChart: {},
        }
    },
    mounted() {
        this.setEchart();
    },
    computed: {
        origin() {
            return this.cellData ? this.cellData : 0 //无人机暂无数据时
        },
        option() {
            let that = this
            let obj = {
                title: {
                    bottom: '4%',
                    left: '35%',
                    text: '电池电量',
                    textStyle: {
                        color: '#333',
                        padding: '2',
                        fontSize: '12',
                        borderRadius: 2,
                        padding: 2,
                        textShadowBlur: 2,
                        textShadowOffsetX: 1,
                        textShadowOffsetY: 1,
                        textShadowColor: '#EEE',
                    },
                },
                series: [{
                    type: 'liquidFill',
                    radius:'75%',
                    data: [this.origin / 100],
                    label: {
                        normal: {
                            color: '#fff',
                            insideColor: 'transparent',
                            textStyle: {
                                fontSize:BatFontSize,
                                fontWeight: 'bold',
                            }
                        }
                    },
                    color: [{
                        type: 'linear',
                        x: 0,
                        y: 1,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 1,
                            color: ['#6a7feb'], // 0% 处的颜色
                        }, {
                            offset: 0,
                            color: ['#27e5f1'], // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }],
                    outline: {
                        show: true,
                        borderDistance: 5,
                        itemStyle: {
                            borderColor: 'rgba(67,209,100,1)',
                            borderWidth: 0
                        }
                    }
                }]
            }
            return obj
        },
    },
    methods: {
        setEchart() {
            let dom = this.$refs.liquidFill
            this.myChart = this.$echarts.init(dom)
            try {
                this.myChart.setOption(this.option)
            } catch {
                console.error('error')
            }
        },
    },
    watch: {
        origin(newValue, oldValue) {
            this.myChart.setOption(this.option, true)
        },
        size(v){
          this.$nextTick(()=>{
            BatFontSize=parseInt(v.split('px')[0]*30/200)
            this.myChart.resize()
          })
        }
    },
}
</script>

<style lang="scss" scoped>
#liquidFill {
    width: 2rem;
    height: 2rem;
}
</style>
<template>
  <div class="disk-wrap">
    <ul class="disk">
      <li v-for="(item, index) in diskGroup" :class="item.className" :key="index">
        <button :disabled="item.disabled ? true : false" class="specital-btn" @click="setValue(item)">
          <i class="iconfont" :class="item.icon"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { UAV_ACTION } from '@/enum/UAV_ACTION';
export default {
  name: 'Disk',
  props: ['marker'],
  data() {
    return {
      diskGroup: [
        {
          className: 'up',
          icon: 'icon-triangle-top',
          type: 1,
          key: 'lat',
          val: UAV_ACTION.POS.forward,
          disabled: false
        },
        {
          className: 'right',
          icon: 'icon-triangle-right',
          type: 2,
          key: 'lng',
          val: UAV_ACTION.POS.right,
          disabled: false
        },
        {
          className: 'down',
          icon: 'icon-triangle-bottom',
          type: 3,
          key: 'lat',
          val: UAV_ACTION.POS.backward,
          disabled: false
        },
        {
          className: 'left',
          icon: 'icon-triangle-left',
          type: 4,
          key: 'lng',
          val: UAV_ACTION.POS.left,
          disabled: false
        }
      ]
    };
  },
  methods: {
    deBounce2Seconds(fn,time=2000) {
      setTimeout(() => {
        fn();
      }, time);
    },
    setValue(item) {
      item.disabled = true;
      this.deBounce2Seconds(() => {
        item.disabled = false;
      });
      this.$emit('setValue', item.val);
    }
  }
};
</script>

<style lang="scss" scoped>
.specital-btn {
  width: 30px;
  height: 30px;
  left: -5px;
  top: -5px;
  position: relative;
  border: 0;
  background-color: transparent;
  &[disabled] {
    cursor: not-allowed;
  }
}
.disk-wrap {
  width: 100px;
  height: 100px;
    input{
      color: black;
    }
  .disk {
    width: 100px;
    height: 100px;
    background-color: #{$navBarBg};
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #666;
    li {
      position: absolute;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      .iconfont {
        color: #666;
        text-shadow: 1px 1px 1px #f40;
        font-size: 30px;
      }
    }
    li:active .iconfont {
      color: #eee;
    }
    .center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: #555;
      border: 1px solid #666;
      box-shadow: 0 2px 12px 0 #f40;
    }
    .up {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .right {
      top: 50%;
      right: 0;
      padding: none;
      transform: translate(0, -50%);
    }
    .down {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .left {
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }
}
</style>

<template>
  <div class="active-mission-wrap">
    <transition name="el-zoom-in-bottom">
      <div v-show="flag < 1" class="transition-box">
        <span class="interval">一小时内暂无执飞任务</span>
      </div>
    </transition>
    <transition name="el-zoom-in-bottom">
      <div v-show="flag > 0 && flag <= 1 && interval" class="transition-box">
        <span class="interval">下一架次将于</span>
        <FlipClock :interval="interval" />
        <span class="interval active-time">分钟后执飞</span>
        <div class="mission-detail">
          <strong class="title">待执飞任务详情</strong>
          <el-row>
            <span>任务名称：</span>
            <span>{{ currPlan.missionName }}</span>
          </el-row>
          <el-row>
            <span>执行时间：</span>
            <span>{{ currPlan.excuteTime && $moment(currPlan.excuteTime * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
          </el-row>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FlipClock from '@/components/TimerBar';
export default {
  name: 'ActiveMission',
  props: {
    currPlan: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      timer: null,
      interval: '',
      flag: 0,
    };
  },

  methods: {
    monitorActiveMission(endDate) {
      this.timer = setInterval(() => {
        const seconds = (endDate - new Date().getTime()) / 1000;
        if (seconds < 0) {
          this.$store.commit('content/INIT_TIMEDTASK', true);
          return clearInterval(this.timer);
        }
        this.flag = seconds / 3600; //一小时内的才显示
        this.interval = this.formatSeconds(seconds);
      }, 1000);
    },
    /* 时间转换 */
    formatSeconds(time) {
      let min = Math.floor(time % 3600);
      let val = this.formatBit(Math.floor(time / 3600)) + ':' + this.formatBit(Math.floor(min / 60)) + ':' + this.formatBit(time % 60);
      return val;
    },
    /* 补0 */
    formatBit(val) {
      val = +val;
      return val > 9 ? val : `0${val}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    currPlan(newVal, oldVal) {
      if (!newVal.excuteTime) {
        return;
      }
      this.monitorActiveMission(newVal.excuteTime * 1000);
    },
  },
  components: { FlipClock },
};
</script>

<style lang="scss">
.active-mission-wrap {
  width: 100%;
  color: #fff;
  .interval {
    display: inline-block;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  .transition-box {
    padding: 10px;
    .mission-detail {
      font-size: 14px;
      .el-row {
        height: 30px;
        line-height: 30px;
        span:nth-child(1) {
          float: left;
        }
        span:nth-child(2) {
          float: right;
        }
      }
    }
  }
  .test-clock-container {
    font: normal 12px 'Helvetica Neue', Helvetica, sans-serif;
    user-select: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
    background: radial-gradient(ellipse at center, #969696 0%, #595959 100%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

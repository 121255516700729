

import createEnum from "./createEnum"

export const KEYBOARD_UAV_VALID = createEnum({ // 无人机空间位置
    A: [65, 'A'],
    // B: [66, 'B'],
    // C: [67, 'C'],
    D: [68, 'D'],
    E: [69, 'E'],
    //  F: [70, 'F'],
    //   G: [71, 'G'],
    //  H: [72, 'H'],
    //  I: [73, 'I'],
    //  J: [74, 'J'],
    //  K: [75, 'K'],
    L: [76, 'L'],
    //   M: [77, 'M'],
    //  N: [78, 'N'],
    //  O: [79, 'O'],
    P: [80, 'P'],
    Q: [81, 'Q'],
    R: [82, 'R'],
    S: [83, 'S'],
    //  T: [84, 'T'],
    //  U: [85, 'U'],
    // V: [86, 'V'],
    W: [87, 'W'],
    //  X: [88, 'X'],
    // Y: [89, 'Y'],
    // Z: [90, 'Z'],
    F1: [112, 'F1'],
    // F2: [113, 'F2'],
    // F3: [114, 'F3'],
    // F4: [115, 'F4'],
    // F5: [116, 'F5'],
    // F6: [117, 'F6'],
    // F7: [118, 'F7'],
    // F8: [119, 'F8'],
    // F9: [120, 'F9'],
    // F10: [121, 'F10'],
    // F11: [122, 'F11'],
    // F12: [123, 'F12'],
    HOME: [36, 'HOME'],
    MINUS: [109, 'MINUS'],
    INCREASE: [107, 'INCREASE'],
    PAGEUP: [33, 'PAGEUP'],
    PAGEDOWN: [34, 'PAGEDOWN'],
    ARROWLEFT: [37, 'ARROWLEFT'],
    ARROWRIGHT: [39, 'ARROWRIGHT'],
    ARROWTUP: [38, 'ARROWTOP'],
    ARROWDOWN: [40, 'ARROWBOTTOM'],
    BackSpace: [8, 'BackSpace'],
    TAB: [9, 'TAB'],
    Spacebar: [32, 'Spacebar'],

}, '未知')







<template>
  <div class="handle-content-page-header-wrap">
    <div class="top-message-bar">
      <div class="logo-box-wrap">
        <img :src="logoSocure" alt="">
        <h1>{{serverName}}</h1>
      </div>
      <div class="weather-wrap" style="padding-left:0" v-if="hiveInformation.isRaining || hiveInformation.windSpeed">
        <div class="weather-wrap" v-if="startSite&&(startSite.siteMode==1||startSite.siteMode==4)">
          <div class="weather">
            <span>天气情况:</span>
            <i style="font-size:20px" :class="hiveInformation.isRaining == '有雨' ? 'el-icon-heavy-rain': 'el-icon-sunny' " :size="size" :color="'#FFF'"></i>
            <span>{{hiveInformation.isRaining}}</span>
          </div>
          <div class="weather">
            <span>风速:</span>
            <span>{{hiveInformation.windSpeed}}</span>
          </div>
        </div>

      </div>
      <div class="shift-bar">
        <div class="shift-bar-wrap">
          <span v-if="startSite&&(startSite.siteMode==1||startSite.siteMode==4)" class="site">起点站:{{startSite.siteName}}</span>
          <div style="width:210px">
            <ShiftBar :waypointIndex="waypointIndex>waypointCount?waypointCount:waypointIndex" :waypointCount="waypointCount" v-if="missionBatch&&waypointCount>1" />
          </div>
          <span @click="goEndSite" v-if="endSite&&(endSite.siteMode==1||endSite.siteMode==4)" class="site end-site-link">终点站:{{endSite.siteName}}</span>
        </div>
      </div>
    </div>
    <!-- <div class="icon electric-panel-wrapper">
      <span>电量:</span>
      <Battery :quantity="quantity" />
    </div> -->
    <!-- <div class="icon rssi-level-wrapper">
      <span>GPS:</span>
      <RSSI :level="5" />
    </div> -->
    <i class="el-icon-close" @click="$router.go(-1)"></i>
  </div>
</template>

<script>
import ShiftBar from "./ShiftBar.vue"
import RSSI from "@/components/RSSI"
import Battery from "@/components/Battery"

export default {
  name: 'PageHeader',
  components: { ShiftBar, Battery, RSSI },
  props: ['hiveInformation', 'quantity', 'missionBatch', 'waypointCount', 'waypointIndex', 'level', 'startSite', 'endSite'],
  data() {
    return {
      size: 20,
      logoSocure: require('@/assets/images/logo/logo.png'),
     serverName: '祺云™无人机集群调度系统'
    }
  },
  created(){
    console.log(this.$store.state)
    if(this.$store.state.user.logo){
        this.logoSocure= this.$store.state.user.logo
    }
    if(this.$store.state.user.serverName){
        this.serverName= this.$store.state.user.serverName
    }
  },
  methods:{
  goEndSite(){
    
    this.$router.push({ name: 'handleCenter', query: { siteID: this.endSite.siteID } })
    window.reload()
  }
  }


}
</script>

<style lang="scss" scoped>
.end-site-link{
  cursor: pointer;
  &:hover{
    color: #409eff;
  }
}
.handle-content-page-header-wrap {
  height: 60px;
  width: 100%;
  min-width: 1270px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  background: #{$navBarBg};
  color: #fff;
  font-family: 'Alibaba-PuHuiTi-Medium';
  .top-message-bar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    .logo-box-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        height: 30px;
      }
      h1 {
        color: #409eff;
        padding: 0;
        margin: 0;
        font-size: 18px;
        padding-left: 10px;
        font-family: 'Alibaba-PuHuiTi-Medium';
      }
    }
    .weather-wrap {
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      > div.weather {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        span {
          margin: 0 6px;
        }
      }
    }
    .shift-bar {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .shift-bar-wrap {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        span.site {
          font-size: 14px;
          margin: 0 10px;
        }
      }
    }
    .meteorological-info-wrap {
      height: 100%;
      font-size: 14px;
      .weather {
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .icon {
    padding: 0 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      display: block;
      width: 30px;
      line-height: 18px;
      font-size: 12px;
    }
  }
  .el-icon-close {
    padding: 6px;
    margin-left: 10px;
    font-size: 16px;
    background: #{$menuHover};
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background: #{$error};
    }
  }
}
</style>

<template>
  <li class="inspection-item">
    <span><i :class="state | statusIcon"></i>{{state | parseStatus}}</span>
    <span class="item-title">{{title}}</span>
  </li>
</template>

<script>
export default {
  name: 'InspectionItem',
  props: ['state', 'title', 'icon'],
  filters: {
    statusIcon(status) {
      const statusMap = {
        1: 'el-icon-remove-outline red',
        2: 'el-icon-loading blue',
        3: 'el-icon-circle-check green'
      }
      return statusMap[status]
    },
    parseStatus(status) {
      const statusMap = {
        0: '等待',
        1: '过程中',
        2: '完成'
      }
      return statusMap[status]
    },
  }
}
</script>

<style lang="scss" scoped>
.inspection-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  color: #eee;
  background: #f40;
  > span {
    padding: 5px;
  }
}
</style>
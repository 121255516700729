<template>
  <el-form class="automatic-mission-wrap" label-position="top" label-width="80px" size="small" :rules="rules" :model="missionInfo" ref="ruleForm">
    <el-form-item label="任务类型">
      <el-select v-model="typeSelect" @change="typeSelectChangeHandler">
        <el-option :value="1" label="路点任务" key="typeSelect_1"></el-option>
        <el-option :value="2" label="二维扫图" key="typeSelect_2"></el-option>
      </el-select>
      <el-select v-if="type == 2" v-model="missionInfo.index" style="width: 100%" @change="changeIndexHandler">
        <el-option v-for="(item, index) in children" :value="index" :label="'子航线' + ++index" :key="'son_' + index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="任务名称" prop="Id">
      <el-select :disabled="disabled" clearable filterable v-model="missionInfo.Id" style="width: 100%" @change="changeHandler">
        <el-option v-for="mission in missionListFilter" :value="mission.missionID" :label="mission.missionName" :key="mission.missionID"></el-option>
      </el-select>
      <el-select v-if="type == 2" v-model="missionInfo.index" style="width: 100%" @change="changeIndexHandler">
        <el-option v-for="(item, index) in children" :value="index" :label="'子航线' + ++index" :key="'son_' + index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="siteSFMode != 1" label="返航方式" prop="finishAction">
      <el-select :disabled="disabled" clearable v-model="missionInfo.finishAction" style="width: 100%" @change="handleChangeFinishAction">
        <el-option v-for="action in activeMenu" :value="action.value" :label="action.label" :key="action.value" :disabled="action.disabled"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="指定降落站点" v-if="missionInfo.finishAction === 4" prop="endSiteID">
      <el-select v-model="missionInfo.endSiteID" style="width: 100%">
        <el-option v-for="site in siteList" :value="site.siteID" :label="site.siteName" :key="site.siteID"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="全程变焦" v-if="missionInfo.endSiteID">
      <el-checkbox size="mini" v-for="zoom in zooms" :key="zoom.label" v-model="zoom.checked" @change="handleChangeZoom(zoom)">{{ zoom.label }}</el-checkbox>
    </el-form-item>
    <el-form-item v-if="missionInfo.endSiteID">
      <el-form :inline="true">
        <el-form-item label="全程录像">
          <el-switch v-model="missionInfo.isVideo" style="margin-left: 124px"></el-switch>
        </el-form-item>
      </el-form>
    </el-form-item>
    <el-form-item style="margin-top: 20px">
      <el-button :disabled="disabled" type="primary" style="width: 100%" @click="submitForm()">立即下发</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'AutomaticMission',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    missionList: {
      type: Array,
      default: [],
    },
    /*TODO 后续需要切换该接口*/
    siteList: {
      type: Array,
      default: [],
    },
    children: {
      type: Array,
      default: [],
    },
    siteSFMode: {
      type: Number,
      default: 1,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      typeSelect: 1,
      missionInfo: {
        Id: '',
        index: 0,
        finishAction: 1,
        flightWay: 1,
        endSiteID: '',
        zoomDefault: 0,
        isVideo: false,
      },
      zooms: [
        {
          label: 'x4',
          value: 4,
          checked: false,
        },
        {
          label: 'x6',
          value: 6,
          checked: false,
        },
        {
          label: 'x8',
          value: 8,
          checked: false,
        },
        {
          label: 'x10',
          value: 10,
          checked: false,
        },
      ],
      activeMenu: [
        {
          value: 1,
          label: '返回起始站',
        },
        // {
        //   value: 2,
        //   label: '悬停',
        // },
        // {
        //   value: 3,
        //   label: '指定降落点',
        // },
        {
          value: 4,
          label: '返回其他站',
          disabled: true,
        },
      ],
      rules: {
        Id: [{ required: true, message: '请选择任务', trigger: 'change' }],
        finishAction: [{ required: true, message: '请选择返航方式', trigger: 'change' }],
        endSiteID: [{ required: true, message: '请选择终点站点', trigger: 'change' }],
      },
    };
  },

  created() {
    this.activeMenu[1].disabled = this.siteSFMode === 1 ? true : false;
  },

  watch: {
    'missionInfo.endSiteID'(newVal, oldVal) {
      this.handleChangeEndSiteID(newVal);
    },
    'missionInfo.finishAction'(newVal, oldVal) {
      if (newVal != 4) {
        this.missionInfo.endSiteID = '';
      }
    },

    siteSFMode(newVal) {
      this.activeMenu[1].disabled = newVal === 1 ? true : false;
    },
  },
  computed: {
    missionListFilter() {
      return this.missionList.filter((mission) => mission.missionType === this.typeSelect);
    },
  },

  methods: {
    typeSelectChangeHandler(v) {
      this.changeHandler(null);
      this.typeSelect = v;
      this.missionInfo.Id = '';
    },
    handleChangeFinishAction(action) {
      if (action == 1) {
        // 返回起始站
        this.missionInfo.endSiteID = '';
        this.$emit('noEndSite', true);
      } else if (action == 4) {
        // 返回其他站
        this.$emit('noEndSite', false);
      }
    },

    changeIndexHandler(index) {
      this.$emit('index', index);
    },

    /**
     * @description: 切换终点站点
     * @param {*} endSiteID
     * @return {*}
     */

    handleChangeEndSiteID(endSiteID) {
      this.$emit('handleChangeEndSiteID', endSiteID);
    },
    handleChangeZoom(zoom) {
      this.zooms.forEach((item) => (item.checked = false));
      if (this.missionInfo.zoomDefault === zoom.value) {
        return (this.missionInfo.zoomDefault = 0);
      }
      zoom.checked = true;
      this.missionInfo.zoomDefault = zoom.value;
    },
    submitForm(formName = 'ruleForm') {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('submitForm', this.missionInfo);
        } else {
          this.$message({ type: 'warning', message: '请添加完整任务参数' });
        }
      });
    },

    changeHandler(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-mission-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  ::v-deep .el-form-item {
    .el-form-item__content {
      .el-select {
        width: 100%;
      }
      .el-checkbox {
        margin-right: 18px;
      }
    }

    &.el-form-item--small {
      margin-bottom: 10px;
    }
  }
}
</style>

<template>
  <div class="uav-information-wrapper">
    <s-alert :type="droneInfomation.subState | uavClassBySubState" style="margin-bottom: 10px">
      {{ droneInfomation.subState | stateInterpret }}
    </s-alert>
    <div class="uav-pic-model">
      <div>
        <img :src="modeImg" alt="" />
      </div>
      <div class="card">
        <!-- <div class="title">基本信息</div> -->
        <div class="item-one">
          <div>无人机型号：{{ this.droneInfomation.model ? this.droneInfomation.model : info.model }}</div>
        </div>
        <div class="item-one">
          <div>无人机编号：{{ this.droneInfomation.UAVID ? this.droneInfomation.UAVID : info.UAVID }}</div>
        </div>
        <div class="item-one">
          <div>镜头型号：{{ this.droneInfomation.cameraType }}</div>
        </div>
        <div class="item-one">
          <div>更新时间：{{ timeStamp }}</div>
        </div>
      </div>
    </div>

    <div class="message-box-wrapper">
      <div class="card card-green-border">
        <div class="title">无人机报文信息</div>
        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.lnglat" />
              </div>
              <div class="info">
                <div>{{ toFilters(droneInfomation.longitudeWGS, 6) }}</div>
                <div>经度(WGS84)</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.lnglat" />
              </div>
              <div class="info">
                <div>{{ toFilters(droneInfomation.latitudeWGS, 6) }}</div>
                <div>纬度(WGS84)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.ht" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.altitude }}m</div>
                <div>高度(相对地面)</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.direction" />
              </div>
              <div class="info">
                <div>{{ azimuthTrans }}</div>
                <div>航偏角</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.hspeed" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.horizontalSpeed }} m/s</div>
                <div>水平速度</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.vspeed" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.verticalSpeed }} m/s</div>
                <div>垂直速度(爬升率)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.charge" />
              </div>
              <div class="info">
                <div>{{ currentBatteryPercent }}</div>
                <div>电量</div>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.temp" />
              </div>
              <div class="info">
                <div>{{ currentBatteryTemperature }}</div>
                <div>温度</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.gps" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.GPSCount }}</div>
                <div>GPS星数</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.level" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.GPSLevel }}</div>
                <div>GPS等级</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 
        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.rtk" />
              </div>
              <div class="info">
                <div>不可用 </div>
                <div>RTK状态 </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.lte" />
              </div>
              <div class="info">
                <div>不可用 </div>
                <div>LTE状态 </div>
              </div>
            </div>
          </div>
        </div>
         -->

        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.upsignal" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.upLink }} %</div>
                <div>上行信号</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.downsignal" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.downLink }} %</div>
                <div>下行信号</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img class="spec" :src="infoImg.gimbalpitch" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.gimbalPitch }}</div>
                <div>云台俯仰角</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img class="spec" :src="infoImg.gimbalyaw" />
              </div>
              <div class="info">
                <div>{{ gimbalYawTrans }}</div>
                <div>云台航偏角</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.camera" />
              </div>
              <div class="info">
                <div>{{ currentGimbalType }}</div>
                <div>云台镜头</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.big" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.zoom }}</div>
                <div>放大倍率</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.distance" />
              </div>
              <div class="info">
                <div>{{ distanceToStart }}</div>
                <div>距起点距离</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.distanceend" />
              </div>
              <div class="info">
                <div>{{ distanceToEnd }}</div>
                <div>距离终点距离</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.tianshu" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.isSCOnline ? '在线' : '离线' }}</div>
                <div>天枢状态</div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-info">
              <div class="img">
                <img :src="infoImg.videoShot" />
              </div>
              <div class="info">
                <div>{{ droneInfomation.isRecording ? '是' : '否' }}</div>
                <div>影像录制</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const M210V2 = require('@/assets/images/device/uav_M210V2.png');
const M300 = require('@/assets/images/device/uav_M300.png');
const M30 = require('@/assets/images/device/uav_M30.png');
const MAVIC2 = require('@/assets/images/device/uav_MAVIC2.png');
const P4P = require('@/assets/images/device/uav_P4P.png');
const MAVIC2Pro = require('@/assets/images/device/uav_MAVIC2_Pro.png');
const DH_X1550 = require('@/assets/images/device/DH_X1550.png');

export default {
  name: 'UavInfomation',
  props: {
    droneInfomation: {
      type: Object,
    },
    info: {
      type: Object,
    },
    distanceToEnd: {
      type: String,
      default: '未知',
    },
    distanceToStart: {
      type: String,
      default: '未知',
    },
  },
  data() {
    return {
      substate: '待命',
      alertGenre: 'success',

      infoImg: {
        charge: require('@/assets/images/attitude/charge.png'),
        downsignal: require('@/assets/images/attitude/downsignal.png'),
        gps: require('@/assets/images/attitude/gps.png'),
        hspeed: require('@/assets/images/attitude/hspeed.png'),
        vspeed: require('@/assets/images/attitude/vspeed.png'),
        upsignal: require('@/assets/images/attitude/upsignal.png'),
        ht: require('@/assets/images/attitude/ht.png'),
        direction: require('@/assets/images/attitude/direction.png'),
        lnglat: require('@/assets/images/attitude/lnglat.png'),
        temp: require('@/assets/images/attitude/temp.png'),
        time: require('@/assets/images/attitude/time.png'),
        level: require('@/assets/images/attitude/level.png'),
        substate: require('@/assets/images/attitude/substate.png'),
        distance: require('@/assets/images/attitude/distance.png'),
        camera: require('@/assets/images/attitude/camera.png'),
        distanceend: require('@/assets/images/attitude/distanceend.png'),
        gimbalroll: require('@/assets/images/attitude/gimbalroll.png'),
        gimbalpitch: require('@/assets/images/attitude/gimbalpitch.png'),
        zoom: require('@/assets/images/attitude/zoom.png'),
        big: require('@/assets/images/attitude/big.png'),
        tianshu: require('@/assets/images/attitude/tianshu.png'),
        videoShot: require('@/assets/images/attitude/videoShot.png'),
        gimbalyaw: require('@/assets/images/attitude/gimbalyaw.png'),
        rtk: require('@/assets/images/attitude/rtk.png'),
        lte: require('@/assets/images/attitude/lte.png'),
      },
    };
  },

  computed: {
    modeImg() {
      const model = this.info?.model ? this.info.model : '';
      return this.uavImage(model);
    },
    timeStamp() {
      if (this.droneInfomation?.timestamp) {
        if (typeof this.droneInfomation.timestamp === 'string') {
          return this.droneInfomation.timestamp;
        }
        if (typeof this.droneInfomation.timestamp === 'number') {
          if (this.droneInfomation.timestamp / Math.pow(10, 10) > 1) {
            // 毫秒
            return this.$moment(this.droneInfomation.timestamp).format('YYYY-MM-DD HH:mm:ss');
          } else {
            // 秒
            return this.$moment(this.droneInfomation.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
          }
        }
      }
      return '';
    },

    currentGimbalType() {
      let display = this.droneInfomation.displayMode;
      let type;
      if (display == 1) {
        type = '可见光';
      } else if (display == 2) {
        type = '红外';
      } else if (display == 3) {
        type = '画中画';
      } else if (display == 4) {
        type = '混合';
      } else if (display == 5) {
        type = '广角';
      } else {
        type = '未知';
      }
      return type;
    },

    azimuthTrans() {
      let i = this.droneInfomation.azimuth;
      return this.translateDirection(i);
    },

    gimbalYawTrans() {
      let i = this.droneInfomation.gimbalYaw;
      return i;
      //  return this.translateDirection(i)
    },

    currentBatteryPercent() {
      let val = '未知';
      let batteries = this.droneInfomation.batteries;
      let total = 0;
      if (batteries && batteries.length > 0) {
        for (let battery of batteries) {
          total += battery.batteryPercent;
        }
        val = parseInt(total / batteries.length) + '%';
      }
      return val;
    },
    currentBatteryTemperature() {
      let val = '未知';
      let batteries = this.droneInfomation.batteries;
      let total = 0;
      if (batteries && batteries.length > 0) {
        for (let battery of batteries) {
          total += battery.temperature;
        }
        val = parseInt(total / batteries.length) + '℃';
      }
      return val;
    },
  },
  methods: {
    uavImage(model) {
      const models = {
        '经纬 M210 V2': M210V2,
        '经纬 M300 RTK': M300,
        '经纬 M30': M30,
        '御 Mavic 2 行业版': MAVIC2,
        '精灵 Phantom 4 Pro': P4P,
        '御 2 行业进阶版': MAVIC2Pro,
        '大华 X1550': DH_X1550,
      };
      return models[model] || M300;
    },
    /* 计算电池平均温度 */
    batteryStatus(p1 = 0, p2 = 0) {
      let value = '';
      value = (p1 + p2) / 2;
      return this.toFilters(value, 1);
    },
    toFilters(v, n) {
      if (!v) return 0;
      return +v.toFixed(n);
    },

    translateDirection(i) {
      let text = '';
      i = i + 180;
      i = parseInt(i);
      if (i < 90) {
        text = `南偏西${i}`;
      } else if (i < 180) {
        text = `北偏西${180 - i}`;
      } else if (i < 270) {
        text = `北偏东${360 - i}`;
      } else if (i < 360) {
        text = `南偏东${360 - i}`;
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.uav-information-wrapper {
  width: 100%;
  height: 100%;
  background: #{$layoutLeftMenuBg};
  display: flex;
  flex-direction: column;
  .uav-pic-model {
    display: flex;
    width: 100%;
    height: 140px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: #{$layoutLeftMenuBg};
    box-shadow: 0 0px 4px rgba(134, 142, 150, 0.12), 0 0 4px rgba(134, 142, 150, 0.04);
    @include prefix(user-select, none);

    > div:first-child {
      width: 40%;
      height: 100%;
      > img {
        width: 100%;
      }
    }
    > div:last-child {
      width: 60%;
      height: 100%;
    }
  }
  .load-information-wrap {
    width: 100%;
    padding: 10px 0;
    margin: 10px 0 0 0;
    color: rgb(235, 238, 238);
    background: #{$layoutLeftMenuBg};
    border-radius: 4px;
    box-shadow: 0 0px 4px rgba(134, 142, 150, 0.12), 0 0 4px rgba(134, 142, 150, 0.04);
    font-size: 12px;
    tr.table-header {
      color: #fff;
    }
    tbody > tr {
      td:nth-child(2n-1) {
        width: 75px;
        text-align: right;
      }
      td {
        padding: 6px 0;
      }
    }
  }
  .message-box-wrapper {
    flex: 1;
    margin: 10px 0 0 0;
    padding: 0;
    border-radius: 4px;
    // box-shadow: 0 0px 4px rgba(134, 142, 150, 0.12),
    //     0 0 4px rgba(134, 142, 150, 0.04);
    // background: #{$layoutLeftMenuBg};
    color: #fff;
    font-size: 12px;
    font-family: '\5FAE\8F6F\96C5\9ED1', Helvetica, '黑体', Arial, Tahoma;
    /*滚动条样式*/
    scrollbar-color: rgba(255, 255, 0, 0.2) rgba(125, 125, 125, 0.2); /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
    overflow: auto;
  }
  .message-box-wrapper::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .message-box-wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(237, 238, 230, 0.2);
    -webkit-box-shadow: inset 0 0 5px rgba(237, 238, 230, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  .message-box-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(25, 36, 49, 0.2);
    -webkit-box-shadow: inset 0 0 5px rgba(25, 36, 49, 0.2);
    border-radius: 0;
    background: rgba(25, 36, 49, 0.1);
  }
}

.card-green-border {
  border: 1px solid #0b137e;
  border-image: linear-gradient(#24952f, #313131) 20 20;
}

.card {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: white;
  .title {
    color: white;
    text-align: center;
    background: #5d5e60;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .progress {
    padding-left: 15px;
  }
  .one-info {
    text-align: left;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .img {
      width: 26px;
      height: 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      margin-left: 10px;
      line-height: 26px;
      color: white;
    }
  }
  .item-one {
    width: 100%;
    height: 30px;
    display: flex;
    padding: 0 5px;
    > div {
      word-break: break-all;
    }
  }

  .row {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 12px;
    display: flex;
    .item {
      display: flex;
      margin: auto;
      padding: 5px;
      background: #{$cardBg};
      width: 160px;
      float: left;
      border-radius: 2px;
      .img-info {
        display: flex;
        .img {
          width: 32px;
          height: 32px;
          margin: auto;
          margin-right: 5px;
          .spec {
            width: 42px;
            height: 45px;
            margin-top: -5px;
            margin-left: -5px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          font-size: 11px;
          > div {
            text-align: left;
            word-break: keep-all;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .row:nth-last-child(1) {
    margin-bottom: 10px;
  }

  .event-list-wrap {
    .msg-wrap {
      display: flex;
      flex-direction: column;
      padding: 4px;
      overflow: auto;
    }
    .operating {
      height: 150px;
    }
    .event-area {
      height: calc(100vh - 788px);
      overflow: auto;
    }
  }
}
</style>

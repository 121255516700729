<template>
  <div class="hive-model-MK4-wrap">
    <ul id="ul">
      <li>
        <div class="door door-top " :class="{ active: roofState === '打开中' || roofState === '关闭中' }"></div>
        <div class="door door-bottom " :class="{ active: roofState === '打开中' || roofState === '关闭中' }"></div>
      </li>
      <li class="bbottom" :class="{ active: stageState === '上升中' || stageState === '下降中' }">
        <div class="x fance-top" :class="{ active: braceState === '松开中' || braceState === '夹紧中' }"></div>
        <div class="y fance-right" :class="{ active: clawState === '松开中' || clawState === '夹紧中' }"></div>
        <div class="x fance-bottom" :class="{ active: braceState === '松开中' || braceState === '夹紧中' }"></div>
        <div class="y fance-left" :class="{ active: clawState === '松开中' || clawState === '夹紧中' }"></div>
      </li>
      <li><img src="./img-mk4/back.png" /></li>
      <li><img src="./img-mk4/face.png" /></li>
      <li><img src="./img-mk4/left.png" /></li>
      <li><img src="./img-mk4/right.png" /></li>
      <li><img src="./img-mk4/bottom.png" /></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HiveMK4',
  data() {
    return {
      time: 6000,
      openTime: 16000,
    };
  },
  props: {
    roofState: {
      type: String,
      default() {
        return '';
      },
    },
    stageState: {
      type: String,
      default() {
        return '';
      },
    },
    braceState: {
      type: String,
      default() {
        return '';
      },
    },
    clawState: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    roof() {
      var result = false;
      switch (this.roofState) {
        case '打开中':
        case '已打开':
          result = true;
          break;
        case '关闭中':
        case '已关闭':
          result = false;
          break;
        default:
          break;
      }
      return result;
    },
    stage() {
      var result = false;
      switch (this.stageState) {
        case '上升中':
        case '顶部':
          result = true;
          break;
        case '下降中':
        case '底部':
          result = false;
          break;
        default:
          break;
      }
      return result;
    },
    brace() {
      var result = false;
      switch (this.braceState) {
        case '松开中':
        case '松开':
          result = true;
          break;
        case '夹紧中':
        case '夹紧':
          result = false;
          break;
        default:
          break;
      }
      return result;
    },
    /* 卡爪 */
    claw() {
      var result = {};
      switch (this.clawState) {
        case '松开中':
        case '松开':
          result = true;
          break;
        case '夹紧中':
        case '夹紧':
          result = false;
          break;
        default:
          break;
      }
      return JSON.parse(JSON.stringify(result));
    },
  },
  watch: {
    roof(newVal) {
      if (newVal) {
        this.openTDoor();
      } else {
        this.closeDoor();
      }
    },
    stage(newVal) {
      if (newVal) {
        this.upBottom();
      } else {
        this.downBottom();
      }
    },
    /* 横杆 */
    brace(newVal) {
      if (newVal) {
        this.fanceYAcitive();
      } else {
        this.clampYAcitive();
      }
    },
    /* 卡爪 */
    claw(newVal) {
      if (newVal) {
        this.letGo();
      } else {
        this.collectBuckle();
      }
    },
  },
  methods: {
    /* 顶盖打开 */
    acitive() {
      var doorLeft = document.getElementsByClassName('door-top')[0];
      doorLeft.animate(
        [
          {
            top: '0px',
            left: '0px',
            offset: 0,
          },
          {
            top: '-25px',
            left: '0px',
            offset: 1,
          },
        ],
        {
          // duration: 6000,
          duration: this.roofState === '已打开' ? 0 : 6000,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var doorRight = document.getElementsByClassName('door-bottom')[0];
      doorRight.animate(
        [
          {
            bottom: '0px',
            left: '0px',
            offset: 0,
          },
          {
            bottom: '-25px',
            left: '0px',
            offset: 1,
          },
        ],
        {
          // duration: 6000,
          duration: this.roofState === '已打开' ? 0 : 6000,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },

    /* 平台上升 */
    bup() {
      var bottom = document.getElementsByClassName('bbottom')[0];
      bottom.animate(
        [
          {
            transform: 'rotateX(270deg) translateZ(30px)',
            offset: 0,
          },
          {
            transform: 'rotateX(270deg) translateZ(-20px)',
            offset: 1,
          },
        ],
        {
          // duration: 16000,
          duration: this.stageState === '顶部' ? 0 : 16000,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 平台下降 */
    bdown() {
      var bottom = document.getElementsByClassName('bbottom')[0];
      bottom.animate(
        [
          {
            transform: 'rotateX(270deg) translateZ(-20px)',
            offset: 0,
          },
          {
            transform: 'rotateX(270deg) translateZ(30px)',
            offset: 1,
          },
        ],
        {
          // duration: 16000,
          duration: this.stageState === '底部' ? 16000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 打开舱门 */
    openTDoor() {
      var doorLeft = document.getElementsByClassName('door-top')[0];
      doorLeft.animate(
        [
          {
            top: '0px',
            left: '0px',
            offset: 0,
          },
          {
            top: '-25px',
            left: '0px',
            offset: 1,
          },
        ],
        {
          // duration: 6000,
          duration: this.roofState === '已打开' ? 0 : 6000,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var doorRight = document.getElementsByClassName('door-bottom')[0];
      doorRight.animate(
        [
          {
            bottom: '0px',
            left: '0px',
            offset: 0,
          },
          {
            bottom: '-25px',
            left: '0px',
            offset: 1,
          },
        ],
        {
          // duration: 6000,
          duration: this.roofState === '已打开' ? 0 : 6000,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 关闭舱门 */
    closeDoor() {
      var doorLeft = document.getElementsByClassName('door-top')[0];
      doorLeft.animate(
        [
          {
            top: '-25px',
            left: '0px',
            offset: 0,
          },
          {
            top: '0px',
            left: '0px',
            offset: 1,
          },
        ],
        {
          // duration: 6000,
          duration: this.roofState === '关闭中' ? 16000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var doorRight = document.getElementsByClassName('door-bottom')[0];
      doorRight.animate(
        [
          {
            bottom: '-25px',
            left: '0px',
            offset: 0,
          },
          {
            bottom: '-0px',
            left: '0px',
            offset: 1,
          },
        ],
        {
          // duration: 6000,
          duration: this.roofState === '关闭中' ? 16000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    upBottom() {
      var bottom = document.getElementsByClassName('bbottom')[0];
      bottom.animate(
        [
          {
            transform: 'rotateX(270deg) translateZ(0px)',
            offset: 0,
          },
          {
            transform: 'rotateX(270deg) translateZ(-30px)',
            offset: 1,
          },
        ],
        {
          // duration: 16000,
          duration: this.stageState === '上升中' ? 16000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    downBottom() {
      var bottom = document.getElementsByClassName('bbottom')[0];
      bottom.animate(
        [
          {
            transform: 'rotateX(270deg) translateZ(-30px)',
            offset: 0,
          },
          {
            transform: 'rotateX(270deg) translateZ(0px)',
            offset: 1,
          },
        ],
        {
          // duration: 16000,
          duration: this.stageState === '下降中' ? 16000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 夹紧横杆 */
    clampYAcitive() {
      var fanceLeft = document.getElementsByClassName('y')[1];
      fanceLeft.animate(
        [
          {
            left: '-10px',
            top: '0px',
            offset: 0,
          },
          {
            left: '0px',
            top: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceRight = document.getElementsByClassName('y')[0];
      fanceRight.animate(
        [
          {
            right: '-10px',
            bottom: '0px',
            offset: 0,
          },
          {
            right: '0px',
            bottom: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 松开横杆 */
    fanceYAcitive() {
      var fanceLeft = document.getElementsByClassName('y')[1];
      fanceLeft.animate(
        [
          {
            left: '0px',
            top: '0px',
            offset: 0,
          },
          {
            left: '-10px',
            top: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceRight = document.getElementsByClassName('y')[0];
      fanceRight.animate(
        [
          {
            right: '0px',
            bottom: '0px',
            offset: 0,
          },
          {
            right: '-10px',
            bottom: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.braceState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    /* 松开卡爪 */
    letGo() {
      var fanceTop = document.getElementsByClassName('x')[0];
      fanceTop.animate(
        [
          {
            left: '-15px',
            top: '0px',
            offset: 0,
          },
          {
            left: '0px',
            top: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceBottom = document.getElementsByClassName('x')[1];
      fanceBottom.animate(
        [
          {
            right: '-15px',
            bottom: '0px',
            offset: 0,
          },
          {
            right: '0px',
            bottom: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '松开中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
    collectBuckle() {
      var fanceTop = document.getElementsByClassName('x')[0];
      fanceTop.animate(
        [
          {
            left: '0px',
            top: '-15px',
            offset: 0,
          },
          {
            left: '0px',
            top: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
      var fanceBottom = document.getElementsByClassName('x')[1];
      fanceBottom.animate(
        [
          {
            right: '0px',
            bottom: '-15px',
            offset: 0,
          },
          {
            right: '0px',
            bottom: '0px',
            offset: 1,
          },
        ],
        {
          duration: this.clawState === '夹紧中' ? 7000 : 0,
          fill: 'forwards',
          iterations: 1,
        }
      );
    },
  },
};
</script>

<style lang="scss" scpoed>
.hive-model-MK4-wrap {
  position: relative;
  .btn-group {
    top: -60px;
    left: 4px;
    position: absolute;
    display: flex;
    flex-direction: row;
  }
  img {
    border: 0;
  }

  ul {
    width: 60px;
    height: 60px;
    position: relative;
    left: 10px;
    transform: rotateX(320deg) rotateY(130deg);
    -webkit-transform: rotateX(320deg) rotateY(130deg);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    .active {
      box-shadow: 0 2px 12px 0 rgba(173, 255, 47, 0.5);
    }
  }
  li {
    width: 60px;
    height: 60px;
    position: absolute;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    color: #fff;
  }

  li:nth-child(1) {
    transform: rotateX(90deg) translateZ(30px);
    -webkit-transform: rotateX(90deg) translateZ(30px);
  }

  li:nth-child(1) .door {
    width: 60px;
    height: 30px;
    background-color: rgba(255, 0, 0, 0.5);
    position: absolute;
    left: 0px;
  }

  .door-top {
    top: 0px;
    transform-origin: top center;
    background-image: url('./img-mk4/door-top.png');
  }

  .door-bottom {
    bottom: 0px;
    transform-origin: bottom center;
    background-image: url('./img-mk4/door-bottom.png');
  }

  li:nth-child(2) {
    background: rgba(0, 0, 0, 0);
    background-image: url('./img-mk4/black.png');
    transform: rotateX(270deg) translateZ(0px);
    -webkit-transform: rotateX(270deg) translateZ(0px);
  }

  .x,
  .y {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
  }

  .x {
    width: 60px;
    height: 20px;
    z-index: 5;
  }

  .y {
    width: 20px;
    height: 60px;
    z-index: 10;
  }

  .fance-top {
    top: 0;
    border-bottom: 4px solid #f40;
  }

  .fance-left {
    left: 0;
    border-right: 4px solid #333;
  }

  .fance-right {
    right: 0;
    border-left: 4px solid #333;
  }

  .fance-bottom {
    bottom: 0;
    border-top: 4px solid #f40;
  }

  .active {
    box-shadow: 1px 1px 14px #aaa, -1px -1px 14px #aaa;
  }

  li:nth-child(3) {
    background: rgba(255, 0, 255, 0.5);
    transform: rotateY(90deg) translateZ(30px);
    -webkit-transform: rotateY(90deg) translateZ(30px);
  }

  li:nth-child(4) {
    background: rgba(0, 255, 0, 0.5);
    transform: rotateY(270deg) translateZ(30px);
    -webkit-transform: rotateY(270deg) translateZ(30px);
  }

  li:nth-child(5) {
    background: rgba(200, 200, 0, 0.5);
    transform: translateZ(-30px);
    -webkit-transform: translateZ(-30px);
  }

  li:nth-child(6) {
    background: rgba(0, 0, 255, 0.5);
    transform: translateZ(30px);
    -webkit-transform: translateZ(30px);
  }
  li:nth-child(7) {
    background-color: rgba(255, 0, 0, 0.5);
    transform: rotateX(270deg) translateZ(30px);
    -webkit-transform: rotateX(270deg) translateZ(30px);
  }
  li:nth-child(8) {
    background: rgba(0, 0, 0, 0.5);
    transform: rotateX(80deg) rotateY(5deg) translateZ(15px);
    -webkit-transform: rotateX(80deg) rotateY(5deg) translateZ(15px);
  }
}
</style>

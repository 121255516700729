import moment from 'moment'
import { HIVE_STATE } from '@/enum/HIVE_STATE'
class ParseHiveInfomation {

    parseWorkState(state) {
        let cn = HIVE_STATE.WORK.getDescFromValue(state)
        return cn
    }
    // 天花板
    parseRoofState(state) {
        let cn = HIVE_STATE.ROOF.getDescFromValue(state)
        return cn
    }

    // 机库工作状态
    parseFlowState(state) {
        var cn = HIVE_STATE.FLOW.getDescFromValue(state)
        return cn
    }

    // 平台
    parseStageState(state) {
        var cn = HIVE_STATE.STAGE.getDescFromValue(state)
        return cn
    }

    // 横杆
    parseBraceState(state) {
        var cn = HIVE_STATE.BRACE.getDescFromValue(state)
        return cn
    }

    // 卡爪
    parseClawState(state) {
        let cn = HIVE_STATE.CLAW.getDescFromValue(state)
        return cn
    }
    parseRainingState(RainingState) {
        var state = '有'
        if (RainingState === true) {
            state = '有'
        } else {
            state = '无'
        }
        return state
    }

    parseWindDirection(WindDirection) {
        var state = ''
        switch (WindDirection) {
            case 0:
                break
        }
        return state
    }

    parseHiveFlowOperate(from, to, code, type) {
        var state = {}
        state.msgFrom = from
        state.msgTo = to
        state.actionID = code
        state.actionType = type
        state.time = moment().format('YYYY-MM-DD HH:mm:ss')
        return state
    }
}
export default new ParseHiveInfomation()

<template>
  <div class="handle-content-wrap">
    <page-header :hiveInformation="hiveInformation" :quantity="currentBatteryPercent" :level="droneInfomation.GPSLevel" :waypointCount="waypointCount" :missionBatch="missionBatch" :waypointIndex="waypointIndex" :startSite="site" :endSite="endSite"></page-header>
    <div class="container-wrapper">
      <div class="according-wrapper left-information-display">
        <hive-information :hiveInformation="hiveInformation" :siteDetail="site" :HM="site.hiveInfo ? site.hiveInfo.hiveModel : ''" v-show="msgArea === 1"></hive-information>
        <uav-information :droneInfomation="droneInfomation" :info="UAVInfo" v-show="msgArea === 2" :distanceToEnd="distanceToEnd" :distanceToStart="distanceToStart"></uav-information>
        <div class="mission-list-wrapper" v-show="msgArea === 3">
          <ActiveMission :currPlan="waitList[0]" />
          <el-divider content-position="left">计划任务排班表</el-divider>
          <Schedule :planList="planList" @removeFloorItem="removeFloorItem" />
        </div>
        <hive-information :hiveInformation="endHiveInformartion" :siteDetail="endSite" :HM="endSite && endSite.hiveInfo && endSite.hiveInfo.hiveModel ? endSite.hiveInfo.hiveModel : ''" v-show="msgArea === 4"></hive-information>
      </div>
      <div class="container-content-wrapper">
        <!-- cesium 地图 -->
        <cesium-map v-if="is3DMode" ref="cesiumMap" :trackUAV="isLock" :site="site" :laser="laserTargetLocation" :endSite="endSite" :uavPos="uavPosFor3D" @rendered="renderedCesium" :pts="flightPath" :records="records" @twoD="is3DMode = false" />
        <!-- ol 地图 -->
        <ol-map v-show="!is3DMode" :site="site" ref="map" @postrender="mapRendered" @distanceChange="distanceChange" @changePostion="temLocHandler" @threeD="is3DMode = true"></ol-map>

        <controls-bar ref="controlsBar" class="control-bar" @toggleRealTimeInformation="toggleRealTimeInformation" :endSiteID="endSiteID" :siteID="site.siteID"></controls-bar>
        <!-- http://10.127.20.172:8080/  A071663138414318833947 :missionBatch="droneInfomation.missionBatch" missionBatch="A071663138414318833947" :aiModels="aiModels" -->
        <tool-bar class="tool-bar" ref="toolBar" :siteMode="site.siteMode" :isRecording="droneRecording" :isOpen="isOpen" :isBoxing="isBoxing" :missionBatch="droneInfomation.missionBatch" @handleToolFunContorl="handleToolFunContorl" @changeView="changeView" @change:handleChangeAIMode="aiModuleChange"></tool-bar>

        <!-- 大疆错误日志 -->
        <div :class="['dji-log', DJIERROR_MSG_LIST.length > 0 ? 'dj-log-show' : '']" @click="logHandler('DJI')">
          <el-tooltip class="item" effect="dark" content="大疆错误日志">
            <i class="el-icon-message-solid" />
          </el-tooltip>
        </div>

        <div v-if="logshow === 'DJI'" class="dji-log-list x-scroll">
          <div v-for="(item, index) in DJIERROR_MSG_LIST" :key="'dji-error-list' + index" class="item">
            <span>{{ item.reason }}</span>
            <span>{{ timeStamp(item.timestamp) }}</span>
          </div>
        </div>

        <!-- 站点日志 -->
        <div :class="['dji-log site', checkList.length > 0 ? 'dj-log-show' : '']" @click="logHandler('site')">
          <el-tooltip class="item" effect="dark" content="站点日志">
            <i class="el-icon-date" />
          </el-tooltip>
        </div>

        <div v-if="logshow === 'site'" class="dji-log-list site x-scroll">
          <div v-for="(item, index) in checkList" :key="'dji-error-list' + index" class="item">
            <span>{{ item.event }}</span>
            <span>{{ timeStamp(item.date) }}</span>
          </div>
        </div>

        <Flv v-if="players[0]" class-name="first-angle" ref="firstViewFVideo" v-drag v-edit v-scroll class="first" vlabel="第一视角" :control-able="true" :is-boxing="isBoxing" :source="ANGLESRC.FIRST" :rtcUrl="ANGLESRC.UAVRTCURL" :is-a-rtc="true" :isTrace="isTargetBoxing" :is-point="true" :traceCoor="targetCoor" @cameraAuto="cameraAutoHandler" @submitParams="submitParamsHandler" />

        <Flv v-drag v-edit v-scroll class-name="second-angle" class="second" Vlabel="第三视角" v-if="players[1]" :source="ANGLESRC.SECOND" />

        <Flv v-drag v-edit v-scroll class-name="internal-angle" class="internal" Vlabel="内窥视角" v-if="players[2]" :source="ANGLESRC.INTERNAL" />

        <Flv v-drag v-edit v-scroll class-name="internal-rbt" class="rbt" Vlabel="机械臂视角" v-if="players[3]" :source="ANGLESRC.RBT" />

        <Flv v-drag v-edit v-scroll className="ai-angle" class="ai-mode" Vlabel="ai识别模块" v-if="ANGLESRC.AIMODE" :is-a-rtc="true" :isAI="true" :source="ANGLESRC.AISTREAM" :rtcUrl="ANGLESRC.AISTREAM" />
        <!-- 仪表盘 -->
        <div class="instriment-bar">
          <!-- 水平速度  -->
          <Horizontal :size="instrumentSize" :horizontalSpeed="droneInfomation.horizontalSpeed" v-if="droneInfomation.horizontalSpeed != undefined" />
          <!-- 垂直速度  -->
          <VerticalVelocity :size="instrumentSize" :verticalSpeed="droneInfomation.verticalSpeed" v-if="droneInfomation.verticalSpeed != undefined" />
          <!-- 电池电量 -->
          <Battery :size="instrumentSize" :cellData="battery" />
          <!-- 高度显示 -->
          <Altitude :size="instrumentSize" :altitudeTo="altitudeTo" :missionBatch="droneInfomation.missionBatch" />
          <!-- 罗盘 -->
          <Flightlndicator :size="instrumentSize" :compassState="droneInfomation.azimuth" style="margin-top: 15px" />
          <!-- <div class="check-box-wrap">
            <CheckList :checkList="checkList" />
          </div> -->
        </div>
      </div>
      <div v-if="(isSmallScreen && isOpen) || !isSmallScreen" :class="['according-wrapper right-console-wrapper', isOpen ? 'active-right' : '']">
        <el-collapse v-model="activeCollapseName" accordion>
          <el-collapse-item title="全自动任务" name="AutomaticMission">
            <automatic-mission :siteSFMode="site.siteSFMode" :type="missionType" :children="childrenList" :endSiteID="endSiteID" :missionList="missionList" :siteList="siteList" @submitForm="submitForm" @noEndSite="noEndSiteHandler" @index="indexHandler" @handleChangeEndSiteID="handleChangeEndSiteID" @change="changeHandler"></automatic-mission>
          </el-collapse-item>
          <el-collapse-item title="无人机控制面板" name="DroneControlPanel">
            <div class="ctrl-item-wrapper" v-if="true">
              <div class="area-wrapper">
                <el-button size="small" :type="hotKeyOpened ? 'danger' : 'primary'" @click="setHotKey">
                  {{ hotKeyOpened ? '关闭键盘模式' : '启动键盘模式' }}
                </el-button>
                <div v-if="hotKeyOpened" class="speed">
                  <el-select v-model="UAVOffset">
                    <el-option v-for="(item, index) in speeds" :key="'speed_' + index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div class="ctrl-item-wrapper" v-if="hotKeyOpened">
              <el-button style="width: 100%" size="small" type="primary" @click="showKeyboard">查看键盘</el-button>
            </div>

            <div class="ctrl-item-wrapper">
              <div class="ctrl-title">
                <strong>任务控制</strong>
              </div>
              <div class="area-wrapper wrapper-mission">
                <el-button :disabled="item.disabled" size="small" v-for="item in missionCtrl" :type="item.className" :key="item.value" @click="missionSetting(droneInfomation.UAVID, item.value, item)">{{ item.text }}</el-button>
              </div>
            </div>
            <div class="ctrl-item-wrapper">
              <div class="ctrl-title">
                <strong>指点飞行</strong>
              </div>

              <div class="area-wrapper wrapper-mission">
                <el-button :disabled="!uavpointingflightAble" v-if="!pointFlyShow" class="container-flat" size="small" type="primary" @click="startSetTempPt">指点飞行</el-button>

                <div class="container-flat" v-else>
                  <div>
                    <el-button size="small" type="primary" @click="sendTempPt" :disabled="!uavpointingflightAble">发送航点</el-button>
                    <el-button size="small" type="danger" @click="clearTempPt">清除航点</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="ctrl-item-wrapper">
              <div class="ctrl-title">
                <strong>无人机飞行控制</strong>
              </div>
              <div class="area-wrapper">
                <div class="disk-area-wrap">
                  <FourSquarePlae @setValue="uTranslation" />
                  <div>
                    步
                    <input class="input" type="text" v-model="offset" :max="20" :min="5" @change="inputTVal(offset)" />
                    m
                  </div>
                  <span>平移控制</span>
                </div>
                <div class="disk-area-wrap">
                  <Vertically @setValue="uTransLift" />
                  <div>
                    步
                    <input class="input" type="text" v-model="altitudeOffset" @change="inputAVal(altitudeOffset)" />
                    m
                  </div>
                  <span>高度控制</span>
                </div>
              </div>
              <div class="slider-wrapper">
                <div class="msg-bar">
                  <span>偏转调整</span>
                  <span>{{ direction(droneInfomation.azimuth) }}</span>
                </div>
                <SliderBar :min="-180" :max="180" value="10" :autoInit="true" :offset="3" refAttribute="yawAngle" @change="modulationYawAngle" />
              </div>
            </div>
            <div class="ctrl-item-wrapper">
              <div class="ctrl-title">
                <strong>镜头选择</strong>
              </div>
              <div class="area-wrapper">
                <el-button :disabled="cameraChangeDisabled" size="small" v-for="item in lens" :type="item.className" :key="item.value" @click="changeCamera(item.value)">{{ item.text }}</el-button>
              </div>
            </div>

            <div class="ctrl-item-wrapper">
              <div class="ctrl-title">
                <strong>无人机云台控制</strong>
              </div>

              <div class="slider-wrapper">
                <div class="camera-ctrl">
                  <el-button :disabled="cameraZoomDisabled" size="small" type="primary" @click="rectangleToZoom">{{ rectangleToZoomTxet }}</el-button>
                  <el-button :disabled="cameraTrackDisabled" size="small" type="primary" @click="rectangleToTarget">{{ rectangleToTargetTxet }}</el-button>
                </div>

                <div class="msg-bar">
                  <div>激光测距</div>
                  <div>
                    <el-switch :disabled="!laserDistanceAble" v-model="isUavLaserDistance" @change="laserGuage"></el-switch>
                  </div>
                </div>
                <div class="msg-bar">
                  <span>朝向调整</span>
                  <input class="reset-btn" value="复位" type="button" @click="gimbalReset" />
                </div>
                <SliderBar :min="-180" :max="180" :value="10" :autoInit="true" :offset="3" refAttribute="toward" @change="modulationToward" />
                <div class="msg-bar">
                  <span>俯仰调整</span>
                </div>
                <SliderBar :min="gimbalPitchMin" :max="gimbalPitchMax" :value="droneGimbalPitch" :autoInit="false" :offset="1" refAttribute="pitch" @change="modulationPitch" />
                <div class="msg-bar">
                  <span>变焦调整</span>
                </div>
                <SliderBar :min="0" :max="30" :value="droneZoom" :autoInit="false" :offset="1" refAttribute="zoom" @change="modulationZoom" />
              </div>
            </div>

            <div class="ctrl-item-wrapper">
              <div class="ctrl-title">
                <strong>喊话系统</strong>
              </div>
              <div>
                <div class="area-wrapper">
                  <el-input v-model="megophone.text" type="textarea" size="mini" :rows="3" :maxlength="40" placeholder="请输入文本转语音内容"></el-input>
                </div>

                <div class="area-wrapper">
                  <div style="dispaly: flex; flex-direction: row">
                    <i class="el-icon-mic" style="margin-right: 3px"></i>
                    音量设置：
                  </div>
                  <div>
                    <el-slider style="width: 130px" v-model="megophone.volume" :min="0" :max="99" @change="setVolume"></el-slider>
                  </div>
                </div>
                <div class="area-wrapper">
                  <div style="dispaly: flex; flex-direction: row">
                    <div class="speaker-contain" :title="currentspeakerType" @click="setSpeakerTimes">
                      {{ currentspeakerType.replace('播放', '') }}
                    </div>
                  </div>
                  <el-button size="mini" type="primary" @click="sendTextForVoice(true)">播放</el-button>
                  <el-button size="mini" type="danger" @click="sendTextForVoice(false)">暂停</el-button>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="媒体数据管理" name="MediaManagement">
            <el-form size="small">
              <el-form-item>
                <el-button type="success" style="width: 100%" @click="submitMissionData">
                  <i class="el-icon-upload"></i>
                  数据上传
                </el-button>
              </el-form-item>
              <el-form-item v-if="false">
                <el-popover placement="right" trigger="click">
                  <el-time-picker v-model="updateTime" placeholder="任意时间点"></el-time-picker>
                  <el-button slot="reference" type="primary" style="width: 100%">定时上传</el-button>
                </el-popover>
              </el-form-item>
              <el-form-item  v-if="false">
                <el-button type="warning" style="width: 100%">全时段上传</el-button>
              </el-form-item>
              <el-form-item  v-if="false">
                <el-button type="danger" style="width: 100%" @click="submitMissionData">
                  <i class="el-icon-delete-solid"></i>
                  格式化SD卡
                </el-button>
              </el-form-item>
            </el-form>
          </el-collapse-item>
          <SiteMaintain v-if="siteIsHiveMode" @open="openHive" />
        </el-collapse>
        <el-button type="danger" style="width: 260px" size="mini" @click="droneReturn">立即返航</el-button>
      </div>
    </div>
    <Inspection :site="site" :subState="droneInfomation.subState" :hiveInfo="hiveInformation" v-if="missionBatch && !inspection" />

    <div class="examine-bar-area footer-load-wrap" v-if="isExamine">
      <Examine :site="site" :hiveInfo="hiveInformation" :endSiteID="endSiteID" :uavInfo="droneInfomation" :weather="hiveInformation.weather" @handelExamine="handelExamine" :endHiveInfo="endHiveInformartion" v-if="isExamine" />
    </div>

    <el-dialog :close-on-click-modal="false" :visible.sync="tempPtDialog" title="提示" width="400px" :before-close="handleClose">
      <el-form :model="tempPtObj" ref="temPtForm" label-width="120px" :rules="tmpPtRules">
        <el-form-item label="经纬度" prop="tmpLocInput">
          <el-input v-model="tempPtObj.tmpLocInput" @change="changeTmpPt"></el-input>
        </el-form-item>
        <el-form-item label="飞行高度(m)" prop="altitude">
          <el-input v-model.number="tempPtObj.altitude"></el-input>
        </el-form-item>
        <el-form-item label="飞行速度(m/s)" prop="speed">
          <el-input v-model.number="tempPtObj.speed"></el-input>
        </el-form-item>
        <el-form-item label="云台俯仰角(°)" prop="gimbalPitch">
          <el-input v-model.number="tempPtObj.gimbalPitch"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="tempPtDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitTempPtForm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="laserDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleLaserClose" width="30%" center>
      <span style="color: white">当前激光测距异常，是否关闭激光测距？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleLaserClose">取 消</el-button>
        <el-button type="primary" @click="closeLaser">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="分享" :visible.sync="shareDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleShareClose" width="30%">
      <div class="share-content">
        <div style="width: 55%">
          <el-input v-model="shareLink" readonly id="shareLink" />
          <el-button id="copyBtn" class="copy" type="primary" icon="el-icon-link" :data-clipboard-text="shareLink" data-clipboard-action="copy" data-clipboard-target="#shareLink" @click="copyShareLink">复制链接</el-button>
        </div>
        <div class="qr-contaner">
          <div class="qr" @click="biggerQR = true">
            <vue-qr :logoSrc="qrLogo" :text="shareLink" :size="100"></vue-qr>
          </div>

          <span>扫码查看飞行记录,点击二维码可放大</span>
        </div>
      </div>
    </el-dialog>

    <div v-if="biggerQR" class="bigger-qr">
      <div>
        <i @click="biggerQR = false" class="el-icon-close"></i>
        <vue-qr :logoSrc="qrLogo" :text="qrLink" :size="600"></vue-qr>
      </div>
    </div>

    <!-- 控制杆 -->
    <Stick @message="stickMessageHandler" @closed="stickWSClosedHandler" />
  </div>
</template>

<script>
import Horizontal from './echarts/Horizontal.vue';
import VerticalVelocity from './echarts/verticalVelocity.vue';
import Battery from './echarts/Battery.vue';
import Flightlndicator from './flightlndicator/index.vue';
import Altitude from './echarts/altitude.vue';
import CheckList from './echarts/CheckList.vue';
import PageHeader from './component/pageHeader';
import UavInformation from './component/uavInformation';
import SiteMaintain from './component/SiteMaintain';
import HiveInformation from './component/hiveInformation';
import ActiveMission from './component/ActiveMission';
import Schedule from './component/Schedule';
import ControlsBar from './component/ControlsBar';
import ToolBar from './component/ToolBar';
import AutomaticMission from './component/AutomaticMission';
import FourSquarePlae from './component/fourSquarePlate';
import OlMap from '@/components/maps/OlMap';
import CesiumMap from '@/components/maps/CesiumMap';
import Vertically from './component/issue';
import Flv from '@/components/Player/Flv.vue';
import AliRts from '@/components/Player/RTSPlayer.vue';
import Examine from './component/Examine.vue';
import Inspection from './component/Inspection.vue';
import SliderBar from '@/components/SliderBar';
import Stick from '@/components/stick/index.vue';
import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE';
import vueQr from 'vue-qr';
import Clipboard from 'clipboard';
import { setStreamInfo, getStreamInfo, removeStreamInfo } from '@/utils/sessionstorage.config.js';
import socket from '@/utils/socket.js';
import { getBatchinfo } from '@/api/flightflows';
import { getSiteGroup, getSiteDetail, updateSiteInformation } from '@/api/site';
import { getMissionBySiteId } from '@/api/mission';
import { locationSetting, missionSetting, gimbalSetting, cameraSetting, cameraFollwByCoors, startCameraTrack, cameraZoomByCoors, joystickSetting, megophonesetting, uavpointingflight, startLaserGuage, cameraAutoByPoint, openHive } from '@/api/director';
import { getAIVideoStream, getAIType, unEnableAIStream } from '@/api/discern';
import { getMissionDetail, sendMission } from '@/api/mission';
import { mediaSetting } from '@/api/media';
import { getUserInformation, getSocketAccount, getUserCorporationsInformation } from '@/utils/auth';
import { getMissionShareID } from '@/api/share';
import { getCronmissions, deleteTimer } from '@/api/timer';
import { parsingInfomation } from '@/utils/parsingSocketInfomation.js';
import statusInclude from '@/utils/statusCode';
import { MODULES } from '@/utils/discern.config';
import sleep from '@/utils/sleep';
import { UAV_ACTION } from '@/enum/UAV_ACTION';
import { UAV_STATE, UAV_STATE_CANFLY, UAV_ALLOW_TMP_PT, UAV_STATE_IN_HIVE, JOYSTICK_ALLOW_TO_SEND } from '@/enum/UAV_STATE';
import { isLnglat, isLnglatByValue } from '@/utils/rules';

import { KEYBOARD_UAV_VALID } from '@/enum/KEYBOARD_UAV_VALID';
import { base_url } from '@/utils/BASE_URL';

const IntervalType = {
  POS: 1,
  MISSION: 2,
  GIMBAL: 3,
  CAMERA: 4,
  OTHER: 5,
};
const STAND_SITES = [3, 4, 5]; // 单机
const WS_UAV_PER_SEC_COUNT_MAX_PLUS = 150; //一秒：50 -200  // ws joystick发送频次
const WS_UAV_SPEED_MAX = 100; // 控制杆 三个值 -100~100

const posKeyCodeSet = new Set([KEYBOARD_UAV_VALID.W, KEYBOARD_UAV_VALID.S, KEYBOARD_UAV_VALID.A, KEYBOARD_UAV_VALID.D, KEYBOARD_UAV_VALID.Q, KEYBOARD_UAV_VALID.E, KEYBOARD_UAV_VALID.PAGEUP, KEYBOARD_UAV_VALID.PAGEDOWN]);
const missionKeyCodeSet = new Set([KEYBOARD_UAV_VALID.Spacebar, KEYBOARD_UAV_VALID.F1]);
const gimbalKeyCodeSet = new Set([
  KEYBOARD_UAV_VALID.ARROWTUP, // ↑: 向上
  KEYBOARD_UAV_VALID.ARROWDOWN, // ↓:向下
  KEYBOARD_UAV_VALID.ARROWLEFT, // ←:左
  KEYBOARD_UAV_VALID.ARROWRIGHT, // →:右
  KEYBOARD_UAV_VALID.HOME, // 复位
]);
const cameraKeyCodeSet = new Set([
  // === 相机 ,cameraSetting,指令集
  KEYBOARD_UAV_VALID.P, // 拍照
  KEYBOARD_UAV_VALID.TAB, // 镜头视角
  KEYBOARD_UAV_VALID.L, // 录像、停止
  KEYBOARD_UAV_VALID.MINUS, //
  KEYBOARD_UAV_VALID.INCREASE, //
]);

let HOTKEY = 'hotKey_';

const otherKeyCodeSet = new Set([]);
// 报文时间戳差值判定为存在丢失现象的最小毫秒数
const MILESECONDS_MISSING = 1000;
const sendCmdInterval = {};
const SECCOUNT = 45; //  一秒发33次数左右（20~50） // 35
let keyDownCheckInterVal = null;

/**
 * @Author: 篆愁君
 * @Date: 2022-10-08 12:52:48
 * @description: 根据机库类型 枚举 是否有实体机库 1 机库 2 非机库
 * @return {*}
 */
const TASKSITEMODE = {
  1: 1,
  2: 2,
  3: 1,
  4: 2,
  5: 2,
};

export default {
  name: 'HandleContent',
  components: {
    Stick,
    vueQr,
    OlMap,
    CesiumMap,
    PageHeader,
    UavInformation,
    SiteMaintain,
    HiveInformation,
    ActiveMission,
    Schedule,
    ControlsBar,
    ToolBar,
    AutomaticMission,
    FourSquarePlae,
    Vertically,
    Flv,
    AliRts,
    Examine,
    Inspection,
    SliderBar,
    Horizontal,
    VerticalVelocity,
    Battery,
    Altitude,
    Flightlndicator,
    CheckList,
  },
  data() {
    return {
      instrumentSize:'200px',
      missionType: 0,
      childMissionIndex: 0,
      childrenList: [],
      shareId: null,
      shareDialogVisible: false,
      biggerQR: false,
      isSmallScreen: false,
      hideLeft: true,
      batchInfoGlobal: null,
      globalCode: -1,
      hotKeyOpened: false,
      cameraChangeDisabled: false,
      cameraZoomDisabled: false,
      cameraTrackDisabled: false,
      keyDownDateSeconds: new Date().getTime(),
      UAV_POS_KEYCODE_SET: new Set(),
      uavPos_manualIterval: null,
      UAVInfo: {},
      sendMissioning: false,
      wsMsgTmpData: [], // 无人机报文缓存
      laserTargetLocation: [],
      wsMsgTmpDataCount: 8, // 大约2秒
      laserDialogVisible: false,
      mapModeImg: {
        two: require('@/assets/images/map/2d.png'),
        three: require('@/assets/images/map/3d.png'),
      },
      qrLink: `${base_url.share}?missionBatch=`,
      qrLogo: require('@/assets/images/logo/logo.png'),
      isSiteUpdated: false,
      gimbalPitchMax: 0,
      gimbalPitchMin: -90,
      cesiumInstance: null,
      is3DMode: false,
      DJIERROR_MSG: '',
      logshow: '',
      DJIERROR_MSG_LIST: [],
      checkList: [],
      tmpPtRules: {
        tmpLocInput: [{ required: true, validator: isLnglat, trigger: 'blur' }],
        gimbalPitch: [{ required: true, trigger: 'blur' }],
        altitude: [{ required: true, trigger: 'blur' }],
        speed: [{ required: true, trigger: 'blur' }],
      },
      tempPtDialog: false,
      tmpLoc: [],
      tempPtObj: {
        tmpLocInput: '',
        altitude: 120, // 海拔
        speed: 5,
        gimbalPitch: 0,
      },
      tempPtHt: 120,
      isAddingTepPt: false, // 正在添加临时航点
      pointFlyShow: false,
      sendedAndPtExisits: false, // 存在已发送的临时航点
      keyPressing: false,
      megophone: {
        text: '', // 喊话器语音文本
        volume: 1, // 喊话器音量
        times: 1, // 喊话器循环次数
      },
      keyboardImg: require('@/assets/images/keyboard.png'),
      UAVOffset: 10,
      cameraZoomOffset: 1,
      speeds: [
        { label: '低档', value: 10 },
        { label: '中低档', value: 30 },
        { label: '中档', value: 50 },
        { label: '中高档', value: 70 },
        { label: '高档', value: 80 },
      ],

      intervalType: -1, //
      toAdd: true,
      sendCmdIntervalGap: 50, //ms 毫秒钟触发一次
      singleMode: true,
      updateTime: '',
      getBatchinfo_sending: false,
      joystickRequesting: false,
      stickUavHeightInterVal: null,
      stickUavHeadInterVal: null,
      stickUavForwardInterVal: null,
      stickUavBackwardInterVal: null,
      stickUavLeftInterVal: null,
      stickUavRightInterVal: null,
      stickGimbalHeadInterVal: null,
      stickGimbalPitchInterVal: null,
      flightPath: [],
      map: null,
      modlues: 6147,
      msgArea: 2,
      missionAllowTemp: false, //用于解决页面选择了任务，还未下发不清理航线
      isLock: true,
      isExamine: false,
      isOpen: false,
      isBoxing: false,
      isZoomBoxing: false,

      socketInstance: null,
      activeCollapseName: '',
      offset: 5,
      altitudeOffset: 5,
      destination: 1,
      waypointCount: 0,
      missionBatch: '',
      waypointIndex: 0,
      droneZoom: 0,
      droneGimbalPitch: 0,
      droneSubState: 0,
      droneRecording: 0,
      widgetYawAngle: null,
      widgetToward: null,
      widgetPitch: null,
      widgetZoom: null,
      inspection: true,
      endSiteID: '',
      missionList: [],
      siteList: [],

      discernModules: MODULES,
      lens: [
        { text: '可视', value: 1, className: 'primary' },
        { text: '红外', value: 2, className: 'primary' },
        // { text: '画中画', value: 3, className: 'primary' },
        // { text: '混合', value: 4, className: 'primary' },
        { text: '广角', value: 5, className: 'primary' },
      ],
      currentLen: 1, // 当前镜头是可视
      missionCtrl: [
        { text: '暂停', value: UAV_ACTION.MISSION.pause, className: 'primary', disabled: false },
        { text: '恢复', value: UAV_ACTION.MISSION.recover, className: 'success', disabled: false },
        { text: '停止', value: UAV_ACTION.MISSION.stop, className: 'danger', disabled: false },
      ],
      players: [false, false, false, false, false],
      waitList: [],
      planList: [],
      sendMissionParam: {},
      missionDetail: {},
      records: [],
      socketAccount: {},
      userInformation: {},
      siteDetail: {},
      ANGLESRC: {
        FIRST: '',
        AIMODE: false,
        UAVRTCURL: null,
        SECOND: '',
        INTERNAL: '',
        AISTREAM: '',
      },
      site: {
        siteID: '',
        hiveInfo: {
          hiveModel: '',
        },
      },
      endSite: null,
      isUavLaserDistance: false,
      laserDistanceAble: true,
      droneInfomation: {
        UAVID: '',
        subState: 0,
        isManualMode: false,
        isTargetTrace: false,
        targetTrace: [],
        missionBatch: null,
        missionID: null,
        model: '',
        distanceStart: 0,
        gimbalModel: '',
        isUAVOnline: 0,
        isGSOnline: 0,
        longitudeWGS: 0,
        latitudeWGS: 0,
        isOSDKAvailable: 0,
        isRecording: 0,
        GPSLevel: 0,
        GPSCount: 0,
        altitude: 0,
        horizontalSpeed: 0,
        verticalSpeed: 0,
        gimbalPitch: -90,
        azimuth: 0,
        gimbalYaw: 0,
        zoom: 2,
        upLink: 0,
        downLink: 0,
        batteries: [],
      }, //socket -> 无人机实时报文
      hiveInformation: {
        flowState: '未知',
        roofState: '未知',
        stageState: '未知',
        braceState: '未知',
        clawState: '未知',
        chargeOn: '未知',
        isInChargingFlow: '未知',
        chargeVoltageLevel: 0,
      }, //socket -> 机库实时报文
      endHiveInformartion: {
        flowState: '未知',
        roofState: '未知',
        stageState: '未知',
        braceState: '未知',
        clawState: '未知',
        chargeOn: '未知',
        isInChargingFlow: '未知',
        chargeVoltageLevel: 0,
      },
      aiModels: [],
    };
  },
  beforeCreate(){

    
  },
  created() {
    if (document.body.clientWidth < 800) {
      this.isSmallScreen = window.navigator;
    }
    // this.getAIType();

    window.addEventListener('keyup', this.handleKeyUps);
    window.addEventListener('keydown', this.handleKeyDowns);

    document.addEventListener('visibilitychange', this.visibilitychangeHandler);
    keyDownCheckInterVal = setInterval(() => {
      if (new Date().getTime() - this.keyDownDateSeconds > 2000) {
        if (this.uavPos_manualIterval) {
          clearInterval(this.uavPos_manualIterval);
        }
        this.stopHotKeyInterval();
        this.uavPos_manualIterval = null;
        this.UAV_POS_KEYCODE_SET.clear();
      }
    }, 1000);

    this.site.siteID = this.$route.query.siteID;
    document.onkeydown = function (event) {
      if (event.keyCode == 9) {
        //如果是其它键，换上相应在ascii 码即可。
        return false;
      }
    };
    window.onresize = () => {
      this.instrumentResize();
    };
  },
  mounted() {
    document.body.oncontextmenu = function () {
      return false;
    };
    this.map = this.$refs.map;
    this.userInformation = getUserInformation();
    console.log(this.userInformation);
    this.socketAccount = getSocketAccount();
    getCronmissions(this.site.siteID)
      .then((res) => {
        const { data, code, reason } = res;
        if (statusInclude(code)) {
          const { list, total } = data;
          this.planList = list;
          const time = this.$moment().format('YYYY-MM-DD HH:mm:ss'); //获取当前时间
          this.planList.forEach((e) => {
            const seconds = this.$moment(e.excuteTime * 1000).diff(this.$moment(new Date(time).getTime()), 'seconds');
            if (seconds > 0) {
              this.waitList.push(e);
              this.$store.commit('content/INIT_TIMEDTASK', false);
            }
          });
        } else {
          this.$message.error(reason);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    shareLink() {
      return `${base_url.share}?missionBatch=${this.missionBatch}&&shareId=${this.shareId}`;
    },
    // 无人机的3D位置数据
    uavPosFor3D() {
      if (this.missionBatch && this.droneInfomation) {
        return {
          position: [this.droneInfomation.longitudeWGS, this.droneInfomation.latitudeWGS, parseFloat(this.droneInfomation.altitude)],
          head: this.droneInfomation.UAVYaw,
          pitch: this.droneInfomation.UAVPitch,
          roll: this.droneInfomation.UAVRoll,
        };
      }
      return {
        position: [0, 0, 0],
        head: 0,
        pitch: 0,
        roll: 0,
      };
    },

    // 是否能够发送临时航点指令（待命，动作待命，可调飞 ）
    uavpointingflightAble() {
      let able = false;
      if (UAV_ALLOW_TMP_PT.includes(this.droneInfomation.subState)) {
        able = true;
      }
      return able;
    },

    isTargetBoxing() {
      if (this.droneInfomation?.isTargetTrace) {
        this.isBoxing = true;
        this.isZoomBoxing = false;
        return true;
      } else {
        if (!this.isZoomBoxing) {
          this.isBoxing = false;
        }
      }
      return false;
    },

    isLaserGuaging() {
      if (this.droneInfomation?.isLaserEnabled) {
        return true;
      }
      return false;
    },

    // 目标追踪实时坐标
    targetCoor() {
      if (this.droneInfomation?.targetTrace) {
        let coor = this.droneInfomation.targetTrace;
        // coor= this.coorAddBlackSides(coor);
        return coor;
      }
      return [];
    },

    currentspeakerType() {
      let value = '循环播放';
      if (this.megophone.times == 1) {
        value = '单次播放';
      } else if (this.megophone.times > 1) {
        value = '播放10次';
      }
      return value;
    },
    siteIsHiveMode() {
      if (this.site && this.site.siteMode == SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
        return true;
      } else {
        return false;
      }
    },
    //天花板状态
    roofState() {
      return this.hiveInformation.roofState;
    },
    //工作状态
    flowState() {
      return this.hiveInformation.flowState;
    },
    //平台状态
    stageState() {
      return this.hiveInformation.stageState;
    },
    //横杆状态
    braceState() {
      return this.hiveInformation.braceState;
    },
    //卡爪状态
    clawState() {
      return this.hiveInformation.clawState;
    },
    //充电状态
    chargeOn() {
      return this.hiveInformation.chargeOn;
    },
    //电池电量
    battery() {
      let percent = 0;
      if (this.droneInfomation && this.droneInfomation.batteries && this.droneInfomation.batteries.length > 0) {
        let batteries = this.droneInfomation.batteries;
        for (let bat of batteries) {
          percent += bat.batteryPercent;
        }
        percent = parseInt(percent / batteries.length);
      }
      return percent;
    },
    //无人机高度
    altitudeTo() {
      return this.droneInfomation.altitude === '未知' ? 0 : this.droneInfomation.altitude;
    },
    // 距离终点距离 修改：由最后一个航点改为降落机库
    distanceToEnd() {
      if (this.droneInfomation && this.droneInfomation.longitudeWGS && this.droneInfomation.latitudeWGS) {
        if (this.endSite && this.endSite.siteLocation && this.endSite.siteLocation.length > 1) {
          let distance = this.map.calculateDistanceByPts([this.droneInfomation.longitudeWGS, this.droneInfomation.latitudeWGS], this.endSite.siteLocation);
          return distance + 'm';
        } else if (this.site && this.site.siteLocation && this.site.siteLocation.length > 1) {
          let distance2 = this.map.calculateDistanceByPts([this.droneInfomation.longitudeWGS, this.droneInfomation.latitudeWGS], this.site.siteLocation);
          return distance2 + 'm';
        }
      }
      return '未知';
    },

    // 距起点距离
    distanceToStart() {
      if (this.droneInfomation && this.droneInfomation.longitudeWGS && this.droneInfomation.latitudeWGS) {
        if (this.site && this.site.siteLocation && this.site.siteLocation.length > 1) {
          let distance = this.map.calculateDistanceByPts([this.droneInfomation.longitudeWGS, this.droneInfomation.latitudeWGS], this.site.siteLocation);
          return distance + 'm';
        }
      }
      return '未知';
    },

    currentBatteryPercent() {
      let val = '未知',
        val1 = 0,
        val2 = 0;
      if (this.droneInfomation) {
        val1 = this.droneInfomation.battery1Percent ? this.droneInfomation.battery1Percent : 0;
        val2 = this.droneInfomation.battery2Percent ? this.droneInfomation.battery2Percent : 0;
      }

      val = parseInt((val1 + val2) / 2);
      return val;
    },
    /* 计算方位角 */
    direction() {
      return (i) => {
        var text = '';
        i = i + 180;
        i = parseInt(i);
        if (i < 90) {
          text = `南偏西${i}`;
        } else if (i < 180) {
          text = `北偏西${180 - i}`;
        } else if (i < 270) {
          text = `北偏东${360 - i}`;
        } else if (i < 360) {
          text = `南偏东${360 - i}`;
        }
        return text;
      };
    },

    rectangleToZoomTxet() {
      if (!this.isZoomBoxing) {
        return '智能变焦';
      } else {
        return '停止变焦';
      }
    },

    rectangleToTargetTxet() {
      if (!this.isTargetBoxing) {
        return '智能跟踪';
      } else {
        return '停止跟踪';
      }
    },

    taskSiteMode() {},

    MeetTheClose() {
      return this.ANGLESRC.AISTREAM && this.missionBatch;
    },
  },
  watch: {
    shareLink(v) {
      this.qrLink = v;
    },
    isLaserGuaging(t) {
      this.isUavLaserDistance = t;
    },
    //天花板状态
    roofState(n, v) {
      if (!v) return;
      const event = `顶盖${n}`;
      this.setMsg(event);
    },
    //工作状态
    flowState(n, v) {
      if (!v) return;
      const event = `${n}`;
      this.setMsg(event);
    },
    //平台状态
    stageState(n, v) {
      if (!v) return;
      const event = `平台${n}`;
      this.setMsg(event);
    },
    //横杆状态
    braceState(n, v) {
      if (!v) return;
      const event = `横杆${n}`;
      this.setMsg(event);
    },
    clawState(n, v) {
      if (!v) return;
      const event = `卡爪${n}`;
      this.setMsg(event);
    },
    //充电
    chargeOn(n, v) {
      if (!v) return;
      const event = `充电状态${n}`;
      this.setMsg(event);
    },
    currentBatteryPercent(newVal, oldVal) {},
    missionBatch(n, o) {
      n ? (this.inspection = true) : (this.inspection = false);
      //当AI识别开着且任务结束时
      if (!n && o && this.ANGLESRC.AISTREAM) this.unEnableAIStream(o);
    },
    droneSubState(n, o) {
      if (this.missionBatch && !this.inspection) {
        if (UAV_STATE_CANFLY.includes(n)) {
          this.inspection = true;
        } else {
          this.inspection = false;
        }
      }
    },
    isOpen(n) {
      this.$nextTick((v) => {
        this.hideLeft = !v;
        this.map.updateSize();
         this.$nextTick(() => {
          setTimeout(()=>{
            this.instrumentResize()
          },500)
      });
      });
    },

    endSite(newVal, oldVal) {
      this.socketInstance.removeEventListener(this.endSiteID + ' state');
      this.endHiveInformartion = {};
      if (newVal) {
        let siteMode = newVal.siteMode;
        let hiveModel = null;
        if (newVal?.hiveInfo?.hiveModel) {
          hiveModel = newVal.hiveInfo.hiveModel;
        }
        this.onInformationBySocket(newVal.siteID); //开启B站socket连接
        // 添加降落机库
        this.map.addEndHiveMarker(siteMode, hiveModel, newVal.siteLocation[0], newVal.siteLocation[1], false);
        this.map.addEndHiveToMissionPath();
      } else {
        console.log('siteSFMode:' + this.site?.siteSFMode);
        // 添加降落机库
        let siteMode = this.site.siteMode;
        let hiveModel = null;
        if (this.site?.hiveInfo?.hiveModel) {
          hiveModel = this.site.hiveInfo.hiveModel;
        }
        this.map.addEndHiveMarker(siteMode, hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], true);
        this.map.addEndHiveToMissionPath();
      }
    },
    // 降落机库，起始机库的切换
    msgArea(v) {
      if (this.endSiteID && this.endSiteID != this.site.siteID) {
        if (v === 1) {
          // 起点机库
          this.getSiteStreams(this.site.siteID);
        } else if (v === 4) {
          // 降落机库
          this.getSiteStreams(this.batchInfoGlobal.endSiteID, true);
        }
      }
    },
  },
  methods: {
    // 获取批次的分享链接
    async getMissionShareID() {
      this.shareId = null;
      if (!this.missionBatch) {
        return this.$message({ type: 'success', message: '暂无可分享的批次！' });
      }
      const { code, reason, data } = await getMissionShareID(this.missionBatch);
      if (statusInclude(code)) {
        this.shareId = data.shareId;
        this.shareDialogVisible = true;
      } else {
        this.$message.error('获取任务的分享失败：' + reason);
      }
    },
    // 复制链接
    copyShareLink() {
      let clipboard = new Clipboard('#copyBtn');
      clipboard.on('success', (e) => {
        clipboard.destroy();
        this.$message({
          message: '已复制至剪切板!',
          type: 'success',
        });
      });
      clipboard.on('error', (e) => {
        this.$message.error('该浏览器不支持自动复制!');
        clipboard.destroy();
      });
    },
    handleShareClose() {
      this.shareDialogVisible = false;
    },
    biggerQRHide() {
      this.biggerQR = false;
    },
    // 更新站点
    async updateSiteDetail(site) {
      const { code, reason } = await updateSiteInformation(site);
      if (statusInclude(code)) {
        this.$message({
          message: '已更新单机位置信息!',
          type: 'success',
        });
      } else {
        this.$message.error('更新单机位置失败：' + reason);
      }
    },
    visibilitychangeHandler() {
      if (document.hidden) {
        this.clearStickIntervals();
        this.stopHotKeyInterval();
      }
    },

    // 无人机暂停或者待命状态
    isUAVIsStatic() {
      if (this.uavInfo.subState) {
        this.$message.error('暂无无人机！');
        return false;
      }
      return false;
    },

    // 处理控制杆消息, 一秒发送次数：20~ 200  （timeout:50,~ 5）
    stickMessageHandler(msg) {
      const { root = '', cmd = '', param = 0, stop = true, joystick = null } = msg;
      if (root === 'uav' && !JOYSTICK_ALLOW_TO_SEND.includes(this.droneInfomation.subState)) {
        return false;
      }
      if (!this.droneInfomation.isManualMode) {
        if (!this.joystickRequesting) {
          this.joystickSetting(true, true);
        }
        // return false;
      }

      // eslint-disable-next-line radix
      switch (root) {
        case 'uav':
          if (this.stickUavInterVal) {
            clearInterval(this.stickUavInterVal);
          }
          this.stickUavInterVal = null;
          if (!stop) {
            this.stickUavInterVal = setInterval(() => {
              this.sendJoystickDataByWS(joystick);
            }, SECCOUNT);
          }
          break;
        case 'mission':
          if (cmd === UAV_ACTION.MISSION.pause) {
            if (this.droneInfomation.subState === UAV_STATE.dongzuodaiming) {
              this.recoverMission();
            } else {
              this.pauseMission();
            }
          } else if (cmd === UAV_ACTION.MISSION.urgencyStop) {
            this.urgencyStopMission();
          }
          break;
        case 'gimbal':
          if (cmd === UAV_ACTION.GIMBAL.recover) {
            // 云台复位
            this.gimbalReset();
          } else if (cmd === UAV_ACTION.GIMBAL.pitch) {
            // 俯仰 上下抬头
            if (this.stickGimbalPitchInterVal) {
              clearInterval(this.stickGimbalPitchInterVal);
            }
            this.stickGimbalPitchInterVal = null;
            if (!stop) {
              const sendParam = this.droneInfomation.gimbalPitch + (5 * param) / Math.abs(param);
              gimbalSetting(this.droneInfomation.UAVID, UAV_ACTION.GIMBAL.pitch, sendParam);
              this.stickGimbalPitchInterVal = setInterval(() => {
                // 俯仰角，只能是定值
                gimbalSetting(this.droneInfomation.UAVID, UAV_ACTION.GIMBAL.pitch, sendParam);
              }, 500);
            }
          } else if (cmd === UAV_ACTION.GIMBAL.head) {
            // 朝向
            if (this.stickGimbalHeadInterVal) {
              clearInterval(this.stickGimbalHeadInterVal);
            }
            this.stickGimbalHeadInterVal = null;
            if (!stop) {
              gimbalSetting(this.droneInfomation.UAVID, UAV_ACTION.GIMBAL.head, (5 * param) / Math.abs(param));
              this.stickGimbalHeadInterVal = setInterval(() => {
                gimbalSetting(this.droneInfomation.UAVID, UAV_ACTION.GIMBAL.head, (5 * param) / Math.abs(param));
              }, 500);
            }
          }
          break;
        case 'camera':
          if (cmd === UAV_ACTION.CAMERA.lens) {
            // 镜头切换
            if (this.currentLen === 1) {
              this.currentLen = 2;
            } else if (this.currentLen === 2) {
              this.currentLen = 5;
            } else if (this.currentLen === 5) {
              this.currentLen = 1;
            } else {
              this.currentLen = 1;
            }
            cameraSetting(this.droneInfomation.UAVID, UAV_ACTION.CAMERA.lens, this.currentLen);
          } else if (cmd === UAV_ACTION.CAMERA.video) {
            // 录像与停止
            this.takeVideo();
          } else if (cmd === UAV_ACTION.CAMERA.zoom) {
            // 焦距
            const zoom = this.droneInfomation.zoom ? this.droneInfomation.zoom : 0;
            let sendParam = param;
            sendParam += zoom;
            cameraSetting(this.droneInfomation.UAVID, UAV_ACTION.CAMERA.zoom, sendParam);
          } else if (cmd === UAV_ACTION.CAMERA.snap) {
            this.takePhoto();
          }
          break;

        default:
          return false;
      }

      return false;
    },
    stickWSClosedHandler() {
      if (this.uavPos_manualIterval) {
        clearInterval(this.uavPos_manualIterval);
      }
      this.uavPos_manualIterval = null;
      // this.$message.error('未检测到控制杆程序！');
    },
    // cesium 加载成功
    renderedCesium() {
      this.cesiumInstance = this.$refs.cesiumMap;
      if (this.missionBatch) {
        // 初始化航线与轨迹
      }
    },

    timeStamp(timestamp) {
      if (timestamp) {
        if (typeof timestamp === 'string') {
          if (typeof timestamp === 'string') {
            return timestamp;
          }
          return timestamp;
        }
        if (typeof timestamp === 'number') {
          if (timestamp / Math.pow(10, 10) > 1) {
            // 毫秒
            return this.$moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
          } else {
            // 秒
            return this.$moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
          }
        }
      }
      return '';
    },

    getMileSeconds(timestamp) {
      if (timestamp) {
        if (typeof timestamp === 'string') {
          return new Date(timestamp).getTime() * 1000;
        }
        if (typeof timestamp === 'number') {
          if (timestamp / Math.pow(10, 10) > 1) {
            // 毫秒
            return timestamp;
          } else {
            // 秒
            return timestamp() * 1000;
          }
        }
      }
      return 0;
    },

    // 点击大疆日志icon处理
    logHandler(v) {
      if (v === this.logshow) {
        this.logshow = '';
        if (v === 'DJI') {
          this.DJIERROR_MSG_LIST = [];
          this.DJIERROR_MSG = '';
        }
      } else {
        this.logshow = v;
      }
    },
    changeTmpPt(lnlat) {
      if (isLnglatByValue(lnlat)) {
        let arr = lnlat.split(',');
        let pt = [parseFloat(arr[0]), parseFloat(arr[1])];
        this.tmpLoc = pt;
        this.map.changeTmpPt(pt);
      }
    },
    handleClose() {
      this.tempPtDialog = false;
    },
    temLocHandler(obj) {
      this.tmpLoc = [obj.longitude, obj.latitude];
      this.tempPtObj.tmpLocInput = `${obj.longitude},${obj.latitude}`;
    },
    // 清除临时航点
    clearTempPt() {
      this.pointFlyShow = false;
      this.map.startSetTempPt(false);
      this.map.stopTempPtChoose();
    },

    startSetTempPt() {
      if (!this.droneInfomation?.UAVID) {
        this.$message.error('暂无无人机！');
        return false;
      }
      this.pointFlyShow = true;
      this.map.startSetTempPt(true);
    },

    // 发送临时航点
    sendTempPt() {
      if(!UAV_STATE_IN_HIVE.includes(this.droneInfomation.subState)){
         this.tempPtObj.altitude = this.droneInfomation.altitude;
      }
      this.tempPtDialog = true;
    },
    submitTempPtForm() {
      this.$refs.temPtForm.validate((valid) => {
        if (valid) {
          this.uavpointingflight();
        }
      });
    },

    // 指点飞行
    async uavpointingflight() {
      if (!this.droneInfomation?.UAVID) {
        this.$message.error('暂无无人机！');
        return false;
      }
      let UAVID = this.droneInfomation.UAVID;
      let conType = 1;
      // 可调飞和待命的状态可发送临时航点
      if (this.droneInfomation.subState == UAV_STATE.dongzuodaiming || this.droneInfomation.subState == UAV_STATE.xuantigndengdai) {
        conType = 2;
      }
      this.tempPtObj.altitude = parseFloat(this.tempPtObj.altitude);
      let param = {
        UAVID,
        conType,
        flightParams: this.tempPtObj,
      };
      param.flightParams.location = this.tmpLoc;
      let { code, reason } = await uavpointingflight(param);
      if (statusInclude(code)) {
        this.tempPtDialog = false;
        this.$message({ type: 'success', message: '航点发送成功！' });
      } else {
        this.$message.error(reason);
      }
    },
    //设置喊话器次数
    setSpeakerTimes() {
      // 顺序：单词，循环，10次
      switch (this.megophone.times) {
        case 1:
          this.megophone.times = 0;
          break;
        case 0:
          this.megophone.times = 10;
          break;
        default:
          this.megophone.times = 1;
      }
    },
    showKeyboard() {
      window.open(this.keyboardImg);
    },

    deBounce2Seconds(fn, time = 2000) {
      setTimeout(() => {
        fn();
      }, time);
    },

    changeCamera(value) {
      this.currentLen = value;
      this.cameraChangeDisabled = true;
      this.deBounce2Seconds(() => {
        this.cameraChangeDisabled = false;
      });

      this.cameraSetting(this.droneInfomation.UAVID, UAV_ACTION.CAMERA.lens, this.currentLen);
    },

    clearTabDefult(event) {
      if (event.keyCode == 9) {
        //如果是其它键，换上相应在ascii 码即可。
        return false;
      }
    },

    setHotKey() {
      if (!this.droneInfomation?.UAVID) {
        this.$message.error('暂无无人机！');
        return false;
      }
      this.hotKeyOpened = !this.hotKeyOpened;
      this.stopHotKeyInterval();
      if (this.hotKeyOpened) {
        if (!this.droneInfomation.isManualMode) {
          this.joystickSetting(true);
        }
        // 启动手动模式
        this.activeHotKeys();
      } else {
        if (this.droneInfomation.isManualMode) {
          this.joystickSetting(false);
        }
        // 关闭手动模式
        this.stopHotKeys();
      }
    },

    async joystickSetting(enable, noTip) {
      let data = {
        UAVID: this.droneInfomation.UAVID,
        enable,
      };
      let { code, reason } = await joystickSetting(data);
      if (!noTip) {
        let tips = '启动';
        if (statusInclude(code)) {
          if (enable) {
            this.$message({
              message: '开启控制杆指令已发送!',
              type: 'success',
            });
          } else {
            tips = '关闭';
            this.$message({
              message: '关闭控制杆指令已发送!',
              type: 'success',
            });
          }
        } else {
          this.$message.error(tips + '控制杆指令失败：' + reason);
        }
      }
    },

    // 连续发送的指令. 水平：15,上下：4,角度：100  角速度：需要 最好 最大是50 再/2
    hotKeyAction_pos(code) {
      let offset = Math.abs(this.UAVOffset);
      if (!this.toAdd) {
        offset = -offset;
      }
      //Q,E ：左转，右转
      if (code == KEYBOARD_UAV_VALID.Q || KEYBOARD_UAV_VALID.E) {
        offset = offset / 10;
      }
      this.locationSetByWS(this.droneInfomation.UAVID, code, offset); // joystick 0-100
    },

    hotKeyAction_mission(code) {
      this.missionSetting(this.droneInfomation.UAVID, code);
    },

    hotKeyAction_gimbal(code) {
      let param = this.UAVOffset / 10; // 1-10
      if (!this.toAdd) {
        param = -param;
      }
      // 俯仰角，只能是定值
      if (code == UAV_ACTION.GIMBAL.pitch) {
        let pitch = this.droneInfomation.gimbalPitch ? this.droneInfomation.gimbalPitch : 0;
        param = param + pitch;
      } else if (code == UAV_ACTION.GIMBAL.head) {
        let pitch = this.droneInfomation.gimbalPitch ? this.droneInfomation.gimbalPitch : 0;
        param = param / 2.5;
      }
      this.gimbalSetting(this.droneInfomation.UAVID, code, param);
    },

    hotKeyAction_camera(code) {
      // 切换视角
      if (code == UAV_ACTION.CAMERA.lens) {
        if (this.currentLen == 1) {
          this.currentLen = 2;
        } else if (this.currentLen == 2) {
          this.currentLen = 5;
        } else if (this.currentLen == 5) {
          this.currentLen = 1;
        } else {
          this.currentLen = 1;
        }
        this.cameraSetting(this.droneInfomation.UAVID, code, this.currentLen);
      } else if (code == UAV_ACTION.CAMERA.zoom) {
        let param = this.cameraZoomOffset;
        if (!this.toAdd) {
          param = -param;
        }
        const zoom = this.droneInfomation.zoom ? this.droneInfomation.zoom : 0;
        param += zoom;
        this.cameraSetting(this.droneInfomation.UAVID, code, param);
      } else {
        this.cameraSetting(this.droneInfomation.UAVID, code);
      }
    },

    hotKeyAction_other() {},

    // 停止手动模式
    stopHotKeyInterval(code) {
      if (code) {
        clearInterval(sendCmdInterval[HOTKEY + code]);
      } else {
        this.globalCode = -1;
        const keys = Object.keys(sendCmdInterval);
        keys.forEach((key) => {
          clearInterval(sendCmdInterval[key]);
        });
      }
    },

    // 开启手动模式
    startHotKeyInterval(action, code) {
      const that = this;
      let sendCmdIntervalGap = 1000;
      if (that.intervalType === IntervalType.POS) {
        // eslint-disable-next-line radix
        sendCmdIntervalGap = SECCOUNT;
        that.hotKeyAction_pos(action);
        sendCmdInterval[HOTKEY + code] = setInterval(() => {
          that.hotKeyAction_pos(action);
        }, sendCmdIntervalGap);
      } else if (that.intervalType === IntervalType.MISSION) {
        that.hotKeyAction_mission(action);
        sendCmdInterval[HOTKEY + code] = setInterval(() => {
          that.hotKeyAction_mission(action);
        }, sendCmdIntervalGap);
      } else if (that.intervalType === IntervalType.GIMBAL) {
        that.hotKeyAction_gimbal(action);
        sendCmdInterval[HOTKEY + code] = setInterval(() => {
          that.hotKeyAction_gimbal(action);
        }, sendCmdIntervalGap);
      } else if (that.intervalType === IntervalType.CAMERA) {
        that.hotKeyAction_camera(action);
        sendCmdInterval[HOTKEY + code] = setInterval(() => {
          that.hotKeyAction_camera(action);
        }, sendCmdIntervalGap);
      }
    },
    // 通过ws发送无人机位置控制(所有值) joystick: { vertical: 0, pitch: 0, roll: 0, yaw: 0 }
    // 前后：pitch，左右：roll，左右转：yaw，上下：vertical（左上：正）
    // 实际最大值：水平最大速度是15,上下是4,，左右转是100
    // 左右转太大，需要除以10，最大100->10
    sendJoystickDataByWS(param) {
      const { UAVID } = this.droneInfomation;
      console.log(param);
      let joystick = param || null;
      if (!joystick) {
        joystick = { vertical: 0, pitch: 0, roll: 0, yaw: 0 };
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.W)) {
          // 前
          joystick.pitch = this.UAVOffset;
        }
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.A)) {
          // 左
          joystick.roll = -this.UAVOffset;
        }
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.Q)) {
          // 左转
          joystick.yaw = -this.UAVOffset / 5;
        }
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.PAGEUP)) {
          // 向上
          joystick.vertical = this.UAVOffset;
        }
        // 负值
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.S)) {
          // 向后
          joystick.pitch -= this.UAVOffset;
        }
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.D)) {
          // 向右
          joystick.roll = this.UAVOffset;
        }
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.E)) {
          // 右转
          joystick.yaw = this.UAVOffset / 5;
        }
        if (this.UAV_POS_KEYCODE_SET.has(KEYBOARD_UAV_VALID.PAGEDOWN)) {
          // 向下
          joystick.vertical -= this.UAVOffset;
        }
      }
      // 处理joystick 幅度
      // 通过ws发送无人机位置控制(所有值) joystick: { vertical: 0, pitch: 0, roll: 0, yaw: 0 }
      // 前后：pitch，左右：roll，左右转：yaw，上下：vertical（左上：正）
      // 实际最大值：水平最大速度是15,上下是4,，左右转是100
      // 左右转太大，需要除以10，最大100->10

      const timestamp = this.$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
      const params = {
        msgId: UAVID + timestamp,
        msgType: 'uav_manual_code',
        UAVID,
        siteFMode: this.site.siteSFMode,
        timestamp,
        joystick,
      };
      this.socketInstance.emit('uav manual-mission', params); // 提交指令
    },

    handleKeyDowns(evt) {
      const code = evt.keyCode;
      if (this.globalCode === code) {
        return;
      }
      if (posKeyCodeSet.has(code) && !JOYSTICK_ALLOW_TO_SEND.includes(this.droneInfomation.subState)) {
        return false;
      }
      this.globalCode = code;
      console.log('down');
      this.keyDownDateSeconds = new Date().getTime();
      this.intervalType = -1;
      if (missionKeyCodeSet.has(code)) {
        evt.preventDefault();
        evt.stopPropagation();
        this.intervalType = IntervalType.MISSION;
      } else if (posKeyCodeSet.has(code)) {
        this.UAV_POS_KEYCODE_SET.add(code);
        this.intervalType = IntervalType.POS;
        if (!this.droneInfomation.isManualMode) {
          this.joystickSetting(true, true);
        }
        if (!this.uavPos_manualIterval) {
          this.uavPos_manualIterval = setInterval(() => {
            this.sendJoystickDataByWS();
          }, SECCOUNT);
        }
        return false;
      } else if (gimbalKeyCodeSet.has(code)) {
        this.intervalType = IntervalType.GIMBAL;
      } else if (cameraKeyCodeSet.has(code)) {
        this.intervalType = IntervalType.CAMERA;
      } else if (otherKeyCodeSet.has(code)) {
        this.intervalType = IntervalType.OTHER;
      } else {
        return false;
      }
      let action = 0;
      switch (code) {
        //= === 无人机  指令集
        case KEYBOARD_UAV_VALID.W: // 87 W:前
          this.toAdd = true;
          action = UAV_ACTION.POS.forward;
          break;
        case KEYBOARD_UAV_VALID.S: // 83 S:后
          this.toAdd = false;
          action = UAV_ACTION.POS.backward;
          break;
        case KEYBOARD_UAV_VALID.A: // 65 A:向左
          this.toAdd = false;
          action = UAV_ACTION.POS.left;
          break;
        case KEYBOARD_UAV_VALID.D: //  68 D:向右
          this.toAdd = true;
          action = UAV_ACTION.POS.right;
          break;
        case KEYBOARD_UAV_VALID.Q: //  81 Q:左转
          action = UAV_ACTION.POS.head;
          this.toAdd = false;
          break;
        case KEYBOARD_UAV_VALID.E: // 69 E:右转
          action = UAV_ACTION.POS.head;
          this.toAdd = true;
          break;
        case KEYBOARD_UAV_VALID.PAGEUP: //  :向上
          action = UAV_ACTION.POS.height;
          this.toAdd = true;
          break;
        case KEYBOARD_UAV_VALID.PAGEDOWN: // :向下
          this.toAdd = false;
          action = UAV_ACTION.POS.height;
          break;

        // 无人机 指令集 ===

        // === 无人机 任务 missionSetting 指令集
        case KEYBOARD_UAV_VALID.Spacebar: // 暂停或恢复
          if (this.droneInfomation.subState === UAV_STATE.dongzuodaiming) {
            action = UAV_ACTION.MISSION.recover;
          } else {
            action = UAV_ACTION.MISSION.pause;
          }
          break;
        case KEYBOARD_UAV_VALID.F1: // 急停
          action = UAV_ACTION.MISSION.urgencyStop;
          break;
        // 无人机 任务 missionSetting 指令集 ===

        // === 云台  gimbalSetting,  指令集
        case KEYBOARD_UAV_VALID.ARROWTUP: // ↑: 向上
          this.toAdd = true;
          action = UAV_ACTION.GIMBAL.pitch;
          break;
        case KEYBOARD_UAV_VALID.ARROWDOWN: // ↓:向下
          this.toAdd = false;
          action = UAV_ACTION.GIMBAL.pitch;
          break;
        case KEYBOARD_UAV_VALID.ARROWLEFT: // ←:左
          this.toAdd = false;
          action = UAV_ACTION.GIMBAL.head;
          break;
        case KEYBOARD_UAV_VALID.ARROWRIGHT: // →:右
          this.toAdd = true;
          action = UAV_ACTION.GIMBAL.head;
          break;
        case KEYBOARD_UAV_VALID.HOME: // 复位
          action = UAV_ACTION.GIMBAL.recover;
          break;
        // 云台 指令集 ===

        // === 相机 ,cameraSetting,指令集
        case KEYBOARD_UAV_VALID.P: // 拍照
          action = UAV_ACTION.CAMERA.snap;
          break;
        case KEYBOARD_UAV_VALID.TAB: // 镜头视角
          action = UAV_ACTION.CAMERA.lens;
          break;
        case KEYBOARD_UAV_VALID.L: // 录像、停止
          if (this.droneInfomation.isRecording) {
            action = UAV_ACTION.CAMERA.videoStop;
          } else {
            action = UAV_ACTION.CAMERA.video;
          }
          break;
        case KEYBOARD_UAV_VALID.MINUS: // - 键
          this.toAdd = false;
          action = UAV_ACTION.CAMERA.zoom;
          break;
        case KEYBOARD_UAV_VALID.INCREASE: // + 键
          this.toAdd = true;
          action = UAV_ACTION.CAMERA.zoom;
          break;
        default: {
          console.error(evt);
        }
        // 相机 指令集 ===

        //= === 其他 指令集

        // 其他 指令集 ===
      }
      if (action && !posKeyCodeSet.has(code)) {
        this.startHotKeyInterval(action, code);
      }
      return false;
    },

    handleKeyUps(evt) {
      const { keyCode } = evt;
      this.globalCode = -1;
      if (posKeyCodeSet.has(keyCode)) {
        console.log('UAV_POS_KEYCODE_SET.delete(keyCode)');
        this.UAV_POS_KEYCODE_SET.delete(keyCode);
        if (this.UAV_POS_KEYCODE_SET.size === 0 && this.uavPos_manualIterval) {
          clearInterval(this.uavPos_manualIterval);
          this.uavPos_manualIterval = null;
        }
      }
      this.stopHotKeyInterval(keyCode);
    },

    // 开启热键映射
    activeHotKeys() {
      this.$message({
        message: '无人系统手动控制模式已开启，请谨慎驾驶',
        type: 'warning',
      });
      window.addEventListener('keydown', this.handleKeyDowns);
      window.addEventListener('keyup', this.handleKeyUps);
    },

    // 关闭热键映射
    stopHotKeys() {
      this.$message({
        message: '无人系统已切换回自动驾驶模式',
        type: 'warning',
      });
      window.removeEventListener('keyup', this.handleKeyUps);
      window.removeEventListener('keydown', this.handleKeyDowns);
    },

    changeHandler(missionId) {
      this.flightPath = [];
      this.missionAllowTemp = false;
      if (missionId) {
        this.getMissionDetail(missionId);
      } else {
        this.missionType = 0;
        this.childrenList = [];
        this.map.clearMissionPath();
        this.map.removeEndHiver();
        this.map.removeDashPath();
      }
    },

    indexHandler(index) {
      this.childMissionIndex = index;

      this.map.clearMissionPath();
      this.map.removeEndHiver();
      this.map.removeDashPath();
      if (this.missionDetail && this.missionDetail.childrenList) {
        this.childrenList = this.missionDetail.childrenList;
        let flightPath = this.missionDetail.childrenList[index];
        let hivePos = null;
        if (this.site && this.site.siteLocation) {
          hivePos = this.site.siteLocation;
        }
        this.map.setMissionPath(flightPath, null, hivePos);
        this.map.fitMissionPath();
        if (this.site?.siteSFMode != 1) {
          // 网联模式
          if (!this.endSiteID || this.endSiteID == this.site?.siteID) {
            // 添加降落机库
            let siteMode = this.site.siteMode;
            let hiveModel = null;
            if (this.site?.hiveInfo?.hiveModel) {
              hiveModel = this.site.hiveInfo.hiveModel;
            }
            this.map.addEndHiveMarker(siteMode, hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], false);
            this.map.addEndHiveToMissionPath();
          } else {
            this.map.addEndHiveToMissionPath();
          }
        }
      }
    },

    noEndSiteHandler(t) {
      this.singleMode = t;
      if (this.site) {
        // 添加降落机库
        let siteMode = this.site.siteMode;
        let hiveModel = null;
        if (this.site?.hiveInfo?.hiveModel) {
          hiveModel = this.site.hiveInfo.hiveModel;
        }
        this.map.addEndHiveMarker(siteMode, hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], false);
        this.map.addEndHiveToMissionPath();
      }
    },

    // 框选变焦
    rectangleToZoom() {
      if (!this.droneInfomation?.UAVID) {
        this.$message.error('暂无无人机！');
        return false;
      }
      this.cameraZoomDisabled = true;
      this.deBounce2Seconds(() => {
        this.cameraZoomDisabled = false;
      });
      if (this.isZoomBoxing) {
        this.isZoomBoxing = false;
        this.isBoxing = false;
      } else {
        if (this.isTargetBoxing) {
          this.startCameraTrack(this.droneInfomation.UAVID, false);
        }
        this.isBoxing = true;
        this.isZoomBoxing = true;
      }
    },

    handleLaserClose() {
      this.laserDialogVisible = false;
    },

    closeLaser() {
      this.laserDialogVisible = false;
      this.laserGuage(false);
    },

    // 开启/关闭激光测距
    laserGuage(t) {
      console.error('laserGuage');
      if (!this.droneInfomation?.UAVID) {
        this.$message.error('暂无无人机！');
        return false;
      }
      this.startLaserGuage(this.droneInfomation.UAVID, t);
    },
    // 框选跟踪目标
    rectangleToTarget() {
      if (!this.droneInfomation?.UAVID) {
        this.$message.error('暂无无人机！');
        return false;
      }
      this.cameraTrackDisabled = true;
      this.deBounce2Seconds(() => {
        this.cameraTrackDisabled = false;
      });
      if (this.isTargetBoxing) {
        this.startCameraTrack(this.droneInfomation.UAVID, false);
        this.isZoomBoxing = false;
        this.isBoxing = true;
      } else {
        this.startCameraTrack(this.droneInfomation.UAVID, true);
        this.isBoxing = false;
      }
    },

    /* 发送检测信息 */
    setMsg(e) {
      const msg = {
        event: e,
        date: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.checkList.unshift(msg);
      return msg;
    },

    async getMissionDetail(missionId) {
      this.missionDetail = null;
      this.missionType = 0;
      this.childrenList = [];
      let { code, data, reason } = await getMissionDetail(missionId);
      if (statusInclude(code)) {
        let flightPath = [];
        this.missionDetail = data;
        this.missionType = this.missionDetail.missionType;
        this.missionAllowTemp = true;
        let hivePos = null;
        if (this.site && this.site.siteLocation) {
          hivePos = this.site.siteLocation;
        }
        if (this.missionType == 2) {
          if (data.flightParams?.childrenList) {
            this.childrenList = data.flightParams?.childrenList;
            flightPath = data.flightParams.childrenList[this.childMissionIndex].flightPath;
          }
        } else {
          flightPath = data.flightParams?.flightPath;
          flightPath = flightPath ? flightPath : [];
        }
        this.flightPath = flightPath;
        console.log(flightPath)
        this.map.setMissionPath(flightPath, null, hivePos);
        this.map.fitMissionPath();
        let siteMode = this.site.siteMode;
        let hiveModel = null;
        let siteLocation = [];
        if (this.endSite) {
          siteLocation = this.endSite.siteLocation;
          if (this.endSite?.hiveInfo?.hiveModel) {
            hiveModel = this.endSite.hiveInfo.hiveModel;
          }
        } else {
          siteLocation = this.site.siteLocation;
          if (this.site?.hiveInfo?.hiveModel) {
            hiveModel = this.site.hiveInfo.hiveModel;
          }
        }
        if (this.site?.hiveInfo?.hiveModel) {
          hiveModel = this.site.hiveInfo.hiveModel;
        }
        this.map.addEndHiveMarker(siteMode, hiveModel, siteLocation[0], siteLocation[1], false);
        this.map.addEndHiveToMissionPath();
      } else {
        this.$message.error('请求任务失败:' + reason);
      }
    },
    /**
     * @description: 根据计划任务编号删除计划
     * @param {*} CMID
     * @return {*}
     * @author: 篆愁君
     */

    removeFloorItem(CMID) {
      deleteTimer(CMID)
        .then((res) => {
          if (statusInclude(res.code)) {
            this.$message({ type: 'success', message: '删除成功' });
          } else {
            this.$message({ type: 'error', message: res.reason });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    instrumentResize(){
       let size =parseInt( (this.$refs.map.$el.offsetWidth-40)/5);
       size = size<200?size:200;
       this.instrumentSize=size+'px';
    },
    mapRendered() {
      this.getSiteDetail(this.site.siteID);
      this.getMissionBySiteId(this.site.siteID);
      this.getSiteList();
      this.instrumentResize();
      this.heartBeating();
    },
    /**
     * @description: 计算无人机时长
     * @param {*} distance
     * @return {*}
     * @author: 篆愁君
     */
    distanceChange(distance) {
      if (!this.missionDetail || !this.missionDetail.flightParams) {
        return false;
      }
      let cruiseSpeed = 0,
        len = 0;

      if (this.flightPath) {
        len = this.flightPath.length;
      }
      if (this.missionDetail?.flightParams?.cruiseSpeed) {
        cruiseSpeed = this.missionDetail.flightParams.cruiseSpeed;
      }
      // 航线的长度加上起降站点的高度(首末航点与起降点高度差)
      let topStart = Math.abs(this.flightPath[0].altitude - this.site.siteAltitude);
      let endSite = this.endSite ? this.endSite : this.site;
      let topEnd = Math.abs(this.flightPath[this.flightPath.length - 1].altitude - endSite.siteAltitude);
      const time = (distance + topStart + topEnd) / cruiseSpeed + (len + 2) * 3 + 80 * 2;

      this.$store.commit('mission/SET_MISSION_DISTANCE', distance + topStart + topEnd);
      this.$store.commit('mission/SET_MiSSION_TIME', time);
    },

    clearUavPathAndMissionPath() {
      this.map.clearRealTimeTrails();
      this.map.clearMissionPath();
    },

    updateUavPos(longitude, latitude, azimuth = 0, gimbalYaw = 0, altitude = 0) {
      if (longitude == 0 || latitude == 0) {
        return;
      }
      this.records.push({
        lng: longitude,
        lat: latitude,
        alt: parseFloat(altitude),
      });

      this.map.setUavMarker('uav', longitude, latitude, azimuth);
      this.map.setGimbalMarker('gimbal', longitude, latitude, gimbalYaw);
      if (this.isLock) {
        this.map.panTo(longitude, latitude);
      }
    },

    removeUav() {
      this.map.removeUavMarkerById('uav');
      this.map.removeGimbalMarkerById('gimbal');
    },

    // 请求轨迹数据
    async getBatchinfo(missionBatch) {
      console.log('请求轨迹数据');
      this.flightPath = [];
      this.getBatchinfo_sending = true;
      this.records = [];
      let { data } = await getBatchinfo(missionBatch);
      if (data) {
        this.batchInfoGlobal = data;
        this.getBatchinfo_sending = false;
        // missionBatch 不存在时，清除航线
        this.missionAllowTemp = false;
        this.clearUavPathAndMissionPath();
      }
      if (data?.missioninfo) {
        let flightPath = [];
        this.waypointCount = flightPath.length;
        let hivePos = null;
        if (this.site && this.site.siteLocation) {
          hivePos = this.site.siteLocation;
        }
        if (data.missionType == 2) {
          let index = 0;
          if (data.missioninfo.childMissionIndex) {
            index = data.missioninfo.childMissionIndex;
          }
          flightPath = data.missioninfo.childrenList[index].flightPath;
          this.flightPath = flightPath;
          this.waypointCount = flightPath.length;
        } else {
          flightPath = data.missioninfo.flightPath;
          this.flightPath = flightPath;
          this.waypointCount = flightPath.length;
        }

        this.map.setMissionPath(flightPath, null, hivePos);
      }
      // 处理轨迹数据
      if (data?.flightRecords?.list) {
        let records = data.flightRecords.list;
        if (records.length > 1) {
          // 处理遗留报文，按照时间戳重新排序
          records = records.sort(function (a, b) {
            return a.timestamp - b.timestamp;
          });
          let records2 = [];
          // 处理遗留报文,去除经纬度为0的轨迹点
          records.forEach((record) => {
            if (record.longitude !== 0 && record.altitude !== 0) {
              this.records.push({
                lng: record.longitude,
                lat: record.latitude,
                alt: parseFloat(record.altitude),
              });
              records2.push(record);
            }
          });
          //根据waypointIndex拆分线
          if (records2.length > 1) {
            let startIndex = 0;
            let recordsTmp = [records2[startIndex]];
            for (let i = 1; i < records2.length; i++) {
              //与上一个轨迹点时间间隔大于5秒判定为存在丢失报文（waypointIndex过渡 可能是顺畅的时间+1，也可能是缺失了报文:>MILESECONDS_MISSING）
              if (this.flightPath.length >= records2[i].waypointIndex && records2[i].waypointIndex - records2[i - 1].waypointIndex > 0 && records2[i].timestamp - records2[i - 1].timestamp > MILESECONDS_MISSING) {
                // 此时开始拆线
                // 拆线前先把前面的线画整齐
                this.map.addRealTimePathByRecords(recordsTmp);
                //上次轨迹点和航线航点连线航点再和此航点连线
                let arr = [records2[startIndex]];
                for (let j = records2[i - 1].waypointIndex - 1; j <= records2[i].waypointIndex - 1; j++) {
                  arr.push({
                    longitude: this.flightPath[j].longitude,
                    latitude: this.flightPath[j].latitude,
                  });
                }
                arr.push(records2[i]);
                this.map.addRealTimePathByRecords(arr, true);
                recordsTmp = [];
                recordsTmp.push(records2[i]);
              } else {
                recordsTmp.push(records2[i]);
              }
              startIndex = i;
            }
            this.map.addRealTimePathByRecords(recordsTmp);
          }
        }
      }
      let siteMode = this.site.siteMode,
        hiveModel = null,
        siteLocation = this.site.siteLocation,
        hide = true;
      if (this.site?.hiveInfo?.hiveModel) {
        hiveModel = this.site.hiveInfo.hiveModel;
      }
      // flightMode 1:孤岛模式，2：网联模式   && data.flightMode == 2
      if (data) {
        if (data.endSiteID && data.endSiteID != this.site.siteID) {
          this.getEndSiteDetail(data.endSiteID);
        } else {
          // 降落至起始机库
          this.map.addEndHiveMarker(siteMode, hiveModel, siteLocation[0], siteLocation[1], hide);
          this.map.addEndHiveToMissionPath();
        }
      } else {
        // 降落至起始机库
        this.map.addEndHiveMarker(siteMode, hiveModel, siteLocation[0], siteLocation[1], hide);
        this.map.addEndHiveToMissionPath();
      }
    },

    gimbalReset() {
      this.gimbalSetting(this.droneInfomation.UAVID, 403, '');
    },
    /**
     * @description: 无人机平移操作(前后左右)
     * @param {Number} e
     * @return {*}
     */
    uTranslation(e) {
      this.locationSetting(this.droneInfomation.UAVID, e, this.offset);
    },
    inputTVal(v) {
      this.offset = v;
    },

    inputAVal(v) {
      // if (v < 5) {
      //   this.altitudeOffset = 5;
      // } else if (v > 20) {
      //   this.altitudeOffset = 20;
      // } else {
      //   this.altitudeOffset = v;
      // }

      this.altitudeOffset = v;
    },

    // 无人机上下
    uTransLift(e) {
      var param;
      if (e === 'down') {
        e = 201;
        param = -this.altitudeOffset;
      } else if (e === 'up') {
        e = 201;
        param = +this.altitudeOffset;
      }
      this.locationSetting(this.droneInfomation.UAVID, e, param);
    },

    /**
     * @description: 切换实时数据显示
     * @param {*} value
     * @return {*}
     */
    toggleRealTimeInformation(value) {
      this.msgArea = value;
    },
    translationMessageArea(value) {
      const t = {
        1: 'site',
        4: 'endSite',
      };
      if (value === 1 || value === 4) {
        return { flag: true, key: t[value] };
      } else {
        return { flag: false };
      }
    },

    /**
     * @description: toolbar 功能
     * @param {*}
     * @return {*}
     */
    handleToolFunContorl(id) {
      switch (id) {
        case 503:
          this.emergencyStop();
          break;
        case 504:
          this.setDroneLock();
          break;
        case 507: //拍摄照片
          this.cameraSetting(this.droneInfomation.UAVID, UAV_ACTION.CAMERA.snap);
          break;
        case 508: //录像控制
          this.recordVideo();
          break;
        case 509:
          this.isOpen = !this.isOpen;
        case 510:
          // this.isBoxing = !this.isBoxing;
          break;
        case 1111:
          this.getMissionShareID();
          break;
      }
    },
    /**
     * @description: 指点变焦
     * @param {*}
     * @return {*}
     * @author: 篆愁君
     */
    submitParamsHandler(coorLoc) {
      let uid = this.droneInfomation?.UAVID;
      uid = uid || this.site?.UAVInfo?.UAVID;
      // 重新计算去除黑边的坐标
      //   coorLoc = this.coorReduceBlackSides(coorLoc);
      if (!uid) {
        this.$message.error('缺失无人机UAVID');
        return false;
      }
      if (this.isTargetBoxing) {
        this.cameraFollwByCoors(uid, coorLoc); // coorLocArr
      } else if (this.isZoomBoxing) {
        this.cameraZoomByCoors(uid, coorLoc);
      } else {
        console.error(coorLoc);
      }
    },

    cameraAutoHandler(coorLoc) {
      let uid = this.droneInfomation?.UAVID;
      uid = uid || this.site?.UAVInfo?.UAVID;
      if (!uid) {
        this.$message.error('缺失无人机UAVID');
        return false;
      }
      this.cameraAutoByPoint(uid, coorLoc);
    },

    // 机库开合
    async openHive(t) {
      if (!this.site?.hiveInfo?.hiveID) {
        return this.$message.error('机库信息未知！');
      }
      if (this.droneInfomation.subState !== UAV_STATE.kediaofei && this.droneInfomation.subState !== UAV_STATE.daiming) {
        return this.$message.error('无人机当前状态禁止操作！');
      }
      const { code, reason } = await openHive(this.site.hiveInfo.hiveID, t);
      if (statusInclude(code)) {
        this.$message('操作成功！');
      } else {
        this.$message.error(reason);
      }
    },

    /**
     * @description: 视角切换
     * @param {obj.show} boolean ,是否显示
     * @param {obj.index} number   0：'第一视角'，1: '第三视角', 2: '内窥视角',3: '识别模块',
     * @return void
     * @author: 篆愁君
     */
    async changeView(obj) {
      if (obj.index === 4) {
        // ai识别模块
        if (obj.show) {
          const type = this.$refs.toolBar.initdistinguishModel();
          const mode = this.$refs.toolBar.handleSelectMode(type, true);
          // UPDATE 当前版本算法不支持任务中切换算法类型，所以弃用
          // this.getAIVideoStream(mode); // 默认开启第一项
          if (mode) {
            this.getAIVideoStream(mode); // 默认开启第一项
            this.ANGLESRC.AIMODE = true;
          }
        } else {
          this.MeetTheClose && this.unEnableAIStream(this.missionBatch);
          this.ANGLESRC.AIMODE = false;
          //关闭识别模块
          this.ANGLESRC.AISTREAM = null;
          this.AIMODE = false;
        }
      } else if (obj.index === 0 && obj.show) {
        // 开启第一视角
        let res = await this.getSiteStreams(this.site.siteID);
      }
      this.$set(this.players, obj.index, obj.show);
    },

    /**
     * @description: 提交关联任务 任务信息渲染到地图
     * @param {*} param
     * @return {*}
     * @author: 篆愁君
     */
    submitForm(param) {
      this.sendMissionParam = param;
      this.isExamine = true;
    },

    /**
     * @description: 切换终点站点
     * @param {*} siteId
     * @return {*}
     */
    async handleChangeEndSiteID(siteId) {
      this.endSiteID = siteId;
      if (siteId === undefined || siteId === '') {
        this.endSite = null;
        //取关终点站点实时数据
        return this.$message({ type: 'info', message: '切换至单库飞行' });
      } else {
        //请求终点站点信息
        await this.getEndSiteDetail(siteId);
      }
    },

    /**
     * @description: 立即返航，且选择返航点，关闭选择框时取消返航 1.返回起点 2.返回备降点
     * @param {*}
     * @return {*}
     */
    courseReversal() {
      const { missionBatch } = this.droneInfomation;
    },
    /**
     * @description: 拼接半自动任务参数
     * @param {*} accessKeyId
     * @param {*} siteID
     * @param {*} actionId
     * @param {*} missionBatch
     * @return {*}
     */
    combinatorialInformation(accessKeyId, siteID, actionId, missionBatch) {
      return {
        msgFrom: accessKeyId,
        msgTo: siteID,
        actionID: actionId,
        time: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
        missionBatch: missionBatch,
      };
    },
    /**
     * @description:
     * @param {String} id
     * @param {Number} type
     * @return {*}
     */
    async missionSetting(id, type, item) {
      let msg = true;
      if (item) {
        if (item.value === UAV_ACTION.MISSION.pause || item.value === UAV_ACTION.MISSION.stop) {
          item.disabled = true;
          this.deBounce2Seconds(() => {
            item.disabled = false;
          });
        }
        if (item.value === UAV_ACTION.MISSION.recover) {
          msg = false;
          this.$message({ type: 'warning', message: '航线任务正在恢复中，请勿重复操作!' });
          item.disabled = true;
          setTimeout(() => {
            item.disabled = false;
          }, 10000);
        }
      }
      const { code, reason } = await missionSetting(id, type);
      if (statusInclude(code)) {
        this.$message({ type: 'success', message: '操作成功' });
      } else {
        if (item) {
          item.disabled = false;
        }

        this.$message({ type: 'warning', message: reason });
      }
    },

    async gimbalSetting(id, type, value) {
      const { code, reason } = await gimbalSetting(id, type, value);
      if (statusInclude(code)) {
        //  this.$message({ type: 'success', message: '操作成功' });
      } else {
        this.$message({ type: 'warning', message: reason });
      }
    },
    async cameraSetting(id, type, value, coorX, coorY, PZoom) {
      const { code, reason } = await cameraSetting(id, type, value, coorX, coorY, PZoom);
      if (statusInclude(code)) {
        this.$message({ type: 'success', message: '操作成功' });
      } else {
        this.$message({ type: 'warning', message: reason });
      }
    },

    // 框选矩形跟踪
    async cameraFollwByCoors(id, coorLoc) {
      const { code, reason } = await cameraFollwByCoors(id, coorLoc);
      if (statusInclude(code)) {
        //  this.$message({ type: 'success', message: '操作成功' });
      } else {
        this.$message({ type: 'warning', message: reason });
      }
    },

    async cameraZoomByCoors(id, coorLoc) {
      const { code, reason } = await cameraZoomByCoors(id, coorLoc);
      if (statusInclude(code)) {
        //  this.$message({ type: 'success', message: '操作成功' });
      } else {
        this.$message({ type: 'warning', message: reason });
      }
    },
    async cameraAutoByPoint(id, coorLoc) {
      const { code, reason } = await cameraAutoByPoint(id, coorLoc);
      if (!statusInclude(code)) {
        this.$message({ type: 'warning', message: reason });
      }
    },
    //开启激光测距
    async startLaserGuage(id, start) {
      this.laserDistanceAble = false;
      const { code, reason } = await startLaserGuage(id, start);
      if (statusInclude(code)) {
        let msg = '已开启激光测距';
        if (!start) {
          msg = '已关闭激光测距';
        }
        this.$message({ type: 'success', message: msg });
      } else {
        this.$message({ type: 'error', message: reason });
      }
      this.laserDistanceAble = true;
    },
    //开启或关闭目标追踪
    async startCameraTrack(id, track) {
      const { code, reason } = await startCameraTrack(id, track);
      if (statusInclude(code)) {
        let msg = '已开启智能跟踪模式';
        if (!track) {
          msg = '已关闭智能跟踪模式';
        }
        this.$message({ type: 'success', message: msg });
      } else {
        this.$message({ type: 'error', message: reason });
      }
    },

    modulationYawAngle(v) {
      this.locationSetting(this.droneInfomation.UAVID, UAV_ACTION.POS.head, parseInt(v));
    },
    modulationToward(v) {
      this.gimbalSetting(this.droneInfomation.UAVID, UAV_ACTION.GIMBAL.head, parseInt(v));
    },
    modulationPitch(v) {
      this.gimbalSetting(this.droneInfomation.UAVID, UAV_ACTION.GIMBAL.pitch, parseInt(v));
    },
    modulationZoom(v) {
      this.cameraSetting(this.droneInfomation.UAVID, UAV_ACTION.CAMERA.zoom, parseInt(v));
    },

    async submitMissionData() {
      const { code, reason } = await mediaSetting(this.droneInfomation.UAVID, UAV_ACTION.MEDIA.upload);
      if (statusInclude(code)) {
        this.$message({ type: 'success', message: '操作成功！' });
      } else {
        this.$message({ type: 'warning', message: reason });
      }
    },

    /**
     * @description: 设置无人机跟随
     * @param {*}
     * @return {*}
     */
    setDroneLock() {
      if (this.$refs.toolBar.tools.viewFixed.className) {
        this.$refs.toolBar.tools.viewFixed.className = '';
        this.isLock = false;
      } else {
        this.$refs.toolBar.tools.viewFixed.className = 'is-action';
        this.isLock = true;
      }
    },

    /**
     * @description: 视频录制
     * @param {*}
     * @return {*}
     */
    recordVideo() {
      this.droneRecording ? this.cameraSetting(this.droneInfomation.UAVID, UAV_ACTION.CAMERA.videoStop) : this.cameraSetting(this.droneInfomation.UAVID, UAV_ACTION.CAMERA.video);
    },

    /**
     * @description: 无人机急停指令
     * @param {*}
     * @return {*}
     */
    emergencyStop() {
      this.$confirm('任务急停,是否继续?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        .then(() => {
          this.missionSetting(this.droneInfomation.UAVID, UAV_ACTION.MISSION.urgencyStop);
        })
        .catch(() => {
          this.$message({ type: 'info', message: '取消急停' });
        });
    },

    /**
     * @description: 无人机返航指令
     * @param {*}
     * @return {*}
     */
    droneReturn() {
      const { missionBatch, UAVID: id } = this.droneInfomation;
      this.combinatorialInformation(missionBatch);
      this.$confirm('将执行返航,请选择返航方式', '提示', { distinguishCancelAndClose: true, confirmButtonText: '起飞点', cancelButtonText: '备降点', type: 'warning' })
        .then(() => {
          this.missionSetting(id, UAV_ACTION.MISSION.backtoStart);
        })
        .catch((action) => {
          action !== 'close' && this.missionSetting(id, UAV_ACTION.MISSION.backtoAlternate);
        });
    },
    /**
     * @description: 发送任务
     * @param {*} isReady
     * @return {*}
     * @author: 篆愁君
     */
    handelExamine(isReady) {
      if (isReady) {
        if (this.sendMissioning) {
          return;
        }
        this.sendMissioning = true;
        const { Id, action, isVideo, flightWay, finishAction, endSiteID } = this.sendMissionParam;
        let UAVID = this.droneInfomation.UAVID;
        const { siteName } = this.site;
        if (!UAVID) {
          this.$message({ type: 'error', message: '缺失无人机编号！' });
          return;
        }
        if (!this.missionDetail) {
          this.$message({ type: 'error', message: '缺失无人机编号！' });
          return;
        }
        const { siteID, missionName } = this.missionDetail;
        const automaticMission = {
          siteID,
          siteName,
          isVideo,
          flightWay,
          missionID: Id,
          missionName,
          zoomDefault: 4,
          UAVID,
          finishAction: finishAction,
        };
        if (endSiteID) {
          automaticMission.endSiteID = endSiteID;
        } else {
          automaticMission.endSiteID = this.site.siteID;
        }

        if (this.site.siteSFMode == 1) {
          // 孤岛模式
          delete automaticMission.endSiteID;
        }
        this.zoomDefault && (automaticMission.zoomDefault = this.zoomDefault);
        sendMission(automaticMission).then(({ code, reason }) => {
          this.sendMissioning = false;
          if (statusInclude(code)) {
            this.$message({ type: 'success', message: '任务已发送' });
            this.isExamine = false;
          } else {
            this.$message({ type: 'error', message: reason });
          }
        });
      } else {
        this.isExamine = false;
        this.$message({ type: 'success', message: '取消发送任务' });
      }
    },
    /**
     * @description: sokcet 实时指令发送
     * @param {*}
     * @return {*}
     */
    handleDroneViaSocket(params) {
      this.socketInstance.emit('uav semi-mission', params); //提交指令
      this.socketInstance.on('uav semi-mission callback', (data) => {
        //监听socket响应信息
        const { reason } = data;
        if (reason === '发送成功') {
          this.$message({ type: 'success', message: '指令发送成功' });
        } else {
          this.$message({ type: 'warning', message: '指令发送失败' });
        }
        this.socketInstance.removeEventListener('uav semi-mission callback'); //断开此次消息响应监听
      });
    },

    // 喊话器开始或停止
    async sendTextForVoice(start) {
      if (!this.droneInfomation.UAVID) {
        return this.$message.error('缺失无人机ID！');
      }
      let param = {
        UAVID: this.droneInfomation.UAVID,
        type: UAV_ACTION.MEGOPHONE.pause,
      };
      if (start) {
        if (!this.megophone.text.trim()) {
          this.$message.error('请输入语音文本！');
          return;
        }
        param.type = UAV_ACTION.MEGOPHONE.play;
        param.tranMode = 1;
        param.voiceParams = {
          voicetts: this.megophone.text,
          voicecyclic: this.megophone.times,
          voicevolume: this.megophone.volume,
        };
      } else {
        param.isStopVoiceCyclic = true;
      }
      const { code, data, reason } = await megophonesetting(param);
      if (statusInclude(code)) {
        this.$message({ type: 'success', message: '指令发送成功' });
      } else {
        this.$message.error('指令发送失败：' + reason);
      }
    },

    // 设置音量

    async setVolume(v) {
      let param = {
        UAVID: this.droneInfomation.UAVID,
        type: UAV_ACTION.MEGOPHONE.volume,
        voicevolume: v,
      };
      const { code, data, reason } = await megophonesetting(param);
    },

    /**
     * @description: 心跳检测
     * @param {*}
     * @return {*}
     */
    heartBeating() {
      setInterval(() => {
        let time = new Date().getTime();
        if (this.droneInfomation.update < time) {
          this.droneInfomation.subState = 0;
          this.droneInfomation.isOSDKAvailable = 0;
          this.droneInfomation.isUAVOnline = 0;
          this.droneInfomation.isGSOnline = 0;
        }
        if (this.hiveInformation.update < time) {
          this.hiveInformation.flowState = 0;
        }
      }, 10000);
    },
    /**
     * @description: 无人机位置控制 偏移为相对控制   高度为绝对控制
     * @param {*} id
     * @param {*} type
     * @param {*} param
     * @return {*}
     * @author: 篆愁君
     */
    async locationSetting(id, type, offset) {
      const { code, reason } = await locationSetting(id, type, offset);
      if (statusInclude(code)) {
        //  this.$message({ type: 'success', message: '操作成功' });
      } else {
        this.$message({ type: 'warning', message: reason });
      }
    },

    // 通过ws发送无人机位置控制  ws 实际范围-100-100 ，前端参数乘以10
    locationSetByWS(UAVID, type, offset) {
      let timestamp = this.$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
      let params = {
        msgId: UAVID + timestamp,
        msgType: 'uav_manual_code',
        UAVID,
        siteFMode: this.site.siteSFMode,
        timestamp,
        joystick: { vertical: 0, pitch: 0, roll: 0, yaw: 0 },
      };
      let matchedKey = true;
      switch (type) {
        //=== 无人机 指令集
        case UAV_ACTION.POS.forward: // 87 W:前
          params.joystick.pitch = offset;
          break;
        case UAV_ACTION.POS.backward: // 83 S:后
          params.joystick.pitch = offset;
          break;
        case UAV_ACTION.POS.left: // 65 向左，68 向右
          params.joystick.roll = offset;
          break;
        case UAV_ACTION.POS.right: //
          params.joystick.roll = offset;
          break;
        case UAV_ACTION.POS.head: //  81 左转,右转
          console.log('toadd' + this.toAdd, offset);
          if (this.toAdd) {
            params.joystick.yaw = offset;
          } else {
            params.joystick.yaw = offset;
          }
          break;
        case UAV_ACTION.POS.height: //  :向上,向下
          if (this.toAdd) {
            params.joystick.vertical = offset;
          } else {
            params.joystick.vertical = offset;
          }
          break;
        // 无人机 指令集 ===
        default:
          matchedKey = false;
      }
      if (matchedKey) {
        this.socketInstance.emit('uav manual-mission', params); //提交指令
      }
    },

    /**
     * @description: 加载实时数据
     * @param id 站点ID
     * @return {*}
     */
    onInformationBySocket(id) {
      this.socketInstance.on(id + ' state', (data) => {
        // if (data.type === 1) {
        //   console.log(data);
        // }

        var { tempData, type } = parsingInfomation(data);
        if (type === 1) {
          // 不处理历史遗留报文报文

          if (this.wsMsgTmpData && this.wsMsgTmpData.length > 0) {
            let datePre = this.getMileSeconds(this.wsMsgTmpData[this.wsMsgTmpData.length - 1].timestamp),
              dateNow = this.getMileSeconds(data.timestamp);
            if (datePre > dateNow) {
              // console.log(this.wsMsgTmpData[this.wsMsgTmpData.length - 1].timestamp, data.timestamp);
              return;
            }
          }
          this.wsMsgTmpData.push(tempData);
          if (this.wsMsgTmpData.length > this.wsMsgTmpDataCount) {
            this.wsMsgTmpData.splice(0, this.wsMsgTmpData.length - this.wsMsgTmpDataCount);
          }
          const { waypointIndex, missionBatch, longitudeWGS, latitudeWGS, isUAVOnline, azimuth, gimbalYaw, zoom, gimbalPitch, subState, isRecording, timestamp, altitude, laserTargetLocation } = tempData;
          this.missionBatch = missionBatch;
          this.waypointIndex = waypointIndex;
          this.droneZoom = zoom;
          this.droneGimbalPitch = gimbalPitch;
          this.droneSubState = subState;
          this.droneRecording = isRecording;
          if (!JOYSTICK_ALLOW_TO_SEND.includes(subState)) {
            this.clearStickIntervals();
            this.stopHotKeyInterval();
          }
          // 针对单机 当无人机上线后是否更新单机位置
          if (this.site && !this.isSiteUpdated && STAND_SITES.includes(this.site.siteMode) && isUAVOnline && longitudeWGS && longitudeWGS && (subState === UAV_STATE.daiming || subState === UAV_STATE.daiming2 || subState === UAV_STATE.chongdiankezhifei)) {
            // 更新位置
            if (!this.site.siteLocation || this.site.siteLocation.length === 0 || (this.site.siteLocation.length === 2 && (this.site.siteLocation[0] !== longitudeWGS || this.site.siteLocation[1] !== latitudeWGS))) {
              this.site.siteLocation = [longitudeWGS, latitudeWGS];
              let hiveModel = null;
              if (this.site?.hiveInfo?.hiveModel) {
                hiveModel = this.site.hiveInfo.hiveModel;
              }
              this.map.addHiveMarker(this.site.siteMode, hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], true);
              this.isSiteUpdated = true;
              this.updateSiteDetail(this.site);
            }
          }

          //大疆错误信息处理
          if (!UAV_STATE_IN_HIVE.includes(subState) && data?.UAVError?.djiError?.length > 0) {
            const { reason } = data.UAVError.djiError[0];
            if (reason) {
              if (this.DJIERROR_MSG === reason && this.DJIERROR_MSG_LIST.length > 0) {
                this.DJIERROR_MSG_LIST[this.DJIERROR_MSG_LIST.length - 1].timestamp = timestamp;
              } else {
                this.DJIERROR_MSG = reason;
                this.DJIERROR_MSG_LIST.unshift({ reason, timestamp });
              }
            }
          }
          if (!missionBatch) {
            if (!this.missionAllowTemp) {
              this.flightPath = [];
              this.endSite = null;
              this.endSiteID = null;
            }
            this.removeUav();
            this.map.clearRealTimeTrails();
            this.isUavLaserDistance = false;
            this.laserDistanceAble = false;
            if (this.is3DMode) {
              this.$refs.cesiumMap.clearFlightData();
            }
            if (!this.missionAllowTemp) {
              this.map.clearMissionPath();
              this.map.removeEndHiver();
              this.map.removeDashPath();
            } else {
              //  console.error('====================== 未执行clear mission path =====================');
            }
          } else {
            this.laserDistanceAble = true;
            if (!this.missionAllowTemp && this.flightPath && this.flightPath.length === 0 && !this.getBatchinfo_sending) {
              // 查询任务和轨迹
              this.getBatchinfo(missionBatch);
            }
            // 激光测距异常显示->是否‘关闭激光测距’ 弹窗 0:mormal,1:too close,2:too far,3:no signal,4:unkwon
            //tempData.laserError !== 0   before
            if (tempData.isLaserEnabled && (tempData.laserError === 3 || tempData.laserError === 4) && !this.laserDialogVisible) {
              this.laserDialogVisible = true;
            }
            // 激光测距,显示逻辑：开启了激光测距，laserError为0，有位置点，小于1.2km
            if (tempData.isLaserEnabled && tempData.laserError === 0 && tempData?.laserTargetLocation) {
              const obj = {
                laserTargetLocation: this.wsMsgTmpData[0].laserTargetLocation,
                seconds: 2,
              };
              this.laserTargetLocation = laserTargetLocation;
              this.map.updateLaserPoint(laserTargetLocation, obj);
            } else {
              this.laserTargetLocation = [];
              this.map.updateLaserPoint(null);
            }
            this.updateUavPos(longitudeWGS, latitudeWGS, azimuth, gimbalYaw, altitude);
            // 判断是否丢失了报文 进行与航点的连线
            if (this.wsMsgTmpData.length > 0) {
              let lastData = this.wsMsgTmpData[this.wsMsgTmpData.length - 1];
              let ptMissedCount = waypointIndex - lastData.waypointIndex;
              let timeStampGap = this.getMileSeconds(timestamp) - this.getMileSeconds(lastData.timestamp);

              // 需要补点,上一个点与航线航点连线
              // waypointIndex不一致，报文时间戳大于1秒 判定为存在此点前丢失报文的情况，若waypointIndex比上一个大则补点（上一个轨迹点和上个waypointIndex，直到当前waypointIndex，与当前点）
              if (ptMissedCount > 0 && timeStampGap > MILESECONDS_MISSING && this.flightPath.length >= waypointIndex) {
                let arr = [{ longitude: lastData.longitudeWGS, latitude: lastData.latitudeWGS }]; //
                // 时间间隔大于5秒且waypointIndex变化了
                for (let i = lastData.waypointIndex; i <= waypointIndex; i++) {
                  console.warn('补充航点：' + (i - 1));
                  arr.push({
                    longitude: this.flightPath[i - 1].longitude,
                    latitude: this.flightPath[i - 1].latitude,
                  });
                }
                arr.push({
                  longitude: longitudeWGS,
                  latitude: latitudeWGS,
                });
                this.map.addRealTimePathByRecords(arr, true);
              }
            }
            if (longitudeWGS !== 0 && latitudeWGS !== 0) {
              this.map.updateLastRealTimePathByLngLat(longitudeWGS, latitudeWGS);
            }
          }
          this.droneInfomation = tempData;
        } else if (type === 2) {
          //机库信息 2
          let error = true;
          if (this.site && this.site.siteID && data.STID == this.site.siteID) {
            this.hiveInformation = tempData;
            error = false;
          }
          if (this.endSiteID && data.STID == this.endSiteID) {
            this.endHiveInformartion = tempData;
            error = false;
          }
          if (error) {
            console.log('其他机库信息：');
            console.log(data);
          }
        } else if (type === 3 && this.$refs.firstViewFVideo) {
          // 目标追踪
          console.log('目标追踪');
          let { coorLoc, STID, callback, timestamp } = tempData;
          let topLeft = coorLoc[0];
          bottomRight = coorLoc[2];
          if (callback == 'canceltrack') {
            this.$refs.firstViewFVideo.setRecognitionBox();
          } else {
            this.$refs.firstViewFVideo.setRecognitionBox(topLeft, bottomRight);
          }
        } else {
          console.log('未知类型的报文');
          console.log(data);
        }
      });
    },

    async getMissionBySiteId(stid) {
      const { code, data } = await getMissionBySiteId(stid);
      this.missionList = data?.list || [];
    },

    /**
     * @description: 获取站点详情
     * @param {*} siteID
     * @return {*}
     * @author: 篆愁君
     */
    async getSiteDetail(siteID) {
      let { code, data, reason } = await getSiteDetail(siteID);
      console.log(data);
      if (!statusInclude(code)) return this.$message.error(reason);

      let { UAVInfo, siteFLVURL, hiveInfo } = data;
      UAVInfo || (UAVInfo = {});
      hiveInfo || (hiveInfo = {});
      this.site = data;
      this.UAVInfo = UAVInfo;
      if (this.site.siteMode != SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
        this.$refs.controlsBar.hideHiveLabel();
        this.$refs.controlsBar.hideScheduleLabel();
      }
      let UAVFLVURL = null,
        UAVRTCURL = null,
        hiveFLVURL = null,
        hiveRBTFLVURL = null;
      // if (this.site?.UAVInfo?.model && this.site.UAVInfo.model === 'M300') {
      //   this.gimbalPitchMax = 180;
      //   this.gimbalPitchMin = 0;
      // }
      if (this.site?.UAVInfo?.UAVFLVURL) {
        UAVFLVURL = this.site.UAVInfo.UAVFLVURL;
      }
      if (this.site?.UAVInfo?.UAVRTCURL) {
        UAVRTCURL = this.site.UAVInfo.UAVRTCURL;
      }
      if (this.site?.hiveInfo) {
        if (this.site?.hiveInfo?.hiveFLVURL) {
          hiveFLVURL = this.site.hiveInfo.hiveFLVURL;
        }
        if (this.site?.hiveInfo?.hiveRBTFLVURL) {
          hiveRBTFLVURL = this.site.hiveInfo.hiveRBTFLVURL;
        }
      } else {
        this.site.hiveInfo = { hiveModel: '' };
      }
      this.ANGLESRC.FIRST = UAVFLVURL;
      this.ANGLESRC.UAVRTCURL = UAVRTCURL;
      this.ANGLESRC.SECOND = this.site.siteFLVURL;
      this.ANGLESRC.INTERNAL = hiveFLVURL;
      this.ANGLESRC.RBT = hiveRBTFLVURL;

      let siteMode = this.site.siteMode;
      let hiveModel = null;
      if (this.site?.hiveInfo?.hiveModel) {
        hiveModel = this.site.hiveInfo.hiveModel;
      }
      if (this.site?.siteLocation) {
        this.map.addHiveMarker(siteMode, hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], true);
      }

      // siteMode 站点模式 ，站点支持的飞行模式 siteSFMode
      //siteMode: 1-固定机库+射频箱 2-射频箱 3-单兵中枢 4-移动机库 5-无人机遥控器 6-天枢
      //只有1,4的情况： 机库检查，风速
      this.socketInstance = socket.initSocket(); //初始化socket
      this.onInformationBySocket(this.site.siteID); //开启A站socket连接
    },

    async getSiteStreams(siteID, keepUavFlv) {
      this.ANGLESRC.FIRST = null;
      this.ANGLESRC.UAVRTCURL = null;
      this.ANGLESRC.SECOND = null;
      this.ANGLESRC.INTERNAL = null;
      let { code, data, reason } = await getSiteDetail(siteID);
      if (!statusInclude(code)) return this.$message.error(reason);
      let UAVFLVURL = null,
        UAVRTCURL = null,
        hiveFLVURL = null;
      if (data?.UAVInfo?.UAVFLVURL) {
        UAVFLVURL = data.UAVInfo.UAVFLVURL;
      }
      if (data?.UAVInfo?.UAVRTCURL) {
        UAVRTCURL = data.UAVInfo.UAVRTCURL;
      }
      if (data?.hiveInfo) {
        if (data?.hiveInfo?.hiveFLVURL) {
          hiveFLVURL = data.hiveInfo.hiveFLVURL;
        }
      } else {
        data.hiveInfo = { hiveModel: '' };
      }
      if (!keepUavFlv) {
        this.ANGLESRC.FIRST = UAVFLVURL;
      }
      this.ANGLESRC.UAVRTCURL = UAVRTCURL;
      this.ANGLESRC.SECOND = data.siteFLVURL;
      this.ANGLESRC.INTERNAL = hiveFLVURL;
    },

    /**
     * @description: 请求终点站点信息
     * @param {*} siteID
     * @return {*}
     */

    async getEndSiteDetail(siteID) {
      this.endSiteID = siteID;
      let { code, data, reason } = await getSiteDetail(siteID);
      if (!statusInclude(code)) return this.$message.error(reason);
      this.endSite = data;
      console.error(this.endSite);

      // this.endSiteID = data.siteID;
    },

    /**
     * @description: 请求站点列表
     * @param {*} page
     * @param {*} limit
     * @return {*}
     */
    async getSiteList({ page = 1, limit = 100 } = {}) {
      const { code, data } = await getSiteGroup(page, limit);
      if (statusInclude(code)) {
        this.siteList = data.list.filter((site) => site.siteID !== this.site.siteID);
      }
    },

    // 获取AI类型
    async getAIType() {
      const { code, reason, data } = await getAIType();
      if (!statusInclude(code)) this.$message.error('获取AI信息错误：' + reason);

      data.forEach((item) => {
        // console.log(item);
        this.aiModels.push(item);
      });
    },

    /**
     * @Author: 篆愁君
     * @Date: 2022-10-08 12:45:02
     * @description: 获取AI流地址
     * @param {*} dealType
     * @return {*}
     * UPDATE 新增taskSiteMode 字段
     */
    async getAIVideoStream(dealType) {
      let url = this.site?.UAVInfo?.UAVRTMPURL ? this.site.UAVInfo.UAVRTMPURL : null;
      if (!url) return this.$message.error('暂无无人机第一视角rtmp视频流！');
      const { sence, model, extra_type } = dealType;
      const params = {
        inputUrl: url, //输入流地址
        missionBatch: this.missionBatch || '', //A071663567452361948564
        extra_type_list: [extra_type],
        model_list: [model],
        sence,
        stId: this.site.siteID,
        taskSiteMode: TASKSITEMODE[this.site.siteMode],
      };

      const { code, reason, data } = await getAIVideoStream(params);
      if (statusInclude(code)) {
        const { outputUrlRtmp = null } = data;
        // console.log(outputUrlRtmp);
        setStreamInfo(outputUrlRtmp); //缓存ai返回的处理流地址并且播放
        const NoProtocolUrl = outputUrlRtmp.slice(4); //处理流地址
        this.$message({ type: 'success', message: '正在连接资源' });
        /* 延迟播放流 */
        sleep(3000).then(() => {
          this.ANGLESRC.AISTREAM = `artc${NoProtocolUrl}`;
        });
      } else {
        this.ANGLESRC.AISTREAM = null;
        this.AIMODE = false;
        this.$message.error(reason);
      }
    },

    /**
     * @description: 切换AI模型
     * @param {*} module
     * @return {*}
     * @author: 篆愁君
     */
    aiModuleChange(module) {
      this.ANGLESRC.AIMODE = true;
      this.getAIVideoStream(module);
    },

    /**
     * @Author: 篆愁君
     * @Date: 2022-10-23 15:51:36
     * @description: 关闭AI识别模块
     * @return {*}
     */

    async unEnableAIStream(missionBatch) {
      return ;
      try {
        const response = await unEnableAIStream(missionBatch);
        if (!statusInclude(response.code)) return this.$message.error(`关闭失败${response.reacon}`);
        return this.$message.error(`AI模块已关闭`);
      } catch (err) {
        this.$message.error(`未知错误，请联系管理员`);
        throw new Error(err);
      }
    },

    // 清空摇杆的定时器
    clearStickIntervals() {
      if (this.stickGimbalPitchInterVal) {
        clearInterval(this.stickGimbalPitchInterVal);
      }
      this.stickGimbalPitchInterVal = null;
      if (this.stickGimbalHeadInterVal) {
        clearInterval(this.stickGimbalHeadInterVal);
      }
      this.stickGimbalHeadInterVal = null;

      if (this.stickUavInterVal) {
        clearInterval(this.stickUavInterVal);
      }
      this.stickUavInterVal = null;
      if (this.stickGimbalHeadInterVal) {
        clearInterval(this.stickGimbalHeadInterVal);
      }
      this.stickGimbalHeadInterVal = null;
      if (this.heartBeatCheckInterVal) {
        clearInterval(this.heartBeatCheckInterVal);
      }
      this.heartBeatCheckInterVal = null;
      if (this.uavPos_manualIterval) {
        clearInterval(this.uavPos_manualIterval);
      }
      this.uavPos_manualIterval = null;
      if (keyDownCheckInterVal) {
        clearInterval(this.keyDownCheckInterVal);
      }
      keyDownCheckInterVal = null;
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWinSize);
    window.removeEventListener('keyup', this.handleKeyUps);
    window.removeEventListener('keydown', this.handleKeyDowns);
    document.removeEventListener('visibilitychange', this.visibilitychangeHandler);
    document.onkeydown = null;
    this.stopHotKeyInterval();
    this.clearStickIntervals();

    //断开socket连接
    if (this.socketInstance && this.socketInstance !== null) {
      socket.clearSocket();
      this.socketInstance = null;
    }

    // 关闭页面时关闭AI
    // this.MeetTheClose && unEnableAIStream(this.missionBatch);
  },
};
</script>

<style lang="scss" scoped>
.bigger-qr {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10000;
  > div {
    width: 600px;
    margin: auto;
    position: relative;
    > i {
      position: absolute;
      right: -50px;
      top: -35px;
      font-size: 36px;
      font-weight: 600;
      cursor: pointer;
    }
    > img {
      width: 600px;
    }
  }
}
.share-content {
  display: flex;
  flex-direction: row;
  margin-top: -30px;
  > div {
    display: flex;
    flex-direction: column;
    height: 150px;
    margin: auto;
    .copy {
      width: 100px;
      height: 30px;
      padding: 0;
    }
    > div {
      margin: auto;
      > img {
        cursor: pointer;
      }
    }
    > .qr {
      width: 100px;
      margin: auto;
    }
  }
}
.handle-content-wrap {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  background: #eee;
  position: relative;
  display: flex;
  flex-direction: column;

  .container-wrapper {
    width: 100%;
    min-width: 1270px;
    height: calc(100vh - 60px);
    height: -moz-calc(100vh - 60px);
    height: -webkit-calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    overflow-x: hidden;

    .according-wrapper {
      width: 280px;
      height: 100%;
      padding: 10px;
      border-top: 1px solid #{$borderSolid};
      background: #{$containerBg};
    }

    .left-information-display {
      width: 400px;
    }

    .container-content-wrapper {
      user-select: none;
      z-index: 99;
      flex: 1;
      position: relative;

      .ai-tool-select-area {
        width: 770px;
        height: 54px;
        position: absolute;
        left: -780px;
        z-index: 999;
        border-radius: 4px;
        background-color: #00000040;

        .el-radio-group {
          line-height: 60px;
          margin-left: 10px;
          color: #fff;

          .el-radio {
            .el-radio__label {
              font-weight: 600;
              color: #000;
            }
          }
        }
      }

      .instriment-bar {
        width: 100%;
        position: absolute;
        bottom: 56px;
        display: flex;
        flex-direction: row;
        overflow: hidden;

        .check-box-wrap {
          position: absolute;
          right: 0;
          bottom: 50px;
          width: 300px;
          height: 200px;
          z-index: 999;
          background: #{$menuBg}99;

          .button-group {
            position: absolute;
            top: -10px;
          }
        }
      }

      .control-bar {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        padding: 0;

        > li.ctrl-item {
          width: 30px;
          height: 30px;
        }
      }

      .tool-bar {
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 4px;
      }

      .entity-models-bar {
        position: absolute;
        height: 400px;
        top: 100px;
        right: 10px;
      }

      .first {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .second {
        position: absolute;
        bottom: 30px;
        left: 10px;
      }

      .internal {
        position: absolute;
        top: 314px;
        left: 10px;
      }

      .rbt {
        position: absolute;
        top: 350px;
        left: 50px;
      }

      .ai-mode {
        position: absolute;
        top: 330px;
        right: 10px;
        z-index: 999999;
      }
    }

    .right-console-wrapper {
      width: 0;
      padding: 10px 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      transition: width 0.3s;
      > div {
        width: 260px;
      }

      ::v-deep .el-collapse {
        height: 100%;
        border: none;
        color: #fff;
        overflow: hidden;

        .el-collapse-item {
          color: #fff;

          div[role='button'].el-collapse-item__header {
            padding: 10px;
            border: none;
            color: #fff;
            background: #{$cardHeaderBg};
          }

          .el-collapse-item__wrap {
            border: none;
            color: #fff;
            background: #1f2d3d;

            .el-collapse-item__content {
              height: calc(100vh - 312px);
              height: -moz-calc(100vh - 312px);
              height: -webkit-calc(100vh - 312px);
              padding: 10px;
              overflow: auto;
              color: #fff;
              background: #192431;

              .ctrl-item-wrapper {
                border: 1px solid #515151;
                margin-bottom: 10px;

                .ctrl-title {
                  padding: 10px;
                  margin: 0;
                  border-bottom: 1px solid #515151;
                  background: #{$menuBg};
                }

                .camera-ctrl {
                  padding: 10px;
                  display: flex;
                  justify-content: center;

                  > * {
                    margin: auto;
                  }
                }

                .wrapper-mission {
                  flex-wrap: wrap;

                  .container-flat {
                    width: 100%;
                    margin: 0;
                    margin-top: 10px;
                    justify-content: space-between;

                    > div {
                      margin: 5px 0;
                      display: flex;
                      flex-direction: row;
                      width: 100%;
                      justify-content: space-between;
                    }
                  }
                }

                .area-wrapper {
                  padding: 10px;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  background: #{$menuBg};

                  .speed {
                    width: 100px;

                    input {
                      height: 30px;
                      margin-top: 1px;
                    }

                    .el-input__inner {
                      background-color: $cardBg;
                      color: white;
                      border-color: #409eff;
                    }

                    .el-input__icon {
                      height: 110%;
                    }
                  }

                  > div {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .input {
                      color: black;
                      width: 40px;
                      height: 20px;
                      margin: 6px;
                      border: none;
                      text-align: center;
                      border-radius: 4px;
                    }
                  }
                }

                .slider-wrapper {
                  padding: 10px;
                  background: #{$menuBg};

                  .skysys-slider-wrap {
                    margin: 10px 0 0 0;
                  }

                  .msg-bar {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // border-bottom: 1px dashed #eee;
                    margin: 6px 0;

                    .reset-btn {
                      width: 36px;
                      font-size: 12px;
                      padding: 1px 4px;
                      background: #{$primary};
                      border: 1px solid #{$primary};
                      color: #fff;
                      border-radius: 4px;
                      cursor: pointer;
                    }
                  }
                }
              }

              .ctrl-item-wrapper:nth-last-child(1) {
                margin-bottom: 0;
              }
            }
          }

          /*滚动条样式*/
          .el-collapse-item__content::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 4px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 4px;
          }

          .el-collapse-item__content::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            box-shadow: inset 0 0 5px rgba(237, 238, 230, 0.2);
            -webkit-box-shadow: inset 0 0 5px rgba(237, 238, 230, 0.2);
            background: rgba(0, 0, 0, 0.2);
          }

          .el-collapse-item__content::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(25, 36, 49, 0.2);
            -webkit-box-shadow: inset 0 0 5px rgba(25, 36, 49, 0.2);
            border-radius: 0;
            background: rgba(25, 36, 49, 0.1);
          }
        }
      }

      > .el-button {
        align-self: flex-start;
        width: 260px;
        font-size: 14px;
      }

      &.active-right {
        width: 280px;
        padding: 10px;
        z-index: 100;
      }
    }
  }

  .inspection-wrapper {
    position: absolute;
  }

  .examine-bar-area {
    width: 670px;
    height: 800px;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .close {
      position: absolute;
      top: 6px;
      right: 10px;
      cursor: pointer;
    }

    .el-button + .el-button {
      margin-left: 0;
    }

    .btn-group {
      height: 100px;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      flex-direction: column;
    }
  }
}

.speaker-contain {
  width: 50px;
  height: 40px;
  background-image: url('~@/assets/images/speaker/circulation.png');
  background-size: contain;
  background-repeat: no-repeat;
  line-height: 40px;
  text-align: left;
  padding-left: 7px;
  font-size: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }

  &:active {
    transform: scale(0.85);
  }
}

.speaker {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.three-D {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  top: 60px;
  left: 85px;
  z-index: 5;
  background: #2d3035;
  display: flex;
  cursor: pointer;

  > img {
    margin: auto;
    width: 20px;
    height: 20px;
    margin-bottom: 6px;
  }
}

.dji-log {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  top: 15px;
  left: 85px;
  z-index: 5;
  background: #2d3035;
  display: flex;
  cursor: pointer;
  &.site {
    top: 60px;
  }

  > i {
    margin: auto;
    font-size: 16px;
    color: white;
  }
}

.dji-log-list {
  width: 350px;
  height: auto;
  max-height: 400px;
  position: absolute;
  top: 15px;
  left: 130px;
  z-index: 5;
  background: transparent;
  overflow: auto;
  z-index: 100;
  &.site {
    top: 60px;
  }

  .item {
    float: left;
    background: #1e1e1e66;
    min-height: 30px;
    height: auto;
    line-height: 30px;
    border-radius: 15px;
    padding: 0 15px;
    margin-bottom: 18px;
    width: auto;

    > span:first-child {
      margin-right: 15px;
    }

    > span {
      color: white !important;
    }
  }
}

.dj-log-show::after {
  content: 'xxffff';
  color: transparent;
  margin-left: -8px;
  right: -3px;
  width: 5px;
  margin-right: 2px;
  margin-top: 6px;
  height: 5px;
  background: red;
  border-radius: 50%;
}
</style>
